<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8957 2.85577H18.9439C19.5515 2.85577 20.0439 3.34825 20.0439 3.95577C20.0439 4.56328 19.5515 5.05577 18.9439 5.05577H15.6323L10.3464 19.1515H13.8515C14.459 19.1515 14.9515 19.644 14.9515 20.2515C14.9515 20.859 14.459 21.3515 13.8515 21.3515H8.78615C8.76803 21.352 8.74985 21.352 8.73164 21.3515H4.68508C4.07757 21.3515 3.58508 20.859 3.58508 20.2515C3.58508 19.644 4.07757 19.1515 4.68508 19.1515H7.99677L13.2827 5.05577H9.77755C9.17004 5.05577 8.67755 4.56328 8.67755 3.95577C8.67755 3.34825 9.17004 2.85577 9.77755 2.85577H14.8446C14.8616 2.85537 14.8786 2.85537 14.8957 2.85577Z" />
  </svg>
</template>

<script>
export default {
  name: 'Italic',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
