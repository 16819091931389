<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    :class="classname"
  >
    <path d="M21 4.1875C21 3.63522 20.5523 3.1875 20 3.1875H4C3.44772 3.1875 3 3.63522 3 4.1875C3 4.73978 3.44772 5.1875 4 5.1875H20C20.5523 5.1875 21 4.73978 21 4.1875Z" />
    <path d="M21 12.1875C21 11.6352 20.5523 11.1875 20 11.1875H4C3.44772 11.1875 3 11.6352 3 12.1875C3 12.7398 3.44772 13.1875 4 13.1875H20C20.5523 13.1875 21 12.7398 21 12.1875Z" />
    <path d="M20 19.1875C20.5523 19.1875 21 19.6352 21 20.1875C21 20.7398 20.5523 21.1875 20 21.1875H4C3.44772 21.1875 3 20.7398 3 20.1875C3 19.6352 3.44772 19.1875 4 19.1875H20Z" />
    <path d="M21 8.1875C21 7.63522 20.5523 7.1875 20 7.1875H12C11.4477 7.1875 11 7.63522 11 8.1875C11 8.73978 11.4477 9.1875 12 9.1875H20C20.5523 9.1875 21 8.73978 21 8.1875Z" />
    <path d="M20 15.1875C20.5523 15.1875 21 15.6352 21 16.1875C21 16.7398 20.5523 17.1875 20 17.1875H12C11.4477 17.1875 11 16.7398 11 16.1875C11 15.6352 11.4477 15.1875 12 15.1875H20Z" />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignRight',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
