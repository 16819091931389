<template>
  <div class="">
    <div
      class="overflow-y-auto"
      :style="{'max-height': height}"
      id="editorContainer"
      ref="editorContainer"
    >
      <div id="proseText" class="pt-7">
        <ProseEditor
          barebones
          ref="prose"
          :autocomplete="spellcheck"
          :autocorrect="spellcheck"
          :autocapitalize="spellcheck"
          :spellcheck="spellcheck"
          :owner="ownerId"
          :podId="podId"
          :isAutoScroll="!disableAutoScroll"
          @loadingState="setLoadingState"
          @json="setDOCC"
          @sources="updateSources"
          @addClip="addClip"
        />
      </div>
    </div>

    <AudioController
      ref="audioController"
      :loading="loading"
      :urlList="urlList"
      :videoUrlList="videoUrlList"
      :imageUrlList = "imageUrlList"
    />
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import { mapGetters } from 'vuex'
import { compressStateJSON } from 'prosemirror-compress'

import CONSTANTS from '@/constants/CONSTANTS'
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import { getPodcastMeta } from '@/services/api/podcast'
import { formatToProse } from '@/view/voiceEditor/vue/components/formatter'
import ProseEditor from '@/view/voiceEditor/vue/components/NewProseEditor'
import AudioController from '@/view/voiceEditor/vue/components/AudioController'

// workerize loader is a third party lib we use to get javascript inside the worker
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../services/api/transcript'

let instance = worker()
let docc = null

export default {
  name: 'DocPreview',
  props: {
    fileKey: String,
    fileOwner: String,
    loading: String,
    height: {
      type: String,
      default: 'calc(100vh - 360px)'
    },
    disableAutoScroll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProseEditor,
    AudioController
  },
  data: function() {
    return {
      editMode: 'editMedia',
      spellcheck: CONSTANTS.ENABLE_SPELL_CHECK,
      podcastInfoListener: null,

      podId: null,
      ownerId: null,

      // move
      loadingUrlList: {},
      urlList: {},
      videoUrlList: {},
      imageUrlList: {},

      saving: ''
    }
  },
  computed: {
    ...mapGetters({
      dirty: 'editor/dirty',
      timeDeleted: 'editor/timeDeleted',
      podcastInfo: 'editor/podcastInfo'
    })
  },
  methods: {
    addClip(payload) {
      this.$emit('addClip', payload)
    },
    goToTime(time) {
      this.$refs.prose.goToTime(time)
    },
    loadDoc: function() {
      this.podId = this.fileKey
      this.ownerId = this.fileOwner
      store.commit('editor/setOwnerId', this.fileOwner)
      this.initPodcast({ owner: this.fileOwner, key: this.fileKey })
    },
    initPodcast: async function({ owner, key }) {
      this.$emit('onLoadingChange', 'true')
      this.podcastInfoListener = getPodcastMeta(owner, key)
      let flag = false
      const mode = this.editMode
      this.podcastInfoListener.on('value', function (info) {
        let meta = info.val()
        if (!flag && meta) {
          flag = true
          window.mode = mode
          store.commit('editor/setMode', mode)
          store.commit('editor/setTotalTime', meta.length)
          store.commit('editor/setTimeDeleted', meta.timeDeleted)
          store.commit('editor/setTitle', meta.title)
          store.commit('editor/setDocVersion', meta.docVersion)

          // TODO: autopilot logic can be added here when we have manual editor or maybe not
        }
        store.commit('editor/setPodcastInfo', meta)
      })

      try {
        let transcript = await instance.getTranscriptCorrected(owner, key)

        // used for transcript-corrected
        // as its html parser cannot be moved to a worker
        if (typeof transcript === 'string') transcript = formatToProse(transcript, key)
        transcript = await instance.addUIDToSource(transcript, owner) // adding source ( audio )

        // load inside prosemirror
        if (transcript['attrs'] && transcript['attrs']['volume']) {
          store.commit('editor/setMasterVolume', transcript['attrs']['volume'])
        }
        docc = transcript
        this.$refs.prose.setContent(docc, () => this.$emit('documentInitialised'))
      } catch (err) {
        console.log(err)
      }
      // this.$emit('onLoadingChange', 'false')
    },
    setLoadingState: function(loadingState) {
      this.$emit('onLoadingChange', loadingState)
    },
    setDOCC (json) {
      // console.log('json', json)
      docc = json
      store.commit('editor/setDirty', Date().toString())
      this.saveToFirebaseLater()
    },

    getURLfromSource (source) {
      /**
       * Get path of audio files based on source
       */
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) ownerid = this.ownerId

      let path, path1, path2, path3
      if (resourceid.startsWith('m-')) {
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.wav`
        path1 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.mp3`
        path2 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.flac`
        path3 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
      } else {
        if (this.browserIs === 'safari') {
          path = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
          path1 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
          path2 = `podcast_audio/${ownerid}/${resourceid}/audio.wav`
          path3 = this.podcastInfo.uri
        } else {
          path = `podcast_audio/${ownerid}/${resourceid}/audio.ogg`
          path1 = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
          path2 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
          path3 = this.podcastInfo.uri
        }
      }
      return firebase.storage().ref(path).getDownloadURL().then(function (url) {
        return {
          source: source,
          url: url
        }
      }).catch(function (err) {
        console.log(`Not found at ${path}`, err)
        return firebase.storage().ref(path1).getDownloadURL().then(function (url) {
          return {
            source: source,
            url: url
          }
        }).catch(function (err) {
          console.log(`Not found at ${path1}`, err)
          return firebase.storage().ref(path2).getDownloadURL().then(function (url) {
            return {
              source: source,
              url: url
            }
          }).catch(function (err) {
            console.log(`Not found at ${path2}`, err)
            return firebase.storage().ref(path3).getDownloadURL().then(function (url) {
              return {
                source: source,
                url: url
              }
            }).catch(function (err) {
              console.log('Storage Error:', source, path3, err)
            })
          })
        })
      })
    },
    async getVideoUrlFromSource(source) {
      /**
       * Get path of video files based on source
       */
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.ownerId
      }
      let path
      if (resourceid.startsWith('m-')) {
        // FIXES: not video if media id starts with m-
        let contentType = null
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
        await firebase.storage().ref(path).getMetadata()
          .then((metadata) => {
            // console.log('path metadata', metadata)
            contentType = metadata.contentType
          })
          .catch((error) => {
            console.log('path metadata error', error)
          })
        if (contentType !== 'video/mp4') {
          return null
        }
        // console.log('path', path)
      } else {
        path = `podcast_audio/${ownerid}/${resourceid}/video.mp4`
      }
      try {
        return firebase.storage().ref(path).getDownloadURL().then(function (url) {
          return {
            source: source,
            url: url
          }
        }).catch(function (err) {
          console.log('Storage Error:', err)
        })
      } catch (err) {
        console.log('Storage Error:', err)
        return null
      }
    },
    getImageUrlFromSource(source) {
      // console.log('ssssssssss mp4', source)
      /**
       * Get path of video files based on source
       */
      // console.log('source is', source)
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.ownerId
      }
      let path
      if (resourceid.startsWith('i-')) {
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`

        try {
          return firebase.storage().ref(path).getDownloadURL().then(function (url) {
          // console.log('ssssssssss mp4', {
          //   source: source,
          //   url: url
          // })
            return {
              source: source,
              url: url
            }
          }).catch(function (err) {
            console.log('Storage Error:', err)
            return null
          })
        } catch (err) {
          console.log('Storage Error:', err)
          return null
        }
      }
    },
    updateSources: function(sources, mediaSources) {
      // Get audio from all the sources
      let vm = this
      let tempList = []
      let tempVideoList = []
      let tempImageList = []

      sources.forEach(function (source) {
        if (source === 0 || source === '0' || source.split('#!@')[0] === 0 || source.split('#!@')[0] === '0') {
          // console.warn(`source can't be 0`)
          return
        }
        if (!(source in vm.urlList) && !(source in vm.loadingUrlList)) {
          let url = vm.getURLfromSource(source)
          let videoUrl = vm.getVideoUrlFromSource(source)

          if (url) {
            vm.loadingUrlList[source] = url
            tempList.push(url)
          }
          if (videoUrl) {
            tempVideoList.push(videoUrl)
          }
        }
      })
      mediaSources.forEach(function (source) {
        if (source === 0 || source === '0' || source.split('#!@')[0] === 0 || source.split('#!@')[0] === '0') {
          // console.warn(`source can't be 0`)
          return
        }
        if (!(source in vm.urlList) && !(source in vm.loadingUrlList)) {
          let imageUrl = vm.getImageUrlFromSource(source)
          if (imageUrl) {
            tempImageList.push(imageUrl)
          }
        }
      })

      Promise.all(tempImageList).then(function (values) {
        values.forEach(function (value) {
          if (value) {
            vm.$set(vm.imageUrlList, value.source, value.url)
          }
        })
      })

      Promise.all(tempVideoList).then(function (values) {
        const validValues = values.filter(t => Boolean(t))
        if (!validValues.length) {
          store.commit('editor/setDocProgress', 92)
          store.commit('video/setSourcesReady', true)
        }
        validValues.forEach(function (value) {
          vm.$set(vm.videoUrlList, value.source, value.url)
        })
      })
      Promise.all(tempList).then(function (values) {
        values.forEach(function (value) {
          if (value) vm.$set(vm.urlList, value.source, value.url)
        })
      })
    },
    saveToFirebaseNow: async function() {
      if (this.dirty === this.saving) return

      const transcodeToFirebase = (doc) => {
        let localDeleted = 0
        if (doc.content) {
          doc.content.forEach(function (paragraphNode) {
            if (paragraphNode.content) {
              paragraphNode.content.forEach(function (textNode) {
                let flag = false
                if (textNode.marks) {
                  textNode.marks.forEach(function (mark) {
                    if (mark.type === 'deleted') {
                      flag = true
                    } else if (mark.type === 'info') {
                      if (flag) {
                        localDeleted += (mark.attrs.aend - mark.attrs.astart)
                      }
                    }
                  })
                }
              })
            }
          })
        }

        store.commit('editor/setTimeDeleted', localDeleted * 1000)
      }

      this.saving = this.dirty
      const updates = {}
      const updatedDate = this.dirty
      transcodeToFirebase(docc.doc)
      let { d } = compressStateJSON(docc)

      updates['/podcast/' + this.podId + '/realtime/checkpoint/'] = {
        d,
        k: docc.k,
        t: docc.t || ''
      }
      updates['/podcast-info/' + this.podId + '/status/code/'] = 206
      updates['/podcast-info/' + this.podId + '/status/msg/'] = 'correcting'
      updates['/podcast-info/' + this.podId + '/timeDeleted/'] = this.timeDeleted
      updates['/podcast-info/' + this.podId + '/updated/'] = updatedDate
      updates['/podcast-info/' + this.podId + '/length/'] = this.totalTime

      try {
        await instance.saveToDB(this.ownerId, updates)
        console.log('Transcript written. Status Updated', updatedDate)

        if (this.dirty && this.dirty <= updatedDate) {
          store.commit('editor/setDirty', '')
          store.commit('editor/setDirtyMessage', 0)
          this.saving = ''
        }
      } catch (err) {
        console.log('unable to save', err)
      }
    }
  },
  mounted: function() {
    this.loadDoc()
  },
  watch: {
    fileKey: async function() {
      store.commit('editor/resetDocProgress')
      this.loadDoc()
    }
  },
  beforeDestroy () {
    if (this.podcastInfoListener) this.podcastInfoListener.off()
    if (myProseEditor) myProseEditor.stopAudioPlayer()
    store.commit('editor/reset')
    this.urlList = {}
    this.videoUrlList = {}
    this.loadingUrlList = {}
  }
}
</script>

<style>

</style>
