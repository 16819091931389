<template>
   <div
    class="bg-black group relative overflow-hidden"
    :class="{
      [`ar-${aspectRatio}`]: true,
      'w-full': aspectRatio === 'landscape',
      'h-full': aspectRatio !== 'landscape'
    }"
    id="custom-video-renderer"
  >
    <Element
      :autoplay="false"
      :isPlaying="false"
      :currentTime="0"
      :totalTime="99"
      :scale="scale"
      :elements="elements"
      class="relative z-20"
    />
  </div>
</template>

<script>
import Element from '@/components/VideoPlayer/Element/StaticElement.vue'
import playerInitData from './playerElementsData'

export default {
  props: {
    height: {
      type: Number,
      default: 72
    },
    width: {
      type: Number,
      default: 84
    },
    videoPlayerInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data: function() {
    return {
      scale: 0.06666,
      elements: []
    }
  },
  components: {
    Element
  },
  computed: {
    aspectRatio: function() {
      return this.videoPlayerInfo.aspectRatio || 'landscape'
    }
  },
  methods: {
    async initialiseElements() {
      const { elements } = await playerInitData.audiogram(this.scale, this.videoPlayerInfo)
      this.elements = elements
    }
  },
  mounted: function() {
    this.initialiseElements()
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Anton&family=Archivo&family=Archivo+Black&family=Arimo&family=Bad+Script&family=Ballet&family=Bangers&family=Calistoga&family=Chewy&family=Cinzel&family=Courier+Prime&family=Iceberg&family=Just+Another+Hand&family=Kumar+One+Outline&family=Lobster&family=Oswald&family=Pacifico&family=Permanent+Marker&family=Playfair+Display&family=Poppins&family=Rakkas&family=Roboto&family=Rubik&family=Shrikhand&family=Squada+One&family=Titan+One&family=Work+Sans&family=ZCOOL+KuaiLe&family=Zilla+Slab&display=swap');

.ar-landscape {
  aspect-ratio: 16 / 9;
}

.ar-square {
  aspect-ratio: 1 / 1;
}

.ar-portrait {
  aspect-ratio: 9 / 16;
}
</style>
