<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.1875C3 3.63522 3.44772 3.1875 4 3.1875H20C20.5523 3.1875 21 3.63522 21 4.1875C21 4.73978 20.5523 5.1875 20 5.1875H4C3.44772 5.1875 3 4.73978 3 4.1875ZM3 12.1875C3 11.6352 3.44772 11.1875 4 11.1875H20C20.5523 11.1875 21 11.6352 21 12.1875C21 12.7398 20.5523 13.1875 20 13.1875H4C3.44772 13.1875 3 12.7398 3 12.1875ZM4 19.1875C3.44772 19.1875 3 19.6352 3 20.1875C3 20.7398 3.44772 21.1875 4 21.1875H20C20.5523 21.1875 21 20.7398 21 20.1875C21 19.6352 20.5523 19.1875 20 19.1875H4ZM3 8.1875C3 7.63522 3.44772 7.1875 4 7.1875H12C12.5523 7.1875 13 7.63522 13 8.1875C13 8.73978 12.5523 9.1875 12 9.1875H4C3.44772 9.1875 3 8.73978 3 8.1875ZM4 15.1875C3.44772 15.1875 3 15.6352 3 16.1875C3 16.7398 3.44772 17.1875 4 17.1875H12C12.5523 17.1875 13 16.7398 13 16.1875C13 15.6352 12.5523 15.1875 12 15.1875H4Z" />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignLeft',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
