<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    :class="classname"
  >
    <path d="M11.6665 3.00065L9.99984 1.33398H3.33317C2.89114 1.33398 2.46722 1.50958 2.15466 1.82214C1.8421 2.1347 1.6665 2.55862 1.6665 3.00065V13.0007C1.6665 13.4427 1.8421 13.8666 2.15466 14.1792C2.46722 14.4917 2.89114 14.6673 3.33317 14.6673H9.1665V16.334L12.4998 13.834L9.1665 11.334V13.0007H3.33317V3.00065H11.6665ZM17.4998 8.83398V18.0007C17.4998 18.4427 17.3242 18.8666 17.0117 19.1792C16.6991 19.4917 16.2752 19.6673 15.8332 19.6673H6.6665C6.22448 19.6673 5.80055 19.4917 5.48799 19.1792C5.17543 18.8666 4.99984 18.4427 4.99984 18.0007V16.334H6.6665V18.0007H15.8332V10.5007H11.6665V6.33398H6.6665V11.334H4.99984V6.33398C4.99984 5.89196 5.17543 5.46803 5.48799 5.15547C5.80055 4.84291 6.22448 4.66732 6.6665 4.66732H13.3332L17.4998 8.83398Z" />
  </svg>
</template>

<script>
export default {
  name: 'Replace',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
