<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="21"
    viewBox="0 0 15 21"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 0.5H9.11625C9.44774 0.500071 9.76563 0.631813 10 0.86625L14.6337 5.5C14.8682 5.73437 14.9999 6.05226 15 6.38375V18C15 18.663 14.7366 19.2989 14.2678 19.7678C13.7989 20.2366 13.163 20.5 12.5 20.5H2.5C1.83696 20.5 1.20107 20.2366 0.732233 19.7678C0.263392 19.2989 0 18.663 0 18V3C0 2.33696 0.263392 1.70107 0.732233 1.23223C1.20107 0.763392 1.83696 0.5 2.5 0.5ZM9.375 2.375V4.875C9.375 5.20652 9.5067 5.52446 9.74112 5.75888C9.97554 5.9933 10.2935 6.125 10.625 6.125H13.125L9.375 2.375ZM4.375 3V4.25H3.125V5.5H4.375V6.75H3.125V8H4.375V9.25H6.25V8H5V6.75H6.25V5.5H5V4.25H6.25V3H5V1.75H3.125V3H4.375ZM6.50888 10.2411C6.27446 10.0067 5.95652 9.875 5.625 9.875H4.375C4.04348 9.875 3.72554 10.0067 3.49112 10.2411C3.2567 10.4755 3.125 10.7935 3.125 11.125V12.2975L2.625 14.2962C2.56258 14.5462 2.57892 14.8093 2.67177 15.0497C2.76463 15.29 2.92948 15.4957 3.14375 15.6388L4.30625 16.4137C4.51153 16.5505 4.7527 16.6235 4.99937 16.6235C5.24605 16.6235 5.48722 16.5505 5.6925 16.4137L6.855 15.6388C7.0695 15.4959 7.23459 15.2902 7.32768 15.0499C7.42077 14.8096 7.4373 14.5463 7.375 14.2962L6.875 12.2975V11.125C6.875 10.7935 6.7433 10.4755 6.50888 10.2411ZM4.37344 11.125V12.2975C4.37343 12.3999 4.36083 12.5019 4.33594 12.6012L3.83594 14.5988L4.99844 15.3738L6.16094 14.5988L5.66094 12.6012C5.63604 12.5019 5.62345 12.3999 5.62344 12.2975V11.125H4.37344Z" />
  </svg>
</template>

<script>
export default {
  name: 'Zip',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
