import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const getBrandkitInfo = ({ userId, type, version }, setter) =>
  firebase.database().ref(`${userId}/brandkit/${type}/${version}`)
    .on('value', snap => setter(snap.val()))

export const getBrandkitInfoOnce = ({ userId, type, version }) => new Promise(async (resolve, reject) => {
  console.log('brandkit', `${userId}/brandkit/${type}/${version}`)
  const snap = await firebase.database().ref(`${userId}/brandkit/${type}/${version}`)
    .once('value')
  resolve(snap.val())
})
