<template>
  <div class="flex items-center max-h-48 mb-4 last:mb-0">
    <div class="text-sm text-primary-500 mr-4">
      {{humanLength(start)}} - {{humanLength(end)}}
    </div>

    <textarea
      v-model="subtitle"
      rows="1"
      maxlength="10000"
      class="resize-none text-sm px-4 py-3.25 rounded-2 border border-primary-300 ring-primary text-primary placeholder-primary-400 focus:ring-1 flex-grow"
      @focus="$emit('focus')"
    />
  </div>
</template>

<script>
import { humanLength } from '@/utilities/utility'

export default {
  name: 'SubtitleEditor',
  data: function() {
    return {
      humanLength
    }
  },
  props: {
    index: {
      type: Number,
      default: 1
    },
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 100
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    subtitle: {
      get: function() {
        return this.text
      },
      set: function(val) {
        this.$emit('onChange', {
          index: this.index,
          update: {
            start: this.start,
            end: this.end,
            text: val
          }
        })
      }
    }
  }

}
</script>

<style>

</style>
