<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    viewBox="0 0 19 20"
    :class="classname"
  >
    <path d="M18.9397 14.9089C18.8777 15.6681 18.7466 16.335 18.4322 16.9519C17.9329 17.9319 17.1361 18.7287 16.1561 19.2281C15.5391 19.5424 14.8723 19.6735 14.1131 19.7355C13.3757 19.7957 12.4652 19.7957 11.3363 19.7957H11.3362H7.91363H7.9135C6.78463 19.7957 5.87411 19.7957 5.13677 19.7355C4.37755 19.6735 3.71069 19.5424 3.09372 19.2281C2.11371 18.7287 1.31693 17.9319 0.817593 16.9519C0.50323 16.335 0.372176 15.6681 0.310145 14.9089C0.277427 14.5084 0.26248 14.0569 0.255653 13.5471C0.247946 12.9715 0.716862 12.5045 1.2925 12.5045C1.86751 12.5045 2.33128 12.9705 2.33918 13.5454C2.34558 14.0113 2.35889 14.4006 2.38656 14.7392C2.43806 15.3696 2.53408 15.7318 2.67386 16.0061C2.97346 16.5941 3.45152 17.0722 4.03953 17.3718C4.31386 17.5116 4.67603 17.6076 5.30642 17.6591C5.94896 17.7116 6.77429 17.7124 7.95825 17.7124H11.2916C12.4755 17.7124 13.3009 17.7116 13.9434 17.6591C14.5738 17.6076 14.936 17.5116 15.2103 17.3718C15.7983 17.0722 16.2764 16.5941 16.576 16.0061C16.7158 15.7318 16.8118 15.3696 16.8633 14.7392C16.8909 14.4006 16.9043 14.0113 16.9107 13.5454C16.9186 12.9705 17.3823 12.5045 17.9573 12.5045C18.533 12.5045 19.0019 12.9715 18.9942 13.5471C18.9874 14.0569 18.9724 14.5084 18.9397 14.9089Z" />
    <path d="M10.6517 1.66509C10.6517 1.14572 10.2307 0.724686 9.71128 0.724686C9.19191 0.724686 8.77088 1.14572 8.77088 1.66509V11.6068L8.10538 10.941C7.73812 10.5737 7.14269 10.5737 6.77544 10.941C6.40819 11.3082 6.40819 11.9037 6.77544 12.2709L9.04632 14.5421C9.41357 14.9094 10.009 14.9094 10.3763 14.5421L12.6475 12.2709C13.0147 11.9037 13.0147 11.3082 12.6475 10.941C12.2802 10.5737 11.6848 10.5737 11.3175 10.941L10.6517 11.6068V1.66509Z" />
  </svg>
</template>

<script>
export default {
  name: 'DownloadFile',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
