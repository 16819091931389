<template>
  <div class="border rounded border-primary-300 w-full col-span-1 relative overflow-hidden">
    <div class="bg-primary rounded-tl rounded-tr flex thumb items-center justify-center relative">
      <div class="flex top-0 right-0 bottom-0 left-0 absolute loadingOverlay items-center justify-center">
        <div class="rounded-full flex h-9 w-9 justify-center items-center relative progress">
          <CircularLoader class="h-10 text-red w-10 absolute" />
          <div class="font-h font-semibold text-white text-xs">{{formattedProgress}}%</div>
        </div>
      </div>
    </div>

    <div class="flex px-4 pt-4 details justify-between">
      <div class="">
        <div class="text-primary text-sm mb-1">{{limitString(title)}}</div>
        <div class="text-xs text-primary-500 loadingDots">Transcribing</div>
      </div>

      <div
        class="cursor-pointer text-primary-500 hover:text-red "
        @click="$emit('onCancel')"
        v-tooltip.top="'Cancel'"
      >
        <CloseCircle />
      </div>
    </div>
  </div>
</template>

<script>
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import CloseCircle from '@/components/base/icons/CloseCircle.vue'

export default {
  props: {
    progress: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    CircularLoader,
    CloseCircle
  },
  computed: {
    formattedProgress: function() {
      return this.progress && (this.progress > 0) ? this.progress : 0
    }
  },
  methods: {
    limitString: function(str, limit = 30) {
      if (str.length > 30) return `${str.slice(0, limit)}...`
      else return str
    }

  }
}
</script>

<style scoped lang="scss">
.clipPreview {
  width: 100%;
}

.thumb {
  height: 80px;
}

.details {
  height: 64px;
}

.loadingOverlay {
  background: rgba(229, 231, 235, 0.7);
  backdrop-filter: blur(7px);
}

.progress {
  background: rgba(0, 0, 0, 0.48);
}
</style>
