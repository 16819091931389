<template>
  <div class="flex flex-col h-full">
    <div v-if="notes && notes.length" class="flex-grow px-5 py-2 overflow-y-auto">
      <TimeRow
        v-for="(line, index) in notes"
        :key="ids[index]"
        class="flex items-end mb-s12"
        :line="line"
        :index="index"
        @onChange="updateNote"
        @addRow="addRow"
        @removeRow="removeRow"
        :hideError="hideError"
        :totalTime="totalTime"
        :timestamps="timestamps"
      />
    </div>
  </div>
</template>

<script>
import TimeRow from './TimeRow.vue'

export default {
  name: 'TimestampForm',
  props: {
    notes: {
      type: Array,
      default: function() {
        return []
      }
    },
    hideError: {
      type: Boolean,
      default: false
    },
    totalTime: {
      type: Number,
      default: 99999
    },
    timestamps: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: {
    TimeRow
  },
  data() {
    return {
      ids: [],
      max: 1,
      formData: [
        {
          id: 1,
          error: false
        },
        {
          id: 1,
          error: true
        },
        {
          id: 1,
          error: false
        }
      ]
    }
  },
  mounted () {
    console.log(this.notes)
    if (this.notes) {
      this.notes.forEach((_, index) => {
        this.ids.push(index)
      })
      this.max = this.notes.length
    }
  },
  methods: {
    updateNote: function({ index, line }) {
      const newNotes = [...this.notes]
      newNotes[index] = line
      this.$emit('updateRawNotes', newNotes)
    },
    addRow: function(index) {
      const newNotes = [...this.notes]
      this.ids.splice(index, 0, this.max)
      this.max++
      newNotes.splice(index, 0, '')
      this.$emit('updateRawNotes', newNotes)
    },
    removeRow: function(index) {
      const newNotes = [...this.notes]
      if (newNotes.length >= 2) {
        this.ids.splice(index, 1)
        newNotes.splice(index, 1)
        this.$emit('updateRawNotes', newNotes)
      }
    }
  }
}
</script>

<style scoped lang="scss">
label:focus-within p {
  display: block;
}
</style>
