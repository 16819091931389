<template>
  <div class="relative">
    <div v-if="disabled" class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white opacity-70" />
    <div class="grid grid-cols-4 gap-2">
      <div class="col-span-3">
        <Popover
          placement="top-start"
          popoverArrowClass="hidden"
        >
          <div class="border rounded-lg cursor-pointer flex border-primary-400 text-sm text-primary w-full py-3.5 px-2.5 col-span-2 items-center justify-between">
            <div class="text-sm text-primary">{{value['font-family'] || 'Roboto'}}</div>
            <ChevronDown class="w-3 h-3 dropdownCaret"/>
          </div>

          <template slot="popover">
            <div class="max-h-80 py-1.5 pr-0.5 pl-1.5 fontPopup overflow-y-auto">
              <div
                v-for="el in fonts"
                :key="el"
                :value="el"
                @click="$emit('onChange', {'font-family': el})"
                v-close-popover
                class="rounded cursor-pointer text-sm text-primary mb-2 p-2.5 hover:bg-primary-200 last:mb-0"
                :class="{'text-red': value['font-family'] === el}"
                :style="{'font-family': el}"
              >
                {{el}}
              </div>
            </div>
          </template>
        </Popover>
      </div>

      <div class="col-span-1">
        <div
          class="relative border rounded-lg cursor-pointer flex border-primary-400 text-sm text-primary w-full py-2.5 px-1.75 col-span-2 items-center justify-between"
        >
          <input
            class="absolute top-0 bottom-0 left-0 right-0 w-full h-full opacity-0 cursor-pointer"
            type="color"
            name="color"
            v-model="color"
          >
          <div
            class="text-sm rounded px-1.25 py-1"
            :style="{
              'color': value['color'] || '#000228',
              'background': value['background'] || '#fff'
            }"
          >
            <div
              class="text-2xl leading-5 font-semibold"
              :style="{'text-shadow': '1px 1px #000228'}"
            >
              A
            </div>
          </div>
          <ChevronDown class="w-3 h-3 dropdownCaret"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Popover from '@/components/base/display/Popover.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'

import isEqual from 'lodash/isEqual'

import 'pattern.css'

const fonts = [
  'Gilroy',
  'Inter',
  'Abril Fatface',
  'Alfa Slab One',
  'Titan One',
  'Work Sans',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Rakkas',
  'Roboto',
  'Rubik',
  'Shrikhand',
  'Squada One',
  'Iceberg',
  'Just Another Hand',
  'Kumar One Outline',
  'Lobster',
  'Oswald',
  'Pacifico',
  'Zilla Slab',
  'ZCOOL KuaiLe',
  'Anton',
  'Archivo',
  'Archivo Black',
  'Arimo',
  'Bad Script',
  'Bangers',
  'Ballet',
  'Calistoga',
  'Chewy',
  'Cinzel',
  'Courier Prime'
]

const fontColors = [
  '#000228',
  '#ffffff',
  '#EC5B16',
  '#53B745',
  '#0075FF',
  '#8338EC',
  '#F8C450',
  '#F20D70'
]

const positions = {
  topRight: {
    x: 92,
    y: 88
  },
  topLeft: {
    x: 8,
    y: 88
  },
  bottomRight: {
    x: 92,
    y: 12
  },
  bottomLeft: {
    x: 8,
    y: 12
  }
}

export default {
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      fonts,
      fontColors
    }
  },
  components: {
    Popover,
    ChevronDown
  },
  computed: {
    activeFont: function() {
      let position = ''
      if (this.value.x) {
        position = Object.keys(positions).find(el => isEqual(positions[el], this.value))
      }
      return position
    },
    color: {
      get: function() {
        return this.value['color'] || '#000228'
      },
      set: function(value) {
        this.$emit('onChange', { 'color': value })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Anton&family=Archivo&family=Archivo+Black&family=Arimo&family=Bad+Script&family=Ballet&family=Bangers&family=Calistoga&family=Chewy&family=Cinzel&family=Courier+Prime&family=Iceberg&family=Just+Another+Hand&family=Kumar+One+Outline&family=Lobster&family=Oswald&family=Pacifico&family=Permanent+Marker&family=Playfair+Display&family=Poppins&family=Rakkas&family=Roboto&family=Rubik&family=Shrikhand&family=Squada+One&family=Titan+One&family=Work+Sans&family=ZCOOL+KuaiLe&family=Zilla+Slab&display=swap');

.fontPopup {
  width: 200px;
}

.fontstylePopup {
  width: 230px;
}

.transparent-bg {
  background-position: 0 0, 4px 4px;
  background-position-x: 0px, 4px;
  background-position-y: 0px, 4px;
  background-size: calc(2 * 4px) calc(2 * 2px);
}
</style>
