<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <main class="flex flex-1 h-screen clipEditor">
    <div
      v-if="debug && clips.length"
      class="absolute z-50 w-2/5 h-40 overflow-auto rounded-lg shadow-lg bg-primary-300 text-primary bottom-4 left-4"
    >
      <div
        v-for="(c, i) in clips"
        :key="c.uid"
        class="mb-1 text-xs"
      >
        <span class="font-h font-semibold">Clip {{i+1}}:</span>
        <a
          target="_blank"
          :href="`${host}/player/${c.docOwner}/${c.uid}?logs=true`"
          class="text-blue"
        >
          {{`${host}/player/${c.docOwner}/${c.uid}?logs=true`}}
        </a>
      </div>

      <div class="mt-6 font-h text-xs font-semibold">Videoplayer Info</div>
      <pre
        class="text-2xs"
      >
        {{JSON.stringify(videoPlayerInfo, 0, 2)}}
      </pre>
    </div>
    <pre
      v-else-if="debug"
      class="absolute z-50 w-2/5 h-40 overflow-auto rounded-lg shadow-lg bg-primary-300 text-primary bottom-4 left-4"
    >
      {{JSON.stringify(projectInfo, 0, 2)}}
    </pre>
    <Downloads
      v-if="downloads"
      :message="downloads"
      :title="downloadName"
    />
    <Header clipper/>

    <div class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16">
      <section class="relative z-10 flex flex-col h-screen col-span-7 bg-white shadow-lg">
        <div v-if="makingClips" class="absolute left-0 right-0 flex justify-center bottom-16">
          <div v-if="makingClipsForTooLong" class="flex items-center px-3 py-1 rounded-lg bg-primary banner">
            <div class="mr-2">⌛️</div>
            <div class="text-xs leading-6 text-white">
              We’re almost there! Just a little while left.
            </div>
          </div>
          <div v-else class="flex items-center px-3 py-1 rounded-lg bg-primary banner">
            <div class="mr-2">⏱</div>
            <div class="text-xs leading-6 text-white">
              Your exports are on their way! Please wait here until we begin exporting.
            </div>
          </div>
        </div>

        <VToastr ref="toastr" />
        <div
          v-show="loading"
          class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-screen bg-white bg-opacity-80 z-99"
        >
          <div class="px-8 py-2 bg-white rounded-lg shadow-lg">Loading...</div>
        </div>

        <div v-show="!loading" class="relative w-full">
          <div class="relative z-10 flex items-center px-10 pt-10 mb-6 bg-white">
            <img
              v-if="projectMeta.icon"
              :src="projectMeta.icon"
              class="w-8 h-8 mr-2"
            />

            <input
              v-if="isEditingTitle"
              type="text"
              placeholder="Name Your Project"
              class="flex-grow p-0 text-4xl font-semibold border-none outline-none font-h placeholder-primary-300 titleInput focus:ring-0"
              v-model="title"
              v-focus
              @blur="isEditingTitle = false;"
              @keyup.enter="isEditingTitle=false;"
            >
            <div
              v-else
              @click="isEditingTitle = true;"
              class="flex-grow overflow-hidden text-4xl font-semibold font-h titleInput whitespace-nowrap overflow-ellipsis sp-0"
              :class="{'text-primary-300': !title}"
            >
              {{title || 'Name Your Project'}}
            </div>
          </div>

          <div
            class="px-10 overflow-y-auto"
            :style="{height: 'calc(100vh - 104px)'}"
          >
            <!-- step 1 -->
            <Step
              v-show="debug ? true : (step !== 6 && (minimized || step <= 2))"
              :isEnabled="enabledSteps.includes(1)"
              :open="!minimized && step === 1"
              :stepHeadClass="''"
              :stepBodyClass="''"
              @onClick="goToStep(1)"
            >
              <div>
                <span @click="goToStep(1)">1. Select from drive or</span>
                <span
                  v-if="selectedFile && selectedDocProgress < 100"
                  class="text-primary-500"
                  @click="goToStep(1)"
                >
                  upload a file
                </span>
                <span
                  v-else-if="step===1"
                  class="underline cursor-pointer text-blue"
                  @click="showUploadModal"
                >
                  upload a file
                </span>
                <span v-else @click="goToStep(1)">
                  upload a file
                </span>
              </div>

              <template slot="stepBody">
                <div>
                  <div
                    class="grid grid-cols-3 gap-4"
                    v-if="selectedFile && selectedDocProgress < 100"
                  >
                    <TranscribingFile
                      :title="selectedFile.title || ''"
                      :progress="Math.round(selectedDocProgress)"
                      @onCancel="removeFile()"
                    />
                  </div>

                  <div
                    v-if="files.length && !(selectedFile && selectedDocProgress < 100)"
                    class="overflow-y-auto border rounded border-primary-300 fileList"
                  >
                    <div
                      class="cursor-pointer flex py-3.25 px-6 relative items-center justify-between group"
                      v-for="item in files"
                      v-if="!(item.code in fileErrorCodes)"
                      :key="item.key"
                      :id="item.key"
                      @click="selectFile(item)"
                    >
                      <div class="flex items-center">
                        <BrandIcon
                          class="mr-3 h-7 w-7"
                          :class="[selectedFile && selectedFile.key === item.key ? 'text-red' : 'text-primary-500 group-hover:text-red']"
                        />
                        <div
                          class="text-sm font-semibold"
                          :class="[selectedFile && selectedFile.key === item.key ? 'text-red' : 'text-primary group-hover:text-red']"
                        >
                          {{item.title}}
                        </div>
                      </div>

                      <div :class="[selectedFile && selectedFile.key === item.key ? 'block text-green' : 'hidden group-hover:block group-hover:text-primary-400 cursor-pointer']">
                        <i
                          class="text-xl far fa-check-circle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Step>

            <!-- step 2 -->
            <Step
              v-show="debug ? true : (step !== 6 && (minimized || step <= 3))"
              :isEnabled="enabledSteps.includes(2)"
              :open="!minimized && step === 2"
              :stepHeadClass="''"
              :stepBodyClass="''"
              @onClick="goToStep(2)"
            >
              <div class="flex justify-between">
                <span @click="goToStep(2)">2. Select aspect ratio</span>
              </div>

              <template slot="stepBody">
                <AspectRatio
                  :aspectRatio="aspectRatio"
                  @onChange="selectAspectRatio"
                />
              </template>
            </Step>

            <!-- step 3 -->
            <Step
              v-show="debug ? true : (step !== 6 && (minimized || step <= 4))"
              :isEnabled="enabledSteps.includes(3)"
              :open="!minimized && step === 3"
              :stepHeadClass="''"
              :stepBodyClass="''"
              @onClick="goToStep(3)"
            >
              <div class="relative flex justify-between">
                <span @click="goToStep(3)">
                  3. Customise your audiogram
                </span>
                <Button
                  v-if="!minimized && step===3"
                  variant="primary"
                  @click="customiseAudiogram()"
                  class="ml-2"
                >
                  Next
                </Button>
              </div>

              <template slot="stepBody">
                <div class="pt-2">
                  <Templates
                    :key="projectId"
                    :projectId="projectId"
                    :aspectRatio="aspectRatio"
                    :template="template"
                    :projectInfo="projectInfo"
                    @onChange="updateAudiogramRender"
                  />
                </div>
              </template>
            </Step>

            <!-- step 4 -->
            <Step
              v-show="debug ? true : (step!==6 || !enableSubtitleFormatting)"
              :isEnabled="enabledSteps.includes(4)"
              :open="!minimized && step === 4"
              :stepHeadClass="''"
              :stepBodyClass="''"
              @onClick="goToStep(4)"
            >
              <div class="flex items-center" v-if="step === 6">
                <div
                  class="flex text-base font-semibold font-h text-primary-500"
                  v-tooltip.right="'Can’t go back now unfortunately 😅'"
                >
                  <span>
                    4. Select text to create clips
                  </span>
                  <LockIcon
                    class="ml-2"
                  />
                </div>
              </div>
              <div v-else class="flex justify-between">
                <div class="relative flex">
                  <span @click="goToStep(4)">
                    4. Select text to create clips
                  </span>
                  <CircularLoader
                    v-if="step===4 && !minimized && !isDocReady"
                    class="relative w-5 h-4 ml-2 text-red top-1"
                  />

                  <div
                    v-if="!minimized && isDocReady && step===4"
                    class="relative"
                  >
                    <div
                      v-if="!searchOpen"
                      class="rounded-lg cursor-pointer flex h-10 ml-2 -top-1.5 text-primary-500 w-10 relative items-center justify-center hover:bg-primary-200 hover:text-primary-600"
                      @click="openSearch"
                    >
                      <SearchIcon class="w-6 h-6 m-0"/>
                    </div>
                    <div
                      class="ml-3 -top-1.5 relative searchBox"
                      :class="{'active': searchOpen}"
                    >
                      <input
                        v-show="searchOpen"
                        type="text"
                        ref="searchInput"
                        v-model="searchterm"
                        @keyup.enter="research()"
                        class="border rounded-lg font-ui font-normal border-primary-300 text-xs w-full py-2.75 pl-3 text-primary-800 block focus:border-primary focus:ring-primary"
                        placeholder="Search inside media"
                        :class="[searchterm ? 'pr-28' : 'pr-2']"
                        @blur="onSearchBlur"
                      />
                      <div
                        v-show="searchOpen && !searchterm"
                        class="flex mt-2 inset-y-0 right-2.5 text-primary-500 absolute"
                      >
                        <SearchIcon class="w-6 h-6 m-0"/>
                      </div>

                      <div
                        v-show="searchOpen && searchterm"
                        class="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-primary-500 hover:text-primary"
                        @click="resetSearch(1)"
                      >
                        <CloseIcon classname="h-3.5 w-3.5 transform rotate-180" />
                      </div>
                      <div
                        v-show="searchOpen && searchterm"
                        class="absolute inset-y-0 flex items-center pr-2 cursor-pointer right-5 text-primary-500 hover:text-primary"
                        @click="navigateSearch(1)"
                      >
                        <ChevronDown classname="h-3 w-3" />
                      </div>
                      <div
                        v-show="searchOpen && searchterm"
                        class="absolute inset-y-0 flex items-center pr-2 cursor-pointer right-10 text-primary-500 hover:text-primary"
                        @click="navigateSearch(-1)"
                      >
                        <ChevronDown classname="h-3 w-3 transform rotate-180" />
                      </div>

                      <div v-show="searchOpen && searchterm" class="rounded flex bg-primary-300 my-2.5 mr-1 inset-y-0 right-16 w-0.5 absolute"/>

                      <div
                        v-show="searchOpen && searchterm"
                        class="flex mt-3.25 text-xs pr-1.5 inset-y-0 right-18 text-primary-500 absolute"
                      >
                        {{searchResults.length ? (searchResultIndex + 1) : 0}}/{{searchResults.length}}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="relative flex items-center -top-2"
                  v-show="!minimized && step===4"
                >
                  <Button
                    :variant="isTimestampError ? 'disabled' : 'primary'"
                    :disabled="isTimestampError"
                    @click="confirmClips()"
                    class="ml-2"
                  >
                    Next
                  </Button>
                </div>
              </div>

              <template slot="stepBody">
                <div
                  id="manualClipperDoc"
                  v-show="debug || (!minimized && step===4)"
                  @mousedown="clearPreview"
                >
                  <DocPreview
                    v-if="showPreviewPlayer"
                    :key="selectedFile.key"
                    :loading="previewLoading"
                    :fileKey="selectedFile.key"
                    :fileOwner="selectedFile.owner"
                    :height="'320px'"
                    :disableAutoScroll="true"
                    @onLoadingChange="onLoadingChange"
                    @goToTime="goToTime"
                    @addClip="createManualClipTimestamp"
                    @documentInitialised="documentInitialised"
                    ref="clipDoc"
                  />
                </div>
              </template>
            </Step>

            <!-- Step 5 -->
            <Step
              v-show="enableSubtitleFormatting"
              :isEnabled="enabledSteps.includes(5)"
              :open="!minimized && step === 5"
              :stepHeadClass="''"
              :stepBodyClass="''"
            >
              <div class="flex items-center" v-if="step === 6">
                <div
                  class="flex text-base font-semibold cursor-default font-h text-primary-500"
                  v-tooltip.right="'Can’t go back now unfortunately 😅'"
                >
                  <span>
                    5. Review and correct transcript
                  </span>
                  <LockIcon
                    class="ml-2"
                  />
                </div>
              </div>
              <div v-else class="flex justify-between">
                <span @click="goToStep(5)">5. Review and correct transcript</span>
                <Button
                  v-if="!minimized && step===5"
                  @click="confirmMakeClips()"
                  v-tooltip.bottom="'Tip: Preview the clips before exporting.<br/>This step will be locked later 😲'"
                  :disabled="!previewTimestamps.length"
                  :variant="previewTimestamps.length ? 'primary' : 'disabled'"
                >
                  Next
                </Button>
              </div>

              <template slot="stepBody">
                <div class="pr-2 overflow-auto max-h-80">
                  <TranscriptEditor
                    v-for="(ts, index) in timestamps"
                    :id="`transcript-${ts.index}`"
                    :key="ts.index"
                    :index="index"
                    :title="ts.title"
                    :subtitle="ts.subtitle || []"
                    @onChange="updateSubtitleForTimestamp"
                    @focus="pauseWhenEditingTranscript"
                  />
                </div>
              </template>
            </Step>

            <!-- Step 6 -->
            <Step
              :isEnabled="enabledSteps.includes(6)"
              :open="!minimized && step === 6"
              :stepHeadClass="''"
              :stepBodyClass="''"
              :showConnector="false"
            >
              <div class="flex justify-between">
                <span @click="goToStep(6)">
                  {{enableSubtitleFormatting ? '6.' : '5.'}} Export Clips
                </span>
                <div v-show="!minimized && step === 6" class="relative -top-1">
                  <Button
                    v-if="selectedClips.length"
                    @click="onDownloadClicked(selectedClips, ['mp3', 'mp4'])"
                  >
                    Download Selected
                  </Button>
                  <Button
                    v-else
                    @click="downloadAll"
                    :variant="selectedClipsForDownloadAll.length ? 'primary' : 'disabled'"
                    :disabled="!selectedClipsForDownloadAll.length"
                  >
                    Download All
                  </Button>
                </div>
              </div>

              <template slot="stepBody">
                <div v-if="!minimized && step===6" class="grid grid-cols-3 gap-4 pb-48">
                  <ExportClip
                    v-for="el in (makingClips ? timestamps : clips)"
                    :key="makingClips ? el.title : el.uid"
                    :clip="el"
                    :working="makingClips"
                    :selected="selectedClips.some(t => t.uid === el.uid)"
                    :isPlaying="exportClip && exportClip.uid === el.uid && exportClip.playing"
                    @onSelect="selectClip"
                    @onSelectForDownloadAll="selectClipForDownloadAll"
                    @onTogglePlay="playExportClip"
                    @onShare="onShare"
                    ref="exportClips"
                  />
                </div>
              </template>
            </Step>
          </div>
        </div>
      </section>

      <section
        class="relative flex flex-col h-screen col-span-3 overflow-hidden bg-white border-l border-primary-300"
        :class="{
          'portraitContainer': ![1, 2, 6].includes(step) && aspectRatio === 'portrait'
        }"
      >
        <div class="relative thumbnail group">
          <HTMLVideoPlayer
            v-if="exportClip"
            :key="exportClip.uid"
            :path="exportClip.url"
            ref="exportPlayer"
            @onPlayToggle="onExportPlayToggled"
          />
          <PreviewPlayer
            v-if="showPreviewPlayer"
            v-show="[3, 4, 5].includes(step)"
            ref="previewPlayer"
            playerType="audiogram"
            :showplayer="[3, 4, 5].includes(step)"
            :disableInteraction="true"
            :showInteractionOnHover="step === 3"
            :debug="debug"
            :key="selectedFile.key"
            :fileKey="selectedFile.key"
            :fileOwner="selectedFile.owner"
            :from="step===4 && previewClip ? previewClip.start : 0"
            :to="step===4 && previewClip ? previewClip.end : 99999"
            :subtitleStyle="enableSubtitleFormatting ? (subtitleStyle || {}) : null"
            :loading="previewLoading"
            :showSeeker="false"
            :customClickAction="step=== 4 || step === 5"
            :videoPlayerInfo="videoPlayerInfo"
            :showVideoPlayer="Boolean(projectInfo)"
            :subtitleOverRide="subtitleOverRide"
            @onPlayerReady="onPlayerReady"
            @onTogglePlay="resumePreview"
            @onPreviewEnd="onPreviewEnd"
            @goToTime="goToTime"
          />
          <div v-if="!exportClip && [1, 2, 6].includes(step)">
            <img
              src="/images/projects/preview-window.svg"
              alt="preview-placeholder"
              class="w-full"
            >
          </div>
        </div>

        <div v-if="[1, 2].includes(step)">
          <img
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>

        <div v-if="step===3" class="mr-2 rightContent">
          <div class="px-4 pt-4 text-lg font-semibold font-h">Customise your audiogram</div>

          <div class="overflow-y-scroll rightChild">
            <AudiogramCustomisation
              :key="projectId"
              :template="template"
              :userId="$route.params.userId"
              :projectId="projectId"
              :projectInfo="projectInfo"
              @onChange="updateAudiogramRender"
            />
          </div>
        </div>

        <!-- step 4 3rd column -->
        <div v-if="step===4">
          <div
            v-if="timestampsWithoutError.length"
            class="relative py-4 pl-4 overflow-hidden rightContent"
          >
            <div class="mb-3 text-lg font-semibold font-h text-primary">Preview Clips</div>

            <div class="pr-4 previewList">
              <PreviewClip
                v-for="el in [...timestampsWithoutError].reverse()"
                playerType="audiogram"
                :class="{'mb-2': true, 'border-primary': previewClip && previewClip.index === el.index}"
                :key="el.index"
                :clip="el"
                :isPlaying="isPlaying && previewClip && previewClip.index === el.index"
                :loading="!playerReady || !isDocReady"
                :totalTime="totalTime || 1000"
                :showDelete="true"
                :editable="true"
                :showDuration="true"
                :videoPlayerInfo="videoPlayerInfo"
                @onTogglePlay="playClip(el)"
                @onDelete="deleteTimestamp(el.index)"
                @onSelect="selectPreviewClip(el)"
                @onUpdate="createManualClipTimestamp"
              />
            </div>
          </div>
          <img
            v-else
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>

        <!-- step 5 3rdcolumn -->
        <div
          v-else-if="step===5 && !previewTimestamps.length"
          class="animate-pulse"
        >
          <img
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>
        <div
          v-else-if="step===5"
          class="relative py-4 pl-4 overflow-hidden rightContent"
        >
          <div class="mb-3 text-lg font-semibold font-h text-primary">Clips</div>

          <div class="pr-4 previewList">
            <PreviewClip
              v-for="el in previewTimestamps"
              class="mb-2"
              playerType="audiogram"
              :key="el.value"
              :clip="el"
              :isPlaying="isPlaying && previewClip && previewClip.index === el.index"
              :loading="sourcesLoading"
              :totalTime="totalTime || 1000"
              :showDelete="step === 4"
              :videoPlayerInfo="videoPlayerInfo"
              :showDuration="true"
              @onTogglePlay="playClip(el)"
              @onDelete="deleteTimestamp(el.index)"
              @onSelect="scrollToClipTranscript(el.index)"
            />
          </div>
        </div>

        <!-- step 6 3rdcolumn -->
        <div v-else-if="step===6 && selectedClips.length" class="relative p-4 overflow-y-auto rightContent">
          <div class="mb-3 text-lg font-semibold font-h text-primary">Share Clips</div>
          <div
            v-for="cl in selectedClips"
            v-if="cl.exports && cl.exports.mp4 && cl.exports.mp4.publish"
            :key="cl.uid"
            class="flex items-center justify-between max-w-full px-4 py-3 mb-2 border rounded border-primary-300 group"
          >
            <div class="w-8/12">
              <div class="overflow-hidden text-sm font-semibold text-primary overflow-ellipsis whitespace-nowrap">
                {{cl.title}}
              </div>
              <div
                class="mt-1 overflow-hidden text-xs cursor-pointer text-primary-800 overflow-ellipsis whitespace-nowrap group-hover:text-blue"
                @click="onShare(`${pubURL}/video/${cl.exports.mp4.publish}`)"
              >
                {{`${pubURL}/video/${cl.exports.mp4.publish}`}}
              </div>
            </div>

            <div
              class="cursor-pointer text-primary-300 hover:text-primary-700"
              @click="onShare(`${pubURL}/video/${cl.exports.mp4.publish}`)"
            >
              <ShareIcon />
            </div>
          </div>
        </div>

      </section>
    </div>
  </main>
</template>

<script>
import 'firebase/auth'
import 'firebase/database'
import * as firebase from 'firebase/app'
import uuidv1 from 'uuid/v1'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import isEqual from 'lodash/isEqual'

import { myProseEditor, focusCursorOnLocation, scrollToLocation, getStateFromJsonDoc } from '@/view/voiceEditor/proseEditor/util/utility'
import { mapGetters, mapActions } from 'vuex'
import { saveExportedFileMeta, updatePodcast } from '@/services/api/podcast'
import { getProjectInfo, updateProject } from '@/services/api/project'

import {
  computeTotalDuration,
  saveDoc,
  createAndCopyDocTime,
  createClippedDoc,
  addTextSelection,
  createClippedDocFromLocation,
  createAndCopyDocLocation
} from '@/view/voiceEditor/proseEditor/util/transoformationUtility'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!./../voiceEditor/proseEditor/util/transoformationUtilityWorker.js'

import VToastr from 'vue-toastr'
import CONSTANTS from '@/constants/CONSTANTS'
import Header from '@/components/Drive/core/Header.vue'

import BrandIcon from '@/components/base/icons/Brand.vue'
import Button from '@/components/base/buttons/Button.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import LockIcon from '@/components/base/icons/Lock.vue'
import SearchIcon from '@/components/base/icons/Search.vue'
import CloseIcon from '@/components/base/icons/Close.vue'
import ShareIcon from '@/components/base/icons/Share.vue'

import AudiogramCustomisation from './components/AudiogramCustomisation/index.vue'
import PreviewPlayer from './PreviewPlayer'
import ExportClip from './components/ExportClip.vue'
import PreviewClip from './components/PreviewClip.vue'
import TranscribingFile from './components/TranscribingFile.vue'
import Downloads from './components/Downloads.vue'
import HTMLVideoPlayer from './components/HtmlVideoPlayer.vue'
import DocPreview from './PreviewPlayer/DocPreview.vue'

import { findAndToggleObj, getResultsForUI } from './utils'
import {
  searchHighlightInDoc,
  removeAllSearchHighlights, // remove all search highlights
  removeAllSelections,
  addCustomHighlight
} from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import { copyToClipboard, formatAsSubtitleNew } from '@/utilities/utility'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import Step from '@/components/base/display/Stepper/Step.vue'
import playerInitData from '@/components/VideoPlayer/playerElementsData'

import { getBrandkitInfoOnce } from '@/services/api/brandkit'

import AspectRatio from './components/AspectRatio/index.vue'
import Templates from './components/Templates/index.vue'
import TranscriptEditor from './components/TranscriptEditor/index.vue'

require('@fortawesome/fontawesome-pro/css/all.css')

const transformUtilityWorker = worker()

export default {
  name: 'ClipEditor',
  components: {
    Header,
    BrandIcon,
    Button,
    PreviewPlayer,
    ExportClip,
    AudiogramCustomisation,
    TranscribingFile,
    VToastr,
    Downloads,
    PreviewClip,
    HTMLVideoPlayer,
    DocPreview,
    CircularLoader,
    ChevronDown,
    SearchIcon,
    CloseIcon,
    ShareIcon,
    Step,
    TranscriptEditor,
    AspectRatio,
    Templates,
    LockIcon
  },
  data: function() {
    return {
      listener: null,
      searchterm: '',
      searchResults: [],
      searchResultIndex: 0,
      avaialbleExportFormats: [
        // 'wav',
        'mp3',
        'mp4'
      ],
      projectInfo: null,
      ownerId: this.$route.params.userId,
      projectId: this.$route.params.projectId,
      loading: true, // for middle column
      totalTime: 0,

      isEditingTitle: false,
      // title: '',

      step: 1,
      enabledSteps: [],
      maxStep: 5,
      minimized: true,
      fileErrorCodes: CONSTANTS.ERROR_CODES,

      docState: null,
      docInit: false,

      // step 2
      // notes, timestamps part of project info
      showNoteErrors: false,
      previewLoading: 'false',
      searchOpen: false,

      // step 3
      makingPreview: false,
      makingClips: false,
      makingClipsForTooLong: false,
      clipMakeProgress: 100,
      playerReady: false,
      isDocReady: false,

      // step 3-4
      previewClip: null,

      // step 4
      exportClip: null,
      selectedClips: [],
      exportWithSubtitles: true,
      selectedClipsForDownloadAll: [],
      shareUrl: '',

      downloads: '',
      downloadName: '',
      previewTimestamps: [],
      debug: this.$route.query.debug === 'true',
      introAsources: [],
      outroAsources: [],
      pubURL: CONSTANTS.PUBLISH_URL,
      brandkitVersion: 'v1',
      isSubtitlesDirty: false
    }
  },
  computed: {
    ...mapGetters({
      allFiles: 'app/computedList',
      isPlaying: 'editor/isPlaying',
      user: 'app/user',
      projectDocId: 'app/projectDocId',
      audioLoading: 'editor/sourcesLoading',
      videoLoading: 'video/sourcesLoading',
      uploadingList: 'app/uploadingList',
      videoReady: 'video/sourcesReady',
      audioReady: 'editor/sourcesReady'
    }),
    host: function() {
      return window.location.origin
    },
    videoPlayerInfo: function() {
      const {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      } = { ...this.projectInfo }
      const info = {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        showSampleSubtitle: [2, 3].includes(this.step),
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      }

      return info
    },
    isReadyToMakePreviews: function() {
      return (this.playerReady && this.docInit)
    },
    mediaReady: function() {
      return this.loading !== 'true' && this.audioReady && this.videoReady
    },
    selectedAll: function() {
      return this.clips.length && (this.clips.length === this.selectedClips.length)
    },
    files: function() {
      const selectedFile = this.allFiles.find(item => (this.selectedFile && (this.selectedFile.key === item.key)))
      return [...(selectedFile ? [selectedFile] : []), ...(this.allFiles || []).filter(t => !t.isClip && (selectedFile ? t.key !== selectedFile.key : true))]
    },
    sourcesLoading: function() {
      return this.audioLoading || this.videoLoading
    },
    title: {
      get: function() {
        return this.projectInfo ? this.projectInfo.title || '' : ''
      },
      set: function(title) {
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: { title, heading: title }
          }
        )
      }
    },
    heading: function() {
      return this.projectInfo ? this.projectInfo.heading || '' : ''
    },
    aspectRatio: function() {
      return this.projectInfo ? this.projectInfo.aspectRatio || '' : ''
    },
    template: function() {
      return this.projectInfo ? this.projectInfo.template || '' : ''
    },
    selectedFile: function() {
      const el = this.projectInfo
      return el && el.doc ? el.doc : null
    },
    showPreviewPlayer: function() {
      return !this.exportClip && this.selectedFile && this.selectedDocProgress === 100 && [1, 2, 3, 4, 5, 6].includes(this.step)
    },
    shadowDoc: function() {
      const el = this.projectInfo
      return el && el.shadowDoc ? el.shadowDoc : null
    },
    selectedDocProgress: function() {
      if (this.selectedFile) {
        const fullDoc = this.files.find(el => el.key === this.selectedFile.key)
        return fullDoc ? fullDoc.doc_progress || 0 : 0
      } else return 0
    },
    form: function() {
      const el = this.projectInfo
      return el && el.form ? el.form : false
    },
    enableSubtitleFormatting: function() {
      const el = this.projectInfo
      const val = el && el.projectType ? (el.enableSubtitleFormatting || false) : false
      return val
    },
    subtitleStyle: function() {
      const el = this.projectInfo
      return el && el.projectType ? (el.subtitleStyle || {}) : false
    },
    projectType: function() {
      const el = this.projectInfo
      return el && el.projectType ? el.projectType : 'Automated Video Clipper'
    },
    projectMeta: function() {
      const meta = CONSTANTS.PROJECT_TYPES.find(el => el.name === this.projectType)
      return meta
    },
    isTimestampError: function() {
      const el = this.projectInfo
      const timestamps = el && el.timestamps ? el.timestamps : []
      const error = timestamps.some(el => el.error) || !timestamps.length
      return error
    },
    showTimestampError: function() {
      return this.showNoteErrors && this.isTimestampError
    },
    timestamps: function() {
      const el = this.projectInfo
      return el && el.timestamps ? el.timestamps : null
    },
    subtitleOverRide: function() {
      return this.step === 5 && this.previewClip ? this.timestamps[this.previewClip.tsIndex].subtitle : null
    },
    timestampsWithoutError: function() {
      const timestamps = this.timestamps
      return (timestamps || []).filter(el => !el.error)
    },
    clips: function() {
      const el = this.projectInfo
      return el && el.clips ? el.clips : []
    }
  },
  methods: {
    ...mapActions({
      toggleState: 'editor/toggleState',
      resetVideo: 'video/resetVideo',
      openModal: 'dialogs/openModal',
      initPlayer: 'video/initPlayer',
      hideDeleted: 'editor/hideDeleted'
    }),
    scrollToClipTranscript: function(index) {
      const elem = document.getElementById(`transcript-${index}`)
      if (elem) elem.scrollIntoView()
    },
    pauseWhenEditingTranscript: function() {
      if (this.isPlaying) this.toggleState(true)
    },
    limitString: function(str, limit = 30) {
      if (str.length > limit) return `${str.slice(0, limit)}...`
      else return str
    },
    onShare: function(url) {
      window.open(url, '_blank')
      copyToClipboard(url)
      this.showToast('Link copied')
    },
    // transcript search
    onSearchBlur: function() {
      if (!this.searchterm) this.searchOpen = false
    },
    openSearch: function() {
      this.searchOpen = true
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 100)
    },
    research: function(searchterm) {
      this.$refs.searchInput.blur()
      const search = searchterm || this.searchterm
      removeAllSearchHighlights()
      this.searchResults = getResultsForUI(searchHighlightInDoc(search))
      this.goToSearchIndex(0)
    },
    debouncedSearch: debounce(function(searchterm) {
      const search = searchterm || this.searchterm
      removeAllSearchHighlights()
      this.searchResults = getResultsForUI(searchHighlightInDoc(search))
      this.goToSearchIndex(0)
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 100)
    }, 750),
    resetSearch: function() {
      removeAllSelections()
      removeAllSearchHighlights()
      this.searchterm = ''
      this.searchResults = []
      this.searchResultIndex = 0
      this.searchOpen = false
    },
    goToSearchIndex: function(index) {
      this.searchResultIndex = index
      const result = this.searchResults[index]
      if (result && result.location) {
        const location = result.location
        removeAllSelections()
        scrollToLocation(location)
        addCustomHighlight(location.start, location.end, 'searchSelection', 'searchSelection')
      }
    },
    navigateSearch: function(val) {
      const resultsLength = this.searchResults.length
      if (val === 1) {
        if (this.searchResultIndex < (resultsLength - 1)) {
          this.goToSearchIndex(this.searchResultIndex + 1)
        }
      } else {
        if (this.searchResultIndex > 0) {
          this.goToSearchIndex(this.searchResultIndex - 1)
        }
      }
    },
    // download in export step
    downloadAll: function() {
      this.$refs.exportClips.forEach(el => {
        el.onSelectForDownloadAll(true)
      })

      this.onDownloadClicked(this.selectedClipsForDownloadAll, ['mp3', 'mp4'])
    },
    showToast: function(msg = '', type = 'info', title = '💡') {
      this.$refs.toastr.Add({
        msg,
        type,
        title: type === 'info' ? title : '',
        position: 'toast-bottom-center',
        progressbar: false,
        preventDuplicates: true,
        timeout: 4000,
        classNames: ['animate__animated', 'animate__zoomInUp']
      })
    },
    goToStep: function(step) {
      if (this.enabledSteps.includes(step)) {
        if (this.step !== step) {
          this.minimized = false
          this.step = step
        } else {
          this.minimized = !this.minimized
        }
      } else {
        if (this.step === 1) this.showToast('Please Select a File First')
        if (this.step === 2) this.showToast('Please Select an Aspect Ratio first')
      }
    },
    // Step 1
    showUploadModal: function() {
      this.$parent.showUploadModal(true)
    },
    selectFile: async function({ owner, key, title }, next = true) {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            title,
            heading: title,
            doc: {
              key,
              title,
              owner
            },
            form: false,
            timestamps: [],
            confirmedCustomisations: false,
            confirmedNotes: false,
            confirmedClips: false
          }
        }
      )
      this.previewTimestamps = []
      if (next) {
        this.step = 2
        this.enabledSteps = [1, 2]
      } else {
        this.enabledSteps = [1]
      }

      const brandkit = await getBrandkitInfoOnce(
        {
          userId: this.$route.params.userId,
          type: this.projectMeta.type,
          version: this.brandkitVersion
        }
      )
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: brandkit
        }
      )
    },
    removeFile: async function() {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            title: '',
            doc: null
          }
        }
      )
      this.showToast('File is removed from the project, you can find it in the drive.')
    },
    // step 2
    selectAspectRatio: async function(aspectRatio) {
      this.enabledSteps = [1, 2, 3]
      this.step = 3

      this.updateAudiogramRender({
        ...this.videoPlayerInfo,
        aspectRatio
      })

      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            aspectRatio
          }
        }
      )
    },
    // step 3
    customiseAudiogram: async function(next = true) {
      this.enabledSteps = [1, 2, 3, 4]
      this.step = 4
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            confirmedCustomisations: true
          }
        }
      )
    },
    selectTemplate: async function(template) {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            template
          }
        }
      )
    },
    // Step 4
    createManualClipTimestamp: async function({ id, ...update }) {
      let timestamps = [...(this.timestamps || [])]
      const indexToUpdate = timestamps.findIndex(el => el.index === id)
      if (indexToUpdate === -1) {
        timestamps.push({
          index: id,
          ...update
        })
      } else {
        timestamps = [
          ...timestamps.slice(0, indexToUpdate),
          {
            ...timestamps[indexToUpdate],
            index: id,
            ...update
          },
          ...timestamps.slice(indexToUpdate + 1)
        ]
      }

      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
    },
    deleteTimestamp: async function(id) {
      let timestamps = [...(this.timestamps || [])]
      const indexToDelete = timestamps.findIndex(el => el.index === id)
      if (indexToDelete !== -1) {
        timestamps = [
          ...timestamps.slice(0, indexToDelete),
          ...timestamps.slice(indexToDelete + 1)
        ]
      }
      if (this.step === 4) this.clearPreview(true)
      if (!timestamps.length) this.enabledSteps = [1, 2, 3, 4]
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
    },
    confirmClips: async function() {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            confirmedNotes: true
          }
        }
      )
      if (!this.enableSubtitleFormatting) {
        this.confirmMakeClips()
      } else {
        this.enabledSteps = [1, 2, 3, 4, 5]
        this.step = 5
        await this.makePreviewClips(true)
      }
    },
    updateDocState: function(state) {
      myProseEditor.view.updateState(state)
      myProseEditor.registerComputeOnDispatchTransaction()
      this.$refs.previewPlayer.syncSubtitle()
    },
    makePreviewClips: async function(updateSubtitles = false) {
      this.makingPreview = true
      if (this.isPlaying) this.toggleState(true)
      const docStateJson = this.docState.doc.toJSON()

      const previewTimestamps = await Promise.all(this.timestamps.map((ts, index) => new Promise(async(resolve) => {
        let clipState, clippedDoc
        if (ts.location) {
          clippedDoc = await transformUtilityWorker.createClippedDocFromLocationWorker(docStateJson, ts.location.start, ts.location.end)
        } else {
          clippedDoc = await transformUtilityWorker.createClippedDocWorker(docStateJson, ts.start, ts.end)
        }
        clipState = getStateFromJsonDoc(clippedDoc.state)
        const duration = computeTotalDuration(clipState)
        const subtitle = ts.subtitle || formatAsSubtitleNew(clipState)

        resolve({
          ...ts,
          duration,
          subtitle,
          clipState,
          tsIndex: index
        })
      })))
      this.previewTimestamps = previewTimestamps

      // Done: save subtitle here
      if (updateSubtitles) {
        await updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: {
              timestamps: previewTimestamps.map(({ tsIndex, clipState, ...rest }) => ({ ...rest }))
            }
          }
        )
      }

      const firstClip = this.previewTimestamps[0]
      this.previewClip = firstClip
      this.updateDocState(firstClip.clipState)

      this.makingPreview = false
      if (this.$refs.previewPlayer) {
        this.$refs.previewPlayer.syncSubtitle()
        this.goToTime(0)
      }
    },
    updateSubtitleForTimestamp: debounce(async function({ index, subtitle }) {
      let timestamps = [...this.timestamps]
      timestamps[index] = {
        ...timestamps[index],
        subtitle
      }
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
      this.isSubtitlesDirty = true
    }, 500),
    onPlayerReady: function() {
      this.playerReady = true
    },
    selectClip: function(clip, forceSelect = false) {
      this.selectedClips = findAndToggleObj(this.selectedClips, 'uid', clip, forceSelect)
    },
    selectClipForDownloadAll: function(clip, forceSelect = false) {
      this.selectedClipsForDownloadAll = findAndToggleObj(this.selectedClipsForDownloadAll, 'uid', clip, forceSelect)
    },
    playExportClip: function(clip) {
      const isSameClipClicked = this.exportClip && (this.exportClip.uid === clip.uid)
      if (isSameClipClicked) {
        if (this.$refs.exportPlayer) this.$refs.exportPlayer.togglePlay()
      } else this.exportClip = clip
    },
    onExportPlayToggled: function(isPlaying) {
      this.$set(this.exportClip, 'playing', isPlaying)
    },
    onPreviewEnd: function() {
      if (this.previewClip) {
        const clip = this.previewClip
        if (this.step === 4 && clip.location) {
          focusCursorOnLocation(clip.location.start, myProseEditor)
        } else this.goToTime(0)
      }
    },
    clearPreview: function(toggle = true) {
      if (toggle && this.isPlaying) this.toggleState(true)
      this.previewClip = null
      myProseEditor.removeCustomHighlight()
    },
    selectPreviewClip: function(clip) {
      const isSameClipClicked = this.previewClip && isEqual(this.previewClip.index, clip.index)

      if (!isSameClipClicked) {
        if (this.isPlaying) this.toggleState(true)
        this.previewClip = clip
        if (this.step === 5) {
          this.updateDocState(clip.clipState)
          this.goToTime(0)
        }

        if (this.step === 4 && clip.location) {
          addTextSelection(this.docState, clip.location)
          focusCursorOnLocation(clip.location.start, myProseEditor)
        }
      }
    },
    playClip: function(clip) {
      const isSameClipClicked = this.previewClip && isEqual(this.previewClip.index, clip.index)

      if (isSameClipClicked) this.toggleState(true)
      else {
        if (this.isPlaying) this.toggleState(true)
        this.previewClip = clip

        if (this.step === 4 && clip.location) {
          addTextSelection(this.docState, clip.location)
          focusCursorOnLocation(clip.location.start, myProseEditor)
        }
        if (this.step === 5) {
          this.updateDocState(clip.clipState)
          this.goToTime(0)
        }

        if (!this.isPlaying) this.toggleState(true)
      }
    },
    goToTime: function(time) {
      this.$refs.clipDoc.goToTime(time)
    },
    onLoadingChange: function(loading) {
      this.previewLoading = loading
    },
    resumePreview: function() {
      if (this.step === 4) {
        // this.clearPreview(false)
        this.toggleState(true)
      } else if (this.previewClip) this.playClip(this.previewClip)
      else if (this.previewTimestamps.length) this.playClip(this.previewTimestamps[0])
    },
    asyncUpdateClip: async function(uid, update) {
      return new Promise((resolve, reject) => {
        updatePodcast(this.$route.params.userId, uid, update)
          .then(resolve)
          .catch(reject)
      })
    },
    confirmMakeClips: function() {
      const dontAskForClipConfirmation = localStorage.dontAskForClipConfirmation
      const vm = this
      if (dontAskForClipConfirmation) {
        this.makeClips()
      } else {
        this.openModal({
          name: 'ClipperExportMessage',
          props: {
            next: vm.makeClips
          }
        })
      }
    },
    makeClips: async function() {
      this.step = 6
      this.enabledSteps = [6]
      this.makingClips = true // loading state for step 4
      setTimeout(() => {
        this.makingClipsForTooLong = true
      }, 30000)
      // delete old clips for this project if any
      const {
        clips: currentClips,
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      } = { ...this.projectInfo }
      const videoPlayerInfo = {
        ...(heading ? { heading } : {}),
        ...(backgroundImage ? { backgroundImage } : {}),
        ...(coverArt ? { coverArt } : {}),
        enableSubtitleFormatting: true,
        ...(enableSubtitleFormatting && subtitleStyle ? { subtitleStyle } : {}),
        aspectRatio,
        ...(template ? { template } : {}),
        ...(accentColor ? { accentColor } : {}),
        ...(backgroundType ? { backgroundType } : {}),
        ...(backgroundColor ? { backgroundColor } : {}),
        ...(logo ? { logo } : {}),
        ...(titleStyle ? { titleStyle } : {}),
        enableLogo: enableLogo || false
      }
      const userId = this.$route.params.userId
      const projectId = this.$route.params.projectId
      if (currentClips && currentClips.length) {
        await updateProject(
          {
            userId,
            projectId,
            update: { clips: [] }
          }
        )
      }

      // make new clips & save in project
      const { key, owner } = this.selectedFile
      let mainDocState = this.docState
      const clips = await Promise.all(this.timestamps.map(
        el => this.makeClippedDoc({
          key,
          owner,
          start: el.start,
          end: el.end,
          location: el.location,
          title: el.title,
          ...(this.isSubtitlesDirty ? { subtitle: el.subtitle } : {}),
          mainDocState
        })
      ))

      await Promise.all([
        Promise.all(clips.map((el, i) => this.asyncUpdateClip(el.uid, {
          deleted: true,
          updated: Date().toString(),
          isClip: true,
          playerType: 'audiogram',
          projectId,
          clipId: el.uid,
          clipIndex: i,
          ...(el.subtitle ? { subtitle: el.subtitle } : {}),
          ...videoPlayerInfo
        }))), // save subtitle style
        Promise.all(clips.map(el => this.asyncUpdateClip(el.copyDocId, {
          updated: Date().toString(),
          isClip: true,
          playerType: 'audiogram',
          ...videoPlayerInfo
        }))), // fix update for new clips
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: { clips, confirmedClips: true }
          }
        )
      ])
      await this.exportClips()

      this.makingClips = false
    },
    makeClippedDoc: async function({ key, owner, start, end, location, title, mainDocState, subtitle }) {
      const version = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      return new Promise(async (resolve, reject) => {
        try {
          const clipDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
          const clipCopyDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
          const clipOwnerId = this.user.uid
          const clip = {
            uid: clipDocId,
            owner: clipOwnerId,
            version,
            start,
            end,
            title,
            ...(subtitle ? { subtitle } : {}),
            docId: key,
            docOwner: owner,
            copyDocId: clipCopyDocId
          }

          // save copy
          let { state: clipStateCopy, latestKey: latestKeyCopy } = await createAndCopyDocTime(mainDocState, start, end, clipCopyDocId, clipOwnerId, title)
          await saveDoc(clipStateCopy, clipOwnerId, clipCopyDocId, firebase, latestKeyCopy)

          // create clip doc,
          let clipState, latestKey, clippedDoc, clipDuration
          if (location) {
            clippedDoc = await createAndCopyDocLocation(mainDocState, location.start, location.end, clipDocId, clipOwnerId, title)
          } else {
            clippedDoc = await createAndCopyDocTime(mainDocState, start, end, clipDocId, clipOwnerId, title)
          }
          clipState = clippedDoc.state
          latestKey = clippedDoc.latestKey

          console.log('project clip state: ', start, end, clipState, clip.uid)

          await saveDoc(clipState, clipOwnerId, clipDocId, firebase, latestKey)
          resolve(clip)
        } catch (error) {
          reject(error)
        }
      })
    },
    exportClips: async function() {
      const { clips } = this.projectInfo
      await Promise.all(clips.map(
        el => this.asyncExportClip(el)
      ))
      this.enabledSteps = [6]
      this.showToast('We’ll email you when the clips are ready. You can close this window for now.', 'info', '🎉')
    },
    asyncExportClip: async function({ uid, owner, version }) {
      return new Promise((resolve, reject) => {
        saveExportedFileMeta(owner, uid, version, 'mp4', {
          status: 'start',
          timestamp: Date.now(),
          startedBy: owner
        },
        resolve,
        reject
        )
      })
    },
    onDownloadClicked: async function(clips, exportTypes) {
      const time = dayjs().format('D MMM, h:mm a')
      const zipFilename = `${this.projectInfo.title || 'project'} - ${time}.zip`
      this.downloadName = zipFilename
      this.downloads = 'Preparing files'

      let filesToDownload = []
      clips.forEach(t => {
        const { exports, title } = t
        exportTypes.forEach(el => {
          if (exports[el] && exports[el].url) {
            filesToDownload.push({
              type: el,
              name: title,
              url: exports[el].url
            })
          }
        })
      })
      this.downloads = `Downloading ${filesToDownload.length} files`
      // make urls
      filesToDownload = await Promise.all(filesToDownload.map(({ type, name, url }) => new Promise(async (resolve, reject) => {
        const fileUrl = await firebase.storage().ref(url).getDownloadURL()
        resolve({
          type,
          name,
          url: fileUrl
        })
      })))
      const urls = filesToDownload.map(t => t.url)
      this.downloads = `Zipping 0 of ${filesToDownload.length} files`
      const vm = this
      const zip = new JSZip()
      let count = 0

      urls.forEach((url, index) => {
        const { name, type } = filesToDownload[index]
        const filename = `${name}.${type}`
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            throw err // or handle the error
          }
          zip.file(filename, data, { binary: true })
          count++
          vm.downloads = `Zipping ${count} of ${filesToDownload.length} files`
          if (count === urls.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              vm.downloads = ''
              saveAs(content, zipFilename)
            })
          }
        })
      })
    },
    decideStep: async function(projectInfo) {
      const {
        doc,
        aspectRatio,
        confirmedCustomisations,
        confirmedNotes,
        confirmedClips,
        timestamps
      } = { ...projectInfo }
      let step = 1
      let enabledSteps = [1]
      // if selected doc go to step 2
      if (doc && doc.key) {
        const fullDoc = this.files.find(el => el.key === doc.key)
        if (fullDoc && fullDoc.doc_progress === 100) {
          enabledSteps = [1, 2]
          step = 2
        } else {
          this.step = 1
          this.enabledSteps = [1]
          this.loading = false
          setTimeout(() => {
            this.minimized = false
          }, 300)
          return
        }
      }
      // if valid timestamps go to step 3
      if (aspectRatio) {
        step = 3
        enabledSteps = [1, 2, 3]
      }
      if (confirmedCustomisations) {
        step = 4
        enabledSteps = [1, 2, 3, 4]
      }
      if (timestamps && timestamps.length && confirmedNotes) {
        step = 5
        enabledSteps = [1, 2, 3, 4, 5]
      }

      // if created preview go to step 4
      if (confirmedClips) {
        enabledSteps = this.debug ? [1, 2, 3, 4, 5, 6] : [6]
        step = 6
      }
      this.step = step
      this.enabledSteps = enabledSteps
      this.loading = false
      const audiogramOnboarded = window.localStorage.getItem('audiogramOnboarded')

      setTimeout(() => {
        this.minimized = false
        if (!audiogramOnboarded) {
          this.showToast('Create audiograms by clipping portions of your recordings')
          window.localStorage.setItem('audiogramOnboarded', true)
        }
      }, 300)
    },
    documentInitialised() {
      this.docState = myProseEditor.view.state
      this.totalTime = computeTotalDuration(this.docState)
      this.docInit = true
    },
    keyDownListner: function(e) {
      const watchKeys = [
        32 // Space
      ]

      if ((e.metaKey || e.ctrlKey) && watchKeys.includes(e.keyCode)) {
        e.preventDefault()
      } else if (watchKeys.includes(e.keyCode)) {
        e.preventDefault()
        if (e.keyCode === 32) this.toggleState(true)
      }
    },
    waitForExports: function (e) {
      if (this.makingClips) {
        return 'Don’t leave just yet! You will lose all progress if you exit now. Are you sure you want to leave?'
      }
    },

    // update previews
    updateAudiogramRender: async function(videoPlayerInfo) {
      const newVideoInfo = {
        ...videoPlayerInfo,
        showSampleSubtitle: [2, 3].includes(this.step)
      }
      console.log('new videoPlayerInfo', newVideoInfo)
      const initData = await playerInitData.audiogram(0.2111, newVideoInfo, 'audiogram')
      this.initPlayer(initData)
    }
  },
  created: function() {
    window.projects = true
    window.onbeforeunload = this.waitForExports
  },
  mounted: function() {
    if (window.Intercom) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }

    this.resetVideo()
    this.hideDeleted()

    // get project info once and decide which step to go to
    getProjectInfo(
      {
        userId: this.$route.params.userId,
        projectId: this.$route.params.projectId
      }
    ).once('value', snap => this.decideStep(snap.val()))
    // setup projectInfo listner
    this.listener = getProjectInfo(
      {
        userId: this.$route.params.userId,
        projectId: this.$route.params.projectId
      }
    ).on('value', snap => {
      this.projectInfo = snap.val()
    })
  },
  watch: {
    searchterm: function(val) {
      this.debouncedSearch(val)
    },
    mediaReady: function(val, oldVal) {
      if (!val && oldVal) this.isDocReady = false
      if (!oldVal && val) {
        this.isDocReady = true
      }
    },
    projectDocId: function(val) {
      if (val) {
        this.selectFile({
          owner: this.$route.params.userId,
          key: val.uid,
          title: val.title
        }, false)
      }
    },
    selectedDocProgress: function(val, oldVal) {
      if (oldVal && oldVal !== 100 && val === 100) {
        this.step = 2
        this.enabledSteps = [1, 2]
      }
    },
    step: async function(val, oldVal) {
      if (this.isPlaying) this.toggleState(true)
      if (myProseEditor) myProseEditor.removeCustomHighlight()

      // recompute total time if step becomes 2
      if (val === 4) {
        this.previewClip = null
        if (this.$refs.noteInput) this.$refs.noteInput.focus()
        document.getElementById('manualClipperDoc').addEventListener('keydown', this.keyDownListner)
      }
      if (oldVal === 4) {
        this.resetSearch()
        document.getElementById('manualClipperDoc').removeEventListener('keydown', this.keyDownListner)
      }
      if (val === 5) {
        this.previewClip = null
      }
      if (oldVal === 5) {
        this.updateDocState(this.docState)
      }
    },
    isReadyToMakePreviews: function(val) {
      if (val && this.step === 5) {
        setTimeout(() => {
          this.makePreviewClips()
        }, 1000)
      }
    }

  },
  beforeDestroy: function() {
    if (this.listener) {
      getProjectInfo(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId
        }
      ).off('value', this.listener)
    }
    myProseEditor.stopAudioPlayer()
    myProseEditor.removeCustomHighlight()
    this.resetVideo()
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss" />
