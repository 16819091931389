<template>
  <div class="relative grid grid-cols-1 py-4 pl-4 pr-2 gap-y-4">
    <div class="col-span-1">
      <div class="flex items-center justify-between mb-1">
        <div class="font-ui text-primary font-medium text-sm">Title</div>
        <div
          v-show="isEditingHeading || headingCharLimit < 0 || headingCharLimit === 50"
          class="flex items-center justify-center h-6 text-xs font-medium rounded-1 w-7 font-ui"
          :class="headingCharLimit < 0 ? 'bg-red-100 text-red' : 'bg-primary-200 text-primary-700'"
        >
          {{headingCharLimit}}
        </div>
      </div>

      <div class="">
        <input
          v-if="isEditingHeading"
          ref="heading"
          type="text"
          placeholder="Name your audiogram"
          class="w-full px-4 py-3 border rounded-lg outline-none border-primary text-sm text-primary placeholder-primary-300"
          v-model="heading"
          v-focus
          @blur="isEditingHeading = false;"
          @keyup.enter="isEditingHeading=false;"
        />
        <div
          v-else
          @click="startEditing"
          class="py-3 pl-4 truncate border border-transparent rounded-lg cursor-text border-primary-400 text-sm"
          :class="[heading ? 'text-primary' : 'text-primary-300']"
        >
          {{heading || 'Name your heading'}}
        </div>
      </div>
    </div>

    <div class="col-span-1">
      <div class="flex items-center mb-1 font-medium text-sm font-ui text-primary">
        Logo
        <VueSwitch class="ml-2" v-model="enableLogo" />
      </div>
      <UploadFile
        key="logo"
        name="logo"
        title="Upload logo"
        accept=".jpeg,.jpg,.png"
        type="image"
        :placeholderImage="defaultLogo || '/images/projects/coverart-preview.png'"
        :value="logoValue"
        :showReplace="true"
        @onChange="onLogoChange"
      />
    </div>

    <div v-show="!hideCoverArt" class="col-span-1">
      <div class="mb-1 font-medium text-sm font-ui text-primary">Cover Art</div>
      <UploadFile
        key="coverArt"
        name="coverArt"
        title="Upload cover art"
        accept=".jpeg,.jpg,.png"
        type="image"
        placeholderImage="/images/default/cover.png"
        :value="coverArtValue"
        :showReplace="true"
        @onChange="onCoverArtChange"
      />
    </div>

    <div class="col-span-1 mb-1">
      <div class="mb-1 font-medium text-sm font-ui text-primary">Background</div>
      <div class="p-2 border rounded-lg border-primary-400">
        <div
          class="flex items-center cursor-pointer"
          @click="onBackgroundTypeChange('image')"
        >
          <UploadFile
            class="flex-grow"
            barebones
            key="bg"
            name="bg"
            title="Upload Image"
            accept=".jpeg,.jpg,.png"
            type="image"
            placeholderImage="/images/projects/audiogram-bg-preview.png"
            :value="backgroundImageValue"
            @onChange="onBackgroundImageChange"
          />

          <div
            class="radio rounded-full ml-2  flex items-center justify-center"
            :class="backgroundType === 'image' ? 'bg-primary' : 'border border-primary-400'"
          >
            <div v-if="backgroundType === 'image'" class="bg-white h-1.5 w-1.5 rounded-full"/>
          </div>
        </div>

        <div class="h-0.25 w-full bg-primary-400 my-2"></div>

        <div
          class="flex items-center cursor-pointer"
          @click="onBackgroundTypeChange('color')"
        >
          <div class="relative flex items-center flex-grow">
            <input
              class="absolute top-0 bottom-0 left-0 right-0 w-full h-full opacity-0 cursor-pointer"
              type="color"
              name="color"
              v-model="backgroundColor"
            >
            <div
              class="w-8 h-8 border rounded-lg cursor-pointer border-primary-300 mr-2"
              :style="{'background-color': backgroundColor}"
            />

            <div class="text-xs text-primary">COLOR: {{backgroundColor}}</div>
          </div>

          <div
            class="radio rounded-full flex items-center justify-center"
            :class="backgroundType === 'color' ? 'bg-primary' : 'border border-primary-400'"
          >
            <div v-if="backgroundType === 'color'" class="bg-white h-1.5 w-1.5 rounded-full"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-medium font-ui text-primary text-sm">
        Wave color
      </div>

      <div class="relative flex items-center justify-between p-2 border rounded-lg border-primary-400 cursor-pointer">
        <input
          class="absolute top-0 bottom-0 left-0 right-0 w-full h-full opacity-0 cursor-pointer"
          type="color"
          name="color"
          v-model="accentColor"
          ref="accentColor"
        >
        <div class="flex items-center">
          <div
            class="w-8 h-8 mr-2 rounded cursor-pointer border border-primary-300"
            :style="{'background-color': accentColor}"
          />
          <div class="text-sm text-primary">{{accentColor}}</div>
        </div>

        <div
          v-tooltip.top="'Replace'"
          class="relative z-10 text-primary-500 hover:text-green cursor-pointer"
          @click="$refs.accentColor.click()"
        >
          <ReplaceIcon class="w-5 h-5" />
        </div>
      </div>
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-medium font-ui text-primary text-sm">
        Title Font Settings
      </div>
      <MinimalSubtitleFormatting
        :key="`titleStyle-${projectId}`"
        :value="titleStyle"
        @onChange="onTitleStyleChange"
      />
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-medium font-ui text-primary text-sm">
        Subtitle Font Settings
      </div>
      <MinimalSubtitleFormatting
        :key="`subtitleStyle-${projectId}`"
        :disabled="!enableSubtitleFormatting"
        :value="subtitleStyle"
        @onChange="onSubtitleStyleChange"
      />
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
import { updateProject } from '@/services/api/project'
import ReplaceIcon from '@/components/base/icons/Replace.vue'
import VueSwitch from '@/components/base/inputs/Switch'
import UploadFile from '@/view/BrandKit/components/UploadFile.vue'

import videoPlayer from '@/components/VideoPlayer/api'

import MinimalSubtitleFormatting from './MinimalSubtitleFormatting.vue'
import templateData from '../Templates/data'

export default {
  name: 'BrandKit',
  components: {
    UploadFile,
    MinimalSubtitleFormatting,
    ReplaceIcon,
    VueSwitch
  },
  props: {
    projectInfo: {
      type: Object,
      default: () => ({})
    },
    userId: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    template: {
      type: String,
      default: 'four'
    }
  },
  data: function() {
    return {
      version: 'v1',
      tab: 'audio',
      isEditingHeading: false,
      coverArtUrl: '',
      backgroundImageUrl: '',
      videoPlayerInfoUpdates: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    templateDetails: function() {
      return templateData.find(t => t.name === this.template)
    },
    hideCoverArt: function() {
      return this.templateDetails && this.templateDetails.hideCover
    },
    defaultLogo: function() {
      return this.templateDetails && this.templateDetails.logo
    },
    heading: {
      get() {
        return this.projectInfo ? this.projectInfo.heading || '' : ''
      },
      set: debounce(function(val) {
        this.save({ heading: val }, false)
        videoPlayer.updateHeading({ text: val })
      }, 400)
    },
    headingCharLimit: function() {
      return 50 - this.heading.length
    },
    backgroundImageValue: function() {
      return this.projectInfo ? this.projectInfo.backgroundImage || {} : {}
    },
    coverArtValue: function() {
      return this.projectInfo ? this.projectInfo.coverArt || {} : {}
    },
    logoValue: function() {
      return this.projectInfo ? this.projectInfo.logo || {} : {}
    },
    enableSubtitleFormatting: {
      get() {
        return this.projectInfo ? this.projectInfo.enableSubtitleFormatting || false : false
      },
      set(val) {
        this.save({ enableSubtitleFormatting: val }, false)
      }
    },
    subtitleStyle: function() {
      return this.projectInfo ? this.projectInfo.subtitleStyle || {} : {}
    },
    titleStyle: function() {
      return this.projectInfo ? this.projectInfo.titleStyle || {} : {}
    },
    accentColor: {
      get: function() {
        return this.projectInfo ? this.projectInfo.accentColor || '#000000' : '#000000'
      },
      set: function(val) {
        this.save({ accentColor: val }, false)
      }
    },
    backgroundType: {
      get: function() {
        return this.projectInfo ? this.projectInfo.backgroundType || '#000000' : '#000000'
      },
      set: function(val) {
        this.save({ backgroundType: val }, false)
      }
    },
    backgroundColor: {
      get: function() {
        return this.projectInfo ? this.projectInfo.backgroundColor || '#000000' : '#000000'
      },
      set: function(val) {
        this.save({ backgroundColor: val }, false)
      }
    },
    videoPlayerInfo () {
      const {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      } = { ...this.projectInfo }
      const info = {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      }
      return info
    },
    enableLogo: {
      get() {
        return this.projectInfo ? this.projectInfo.enableLogo || false : false
      },
      set(val) {
        this.save({ enableLogo: val }, false)
      }
    }
  },
  methods: {
    startEditing: function() {
      this.isEditingHeading = true
      setTimeout(() => {
        this.$refs.heading.focus()
      }, 100)
    },
    saveToBrandkit: function(update) {
      firebase.database().ref(`/${this.user.uid}/brandkit/${this.tab}/${this.version}`)
        .update(update)
    },
    save: async function(update, saveToBrandkit = false) {
      this.videoPlayerInfoUpdates = {
        ...this.videoPlayerInfoUpdates,
        ...update
      }
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update
        }
      )
      if (saveToBrandkit) this.saveToBrandkit(update)
    },
    onBackgroundImageChange: function(backgroundImage) {
      this.save({ backgroundImage }, false)
    },
    onBackgroundTypeChange: function(backgroundType) {
      this.save({ backgroundType, backgroundColor: this.backgroundColor }, false)
    },
    onCoverArtChange: async function(coverArt) {
      this.save({ coverArt }, true)
    },
    onLogoChange: async function(logo) {
      this.save({ logo }, true)
    },
    onSubtitleStyleChange: function(update) {
      this.save({
        subtitleStyle: {
          ...this.projectInfo.subtitleStyle,
          ...update
        }
      }, false)
    },
    onTitleStyleChange: function(update) {
      this.save({
        titleStyle: {
          ...this.projectInfo.titleStyle,
          ...update
        }
      }, false)
    },
    onVideoPlayerInfoUpdate: debounce(function(update) {
      this.$emit('onChange', {
        ...this.videoPlayerInfo,
        ...update
      })
    }, 200)
  },
  watch: {
    videoPlayerInfoUpdates: function(val) {
      this.onVideoPlayerInfoUpdate(val)
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.radio {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
}
</style>
