<template>
  <div class="h-full w-full relative transition ease-in-out duration-300">
    <TextBlock
      v-for="text in texts"
      :id="text.id"
      :key="text.id"
      :element="text"
    />

    <ImageBlock
      v-for="image in images"
      v-show="!image.hidden"
      :id="image.id"
      :key="image.id"
      :element="image"
    />

    <CSSBlock
      v-for="node in nodes"
      v-show="!node.hidden"
      :id="node.id"
      :key="node.id"
      :element="node"
    />

    <AudioWave
      v-for="wave in waves"
      v-show="!wave.hidden"
      :id="wave.id"
      :key="wave.id"
      :element="wave"
      :isPlaying="isPlaying"
      :scale="scale"
    />
  </div>
</template>

<script>
import TextBlock from './TextBlock.vue'
import ImageBlock from './ImageBlock.vue'
import CSSBlock from './CSSBlock.vue'
import AudioWave from './AudioWave.vue'

export default {
  name: 'ClipPreview',
  components: {
    TextBlock,
    ImageBlock,
    CSSBlock,
    AudioWave
  },
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    isPlaying: Boolean,
    totalTime: Number,
    currentTime: Number,
    scale: {
      type: Number,
      default: 1
    },
    elements: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    return {
      schedule: null
    }
  },
  computed: {
    texts: function() {
      return this.elements.filter(t => t.type === 'text')
    },
    images: function() {
      return this.elements.filter(t => t.type === 'image')
    },
    nodes: function() {
      return this.elements.filter(t => t.type === 'node')
    },
    waves: function() {
      return this.elements.filter(t => t.type === 'wave')
    }
  }
}
</script>

<style scoped lang="css">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

</style>
