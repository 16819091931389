<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    :class="classname"
  >
    <path d="M15.2619 10.8917C16.5321 10.0143 17.4226 8.57381 17.4226 7.2381C17.4226 4.27857 15.131 2 12.1845 2H4V20.3333H13.219C15.956 20.3333 18.0774 18.1071 18.0774 15.3702C18.0774 13.3798 16.9512 11.6774 15.2619 10.8917V10.8917ZM7.92857 5.27381H11.8571C12.944 5.27381 13.8214 6.15119 13.8214 7.2381C13.8214 8.325 12.944 9.20238 11.8571 9.20238H7.92857V5.27381ZM12.5119 17.0595H7.92857V13.131H12.5119C13.5988 13.131 14.4762 14.0083 14.4762 15.0952C14.4762 16.1821 13.5988 17.0595 12.5119 17.0595Z" />
  </svg>
</template>

<script>
export default {
  name: 'Italic',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
