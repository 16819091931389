<template>
  <div
    class="
      border
      rounded
      border-primary-300
      w-full
      col-span-1
      relative
      overflow-hidden
    "
    :class="{
      'hover:border-primary': !exportErrored
    }"
  >
    <div
      class="
        bg-primary
        rounded-tl rounded-tr
        flex
        thumb
        items-center
        justify-center
        relative
      "
    >
      <div
        v-if="!exportErrored && working"
        class="
          z-30
          flex
          top-0
          right-0
          bottom-0
          left-0
          absolute
          loadingOverlay
          items-center
          justify-center
        "
      >
        <CircularLoader class="h-10 text-red w-10" />
      </div>
      <div
        v-else-if="!exportErrored && (working || inProgress)"
        class="
          z-30
          flex
          top-0
          right-0
          bottom-0
          left-0
          absolute
          loadingOverlay
          items-center
          justify-center
        "
      >
        <div
          class="
            rounded-full
            flex
            h-9
            w-9
            justify-center
            items-center
            relative
            progress
            bg-primary bg-opacity-50
          "
        >
          <CircularLoader class="h-10 text-red w-10 absolute" />
          <div class="font-h font-semibold text-white text-xs">
            {{ averageProgress }}%
          </div>
        </div>
      </div>
      <div
        v-else-if="!exportErrored && loading"
        class="
          z-30
          flex
          top-0
          right-0
          bottom-0
          left-0
          absolute
          loadingOverlay
          items-center
          justify-center
        "
      >
        <CircularLoader class="h-10 text-red w-10" />
      </div>
      <div
        v-else-if="!exportErrored"
        @click="onTogglePlay"
        class="
          relative
          border-white
          rounded-full
          cursor-pointer
          flex
          border-4
          h-8
          text-white
          w-8
          z-30
          items-center
          justify-center
          hover:border-red hover:text-red
        "
      >
        <PauseIcon v-if="isPlaying" class="h-3 w-3" />
        <PlayIcon v-else class="h-3 left-0.25 w-3 relative" />
      </div>

      <div
        class="
          absolute
          top-0
          bottom-0
          right-0
          left-0
          z-20
          bg-primary bg-opacity-20
          backdrop-filter backdrop-blur-sm
        "
      />

      <div v-if="previewUrl" class="absolute top-0 bottom-0 right-0 left-0">
        <Thumbnail class="object-cover" :source="previewUrl" :percentage="50" />
      </div>
      <div v-else class="absolute top-0 bottom-0 right-0 left-0">
        <Thumbnail class="object-cover" :percentage="50" />
      </div>
    </div>

    <div class="flex justify-between px-3 details w-full relative">
      <div class="flex items-center custom-max-width">
        <div
          v-if="selectable && !exportErrored && clipType === 'export'"
          :class="{
            'text-primary-500': !selected,
            'text-green': selected,
            'cursor-pointer hover:text-green': !inProgress,
          }"
          @click="onSelect()"
        >
          <Checkbox v-if="selected" />
          <CheckboxEmpty v-else />
        </div>
        <div class="w-full ml-1.5 flex"
          :class="`${exportErrored ? 'flex-col' : 'flex-col-reverse justify-end' }`"
        >
          <div v-if="exportErrored" class="text-2xs text-red">Export failed</div>
          <div v-else-if="clip.createdAt" class="font-ui text-sm text-primary font-normal">{{getFromNow}}</div>
          <div :title="clip.title" class="text-primary text-sm font-bold overflow-hidden whitespace-nowrap overflow-ellipsis max-w-full">
            {{ clip.title }}
          </div>
        </div>
      </div>

      <div class="flex justify-end items-center">
        <div
          v-if="
            exportDetails &&
            exportDetails.mp4 &&
            exportDetails.mp4.publish &&
            !exportErrored
          "
          class="text-primary-900 hover:text-blue cursor-pointer ml-2"
          v-tooltip.top="'Share'"
          @click="onShareClicked"
        >
          <ShareIcon />
        </div>
        <div
          v-if="
            exportDetails &&
            exportDetails.mp4 &&
            exportDetails.mp4.url &&
            !exportErrored
          "
          class="text-primary-900 hover:text-primary-400 cursor-pointer ml-4"
          v-tooltip.top="'Download MP4'"
          @click="onDownloadClicked"
        >
          <DownloadIcon />
        </div>
        <div
          v-if="exportErrored"
          class="
            text-primary-500
            hover:text-primary
            cursor-pointer
            ml-2
            flex
            justify-center
            items-center
            errorIconContainer
          "
          @click="showIntercomMessage()"
        >
          <WarningIcon classname="warningIcon" />
          <QuestionMark  classname="questionIcon" v-tooltip.top="'Contact support'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/auth'
import 'firebase/database'
import * as firebase from 'firebase/app'
import CONSTANTS from '@/constants/CONSTANTS'
import dayjs from 'dayjs'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import sum from 'lodash/sum'
import { getPodcastExports } from '@/services/api/podcast'
import Checkbox from '@/components/base/icons/Checkbox.vue'
import CheckboxEmpty from '@/components/base/icons/CheckboxEmpty.vue'
import DownloadIcon from '@/components/base/icons/DownloadFile.vue'
import ShareIcon from '@/components/base/icons/Share.vue'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import Thumbnail from '@/components/VideoPlayer/Thumbnail.vue'
import WarningIcon from '@/components/base/icons/WarningCircular.vue'
import QuestionMark from '@/components/base/icons/QuestionMarkFilled.vue'
import relativeTime from 'dayjs/plugin/relativeTime'
import { secondsToHHMMSS } from '../utils'
dayjs.extend(relativeTime)

export default {
  props: {
    clip: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    clipType: {
      type: String,
      default: 'export'
    },
    loading: {
      type: Boolean,
      default: false
    },
    working: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PlayIcon,
    PauseIcon,
    Checkbox,
    CheckboxEmpty,
    CircularLoader,
    Thumbnail,
    DownloadIcon,
    ShareIcon,
    WarningIcon,
    QuestionMark
  },
  data: function () {
    return {
      exportDetails: {},
      counter: null,
      count: 0,
      previewUrl: ''
    }
  },
  computed: {
    exportErrored: function () {
      return this.exportDetails && this.exportDetails.mp4 && this.exportDetails.mp4.status === 'error'
    },
    clipDuration: function () {
      return this.clip.end - this.clip.start
    },
    avaialbleExportTypes: function () {
      return Object.keys(this.exportDetails || {})
    },
    getFromNow() {
      return `${dayjs(this.clip.createdAt).fromNow()} | ${secondsToHHMMSS(this.clip.duration || this.clipDuration)}`
    },
    exportProgresses: function () {
      const progress = {}
      if (this.working) return progress
      let exportTypes = this.avaialbleExportTypes
      exportTypes = ['mp4'] // TODO: comment out to enable mp3 exports

      exportTypes.forEach((el) => {
        const { [el]: clipExport } = { ...this.exportDetails }
        const { url, timestamp } = { ...clipExport }

        if (url) progress[el] = 100
        else {
          const max = this.clipDuration * 20
          const timePassed = dayjs().diff(dayjs(timestamp)) / 1000 + this.count
          if (timePassed >= max) progress[el] = 99
          else progress[el] = Math.floor((100 * timePassed) / max)
        }
      })

      return progress
    },
    exportsCompleted: function () {
      const progresses = Object.values(this.exportProgresses)
      return progresses.length && progresses.every((el) => el === 100)
    },
    inProgress: function () {
      if (this.clipType !== 'export') return false
      else return !this.exportsCompleted
    },
    averageProgress: function () {
      const progresses = Object.values(this.exportProgresses)
      return sum(progresses) / progresses.length
    },
    path: function () {
      const { mp4 } = { ...this.exportDetails }
      const { url } = { ...mp4 }
      return url || ''
    }
  },
  methods: {
    showIntercomMessage: function() {
      window.Intercom('showNewMessage', 'Clip export failed')
    },
    limitString: function (str, limit = 20) {
      if (str.length > limit) return `${str.slice(0, limit)}...`
      else return str
    },
    onSelect: function (forceSelect = false) {
      if (!this.inProgress && !this.working) {
        this.$emit(
          'onSelect',
          {
            ...this.clip,
            exports: this.exportDetails
          },
          forceSelect
        )
      }
    },
    onSelectForDownloadAll: function (forceSelect = false) {
      if (!this.inProgress && !this.working) {
        this.$emit(
          'onSelectForDownloadAll',
          {
            ...this.clip,
            exports: this.exportDetails
          },
          forceSelect
        )
      }
    },
    onTogglePlay: function () {
      const { url } = { ...this.exportDetails.mp4 }
      if (url && !this.working) {
        this.$emit('onTogglePlay', {
          ...this.clip,
          url,
          playing: true
        })
      }
    },
    getUrl: async function (path) {
      this.previewUrl = await firebase.storage().ref(path).getDownloadURL()
      this.onSelectForDownloadAll(true)
    },
    onDownloadClicked: async function () {
      if (this.exportDetails.mp4 && this.exportDetails.mp4.pub_url) {
        return window.open(this.exportDetails.mp4.pub_url, '_blank').focus()
      }
      if (this.exportDetails.mp4 && this.exportDetails.mp4.url) {
        const time = dayjs().format('D MMM, h:mm a')
        const zipFilename = `${this.clip.title || 'clip'} - ${time}.zip`
        this.downloadName = zipFilename
        this.downloads = 'Preparing files'

        let filesToDownload = [
          {
            type: 'mp4',
            name: this.clip.title,
            url: this.exportDetails.mp4.url
          }
        ]

        this.downloads = `Downloading ${filesToDownload.length} files`
        // make urls
        filesToDownload = await Promise.all(
          filesToDownload.map(
            ({ type, name, url }) =>
              new Promise(async (resolve, reject) => {
                const fileUrl = await firebase
                  .storage()
                  .ref(url)
                  .getDownloadURL()
                resolve({
                  type,
                  name,
                  url: fileUrl
                })
              })
          )
        )
        const urls = filesToDownload.map((t) => t.url)
        this.downloads = `Zipping 0 of ${filesToDownload.length} files`
        const vm = this
        const zip = new JSZip()
        let count = 0

        urls.forEach((url, index) => {
          const { name, type } = filesToDownload[index]
          const filename = `${name}.${type}`
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err // or handle the error
            }
            zip.file(filename, data, { binary: true })
            count++
            vm.downloads = `Zipping ${count} of ${filesToDownload.length} files`
            if (count === urls.length) {
              zip.generateAsync({ type: 'blob' }).then((content) => {
                vm.downloads = ''
                saveAs(content, zipFilename)
              })
            }
          })
        })
      }
    },
    onShareClicked: async function () {
      if (this.exportDetails.mp4 && this.exportDetails.mp4.publish) {
        this.$emit(
          'onShare',
          `${CONSTANTS.PUBLISH_URL}/video/${this.exportDetails.mp4.publish}`
        )
      }
    }
  },
  created: function () {
    if (this.clipType === 'export') {
      const { owner, uid, version } = this.clip
      // setup projectInfo listner
      getPodcastExports(owner, uid, version).on('value', (snap) => {
        this.exportDetails = snap.val()
      })
    }
    this.counter = setInterval(() => {
      this.count += 0.01
    }, 2000)
  },
  beforeDestroy: function () {
    if (this.counter) clearInterval(this.counter)
  },
  mounted: function () {
    if (this.path) this.getUrl(this.path)
  },
  watch: {
    path: function (val) {
      if (val) {
        this.getUrl(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.clipPreview {
  width: 100%;
}

.thumb {
  height: 80px;
  overflow: hidden;
}

.details {
  height: 64px;
}

.loadingOverlay {
  background: rgba(229, 231, 235, 0.7);
  backdrop-filter: blur(7px);
}

.progress {
  background: rgba(0, 0, 0, 0.48);
}

.errorIconContainer {
  >* {
    width: 20px;
  }

  .questionIcon {
    display: none;
  }
  &:hover {
    .warningIcon {
      display: none;
    }
    .questionIcon {
      display: block;
    }
  }
}
.custom-max-width {
  max-width: calc(100% - 120px);
}
</style>
