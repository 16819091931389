<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" :class="classname">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6665 4.5C8.24823 4.5 4.6665 8.08172 4.6665 12.5C4.6665 16.9183 8.24823 20.5 12.6665 20.5C17.0848 20.5 20.6665 16.9183 20.6665 12.5C20.6665 8.08172 17.0848 4.5 12.6665 4.5ZM2.6665 12.5C2.6665 6.97715 7.14366 2.5 12.6665 2.5C18.1894 2.5 22.6665 6.97715 22.6665 12.5C22.6665 18.0228 18.1894 22.5 12.6665 22.5C7.14366 22.5 2.6665 18.0228 2.6665 12.5Z" fill="#E2462C"/>
    <path d="M13.6665 8.5C13.6665 7.94772 13.2188 7.5 12.6665 7.5C12.1142 7.5 11.6665 7.94772 11.6665 8.5V12.5C11.6665 13.0523 12.1142 13.5 12.6665 13.5C13.2188 13.5 13.6665 13.0523 13.6665 12.5V8.5Z" fill="#E2462C"/>
    <path d="M12.6665 15.5C12.1142 15.5 11.6665 15.9477 11.6665 16.5C11.6665 17.0523 12.1142 17.5 12.6665 17.5C13.2188 17.5 13.6665 17.0523 13.6665 16.5C13.6665 15.9477 13.2188 15.5 12.6665 15.5Z" fill="#E2462C"/>
  </svg>
</template>
<script>
export default {
  name: 'WarningCircular',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
