<template>
  <div
    class="flex items-center group"
    :class="{ 'px-2 border rounded-lg border-primary-400 uploadFile': !barebones, 'barebonesUploadFile': barebones }"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      :id="name"
      type="file"
      ref="file"
      :name="name"
      class="absolute w-px h-px overflow-hidden opacity-0"
      :accept="accept"
      @change="onChange"
    />

    <div class="flex items-center flex-grow">
      <div v-if="file" class="flex items-center w-8/12 text-sm truncate text-primary">
        <CircularLoader v-if="inProgress" class="mr-2 text-green"/>
        <div v-else class="mr-2 text-primary">
          <FileIcon v-if="type==='video'" />
          <ImageIcon v-if="type==='image' && !imageUrl && !placeholderImage" />
          <img v-if="type==='image' && !imageUrl && placeholderImage" :src="placeholderImage" class="object-contain w-8 h-8 rounded-lg bg-primary-300 border-primary-300"/>
          <img v-if="type==='image' && imageUrl" :src="imageUrl" class="object-contain w-8 h-8 rounded-md" />
        </div>
        {{limitString(file.name, 16)}}
      </div>
      <div v-else-if="value && value.name" class="flex items-center text-sm text-primary">
        <div class="mr-2 text-primary">
          <FileIcon v-if="type==='video'" />
          <ImageIcon v-if="type==='image' && !imageUrl && !placeholderImage" />
          <img v-if="type==='image' && !imageUrl && placeholderImage" :src="placeholderImage" class="object-contain w-8 h-8 rounded-lg bg-primary-300 border-primary-300"/>
          <img v-if="type==='image' && imageUrl" :src="imageUrl" class="object-contain w-8 h-8 rounded-md" />
        </div>
        {{limitString(value.name, 16)}}
      </div>

      <div v-if="showActionIcons" class="flex-grow"/>

      <label :for="name" class="block cursor-pointer" :class="showUpload ? 'w-full' : ''">
        <div
          v-if="showActionIcons"
          v-tooltip.top="'Replace'"
          class="mr-2.5 text-primary-500 hover:text-green"
        >
          <ReplaceIcon class="w-5 h-5" />
        </div>
        <div v-if="showUpload" class="flex items-center text-sm text-primary-500">
          <div>
            <img v-if="type==='image' && placeholderImage" :src="placeholderImage" class="object-contain w-8 h-8 mr-2 border rounded-lg bg-primary-300 border-primary-300"/>
            <FileUploadIcon v-else class="mr-2 text-primary"/>
          </div>
          <div class="flex items-center justify-between flex-grow">
            <span v-if="title" class="text-xs uppercase text-primary group-hover:text-red">
              {{title}}
            </span>
            <span v-else class="flex items-center justify-start">Drag & drop or <span class="mx-1 text-blue">browse</span> to upload</span>
            <ReplaceIcon v-tooltip.top="'Replace'" v-if="(showReplace || barebones) && title" class="w-5 h-5 hover:text-green" />
            <ChevronRight v-else-if="title" class="text-primary-500"/>
          </div>
        </div>
      </label>

      <div
        v-if="showActionIcons"
        v-tooltip.top="'Delete'"
        class="cursor-pointer mr-2.5 text-primary-500 hover:text-red"
        @click="deleteFile"
      >
        <DeleteIcon class="w-6 h-6" />
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { mapGetters } from 'vuex'
import uuidv1 from 'uuid/v1'
import FileUploadIcon from '@/components/base/icons/FileUpload.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import ReplaceIcon from '@/components/base/icons/Replace.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
// import VideoIcon from '@/components/base/icons/Play.vue'
import ImageIcon from '@/components/base/icons/Image.vue'
import FileIcon from '@/components/base/icons/File.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'

export default {
  components: {
    FileUploadIcon,
    CircularLoader,
    ReplaceIcon,
    DeleteIcon,
    FileIcon,
    ImageIcon,
    ChevronRight
  },
  props: {
    name: {
      type: String,
      default: 'assetsFieldHandle'
    },
    title: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.mp4,.jpg,.jpeg,.png'
    },
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'image'
    },
    placeholderImage: {
      type: String,
      default: ''
    },
    barebones: {
      type: Boolean,
      default: false
    },
    showReplace: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      file: null,
      inProgress: false,
      progress: 0,
      imageUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    showActionIcons: function() {
      return !this.inProgress && (this.file || (this.value && this.value.url))
    },
    showUpload: function() {
      return !(this.file || (this.value && this.value.url))
    }
  },
  methods: {
    limitString: function(str, limit = 30) {
      if (str.length > limit) return `${str.slice(0, limit)}...`
      else return str
    },
    uploadResourceToDB: function(file, resourceId) {
      const storageRef = firebase.storage().ref('podcast_audio/' + this.user.uid + '/uploaded_media/' + resourceId)
      const metadata = { 'contentType': file.type, cacheControl: 'public,max-age=300' }
      return storageRef.put(file, metadata)
    },
    onChange: function() {
      this.file = this.$refs.file.files[0]
      this.$refs.file.value = ''
      this.uploadFile()
    },
    dragover: function(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-primary-200')) {
        event.currentTarget.classList.add('bg-primary-200')
      }
      if (!event.currentTarget.classList.contains('border-dashed')) {
        event.currentTarget.classList.add('border-dashed')
      }
    },
    dragleave: function(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-primary-200')
      event.currentTarget.classList.remove('border-dashed')
    },
    drop: function(event) {
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      if (file && file.type && file.type.startsWith(this.type)) {
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()
      }

      // Clean up
      event.currentTarget.classList.remove('bg-primary-200')
      event.currentTarget.classList.remove('border-dashed')
    },
    deleteFile: function() {
      this.file = null
      this.imageUrl = ''
      this.$emit('onChange', null)
    },
    uploadFile: function() {
      this.inProgress = true

      const resourceId = (this.type === 'image' ? 'i-' : 'm-') + uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      const uploadTask = this.uploadResourceToDB(this.file, resourceId)

      const vm = this
      uploadTask.on('state_changed', function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        vm.progress = progress
      }, function (err) {
        console.log(err)
      }, async function () {
        let duration
        if (vm.type === 'video') duration = await vm.getDuration(vm.file)
        const url = uploadTask.snapshot.ref.fullPath
        vm.imageUrl = await firebase.storage().ref(url).getDownloadURL()
        vm.$emit('onChange', {
          url,
          key: resourceId,
          name: vm.file.name,
          type: vm.type,
          ...vm.type === 'video' ? { duration } : {}
        })
        vm.inProgress = false
      })
    },
    getDuration: async function(file) {
      return new Promise((resolve, reject) => {
        let au = document.createElement('video')
        au.src = URL.createObjectURL(file)

        // Once the metadata has been loaded, display the duration in the console
        au.addEventListener('loadedmetadata', function() {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          resolve(au.duration)
        }, false)
      })
    }
  },
  mounted: async function() {
    if (this.type === 'image' && this.value && this.value.url) {
      this.imageUrl = await firebase.storage().ref(this.value.url).getDownloadURL()
    }
  },
  watch: {
    value: async function(val) {
      if (this.type === 'image' && val && val.url) {
        this.imageUrl = await firebase.storage().ref(val.url).getDownloadURL()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.uploadFile {
  max-height: 50px;
  min-height: 50px;
}

.barebonesUploadFile {
  max-height: 32px;
  min-height: 32px;
}

img {
  min-width: 32px;
  min-height: 32px;
}
</style>
