<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    :class="classname"
  >
    <path d="M14.5105 19.1641L14.6273 19.5H14.9828H18.1714H18.8838L18.6416 18.83L12.1359 0.830046L12.0166 0.5H11.6657H8.11712H7.76665L7.64711 0.829452L1.11568 18.8295L0.872366 19.5H1.58569H4.79998H5.15555L5.27227 19.1641L6.41555 15.8743H13.3673L14.5105 19.1641ZM7.7427 12.0971L9.89141 5.98012L12.0401 12.0971H7.7427Z" stroke="#949BA8"/>
  </svg>
</template>

<script>
export default {
  name: 'Aoutline',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
