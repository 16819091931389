<template>
  <div
    class="flex items-end mb-2.5"
  >
    <label class="w-s96">
      <!-- <p class="hidden text-sm font-medium text-gray-700 mb-s4">Start</p> -->
      <CustomInput
        containerClass=""
        titleClass="text-sm font-medium mb-1"
        :inputClass=" !hideError && error ? 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-red' : 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-gray-300'"
        placeholder="hh:mm:ss"
        title="Start"
        v-model="startTimeModel"
        vmask="##:##:##"
        :error="!hideError && error"
        :autofocus="firstInputFocus"
        :showGap="shouldShowGap()"
      />
    </label>
    <div class="flex items-center h-s40">
      <div class="h-s2 w-s8 bg-primary-500 mx-s4" />
    </div>
    <label class="w-s96 mr-s16">
      <!-- <p class="hidden text-sm font-medium text-gray-700 mb-s4">End</p> -->
      <CustomInput
        containerClass=""
        titleClass="text-sm font-medium mb-1"
        :inputClass=" !hideError && error ? 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-red' : 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-gray-300'"
        placeholder="hh:mm:ss"
        v-model="endTimeModel"
        title="End"
        vmask="##:##:##"
        :error="!hideError && error"
        :autofocus="secondInputFocus"
        :index="index"
      />
    </label>
    <label class="w-s352">
      <!-- <p class="hidden text-sm font-medium text-gray-700 mb-s4">
        Clip name
      </p> -->
      <CustomInput
        containerClass=""
        titleClass="text-sm font-medium mb-1"
        :inputClass=" !hideError && error ? 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-red' : 'px-s12 h-s40 w-full rounded-1.5 border focus:border-primary text-sm text-gray-900 border-gray-300'"
        placeholder="Title of clip"
        v-model="title"
        title="Title"
        :error="!hideError && error"
        :autofocus="thirdInputFocus"
        :index="index"
        @inputBlur="handleTextInputBlur"
      />
    </label>
    <div v-if="timestamps" class="flex items-center h-s40">
      <button v-if="!hideError && error" class="h-s24 w-s24 ml-s8" v-tooltip.top="error">
        <exclamation-circle />
      </button>
      <button class="h-s24 w-s24 ml-s8" :class="`${index < timestamps.length-1 ? 'opacity-10 hover:opacity-100':''}`" @click="$emit('removeRow', index)">
        <minus-circle />
      </button>
      <button v-if="!(!hideError && error)" :class="`${index < timestamps.length-1 ? 'opacity-10 hover:opacity-100':''}`" class="h-s24 w-s24 ml-s8" @click="$emit('addRow', index + 1)">
        <add-circle />
      </button>
    </div>
  </div>
</template>

<script>

import AddCircle from '@/components/base/icons/AddCircle.vue'
import MinusCircle from '@/components/base/icons/MinusCircle.vue'
import ExclamationCircle from '@/components/base/icons/ExclamationCircle.vue'
import CustomInput from '@/components/base/inputs/Input.vue'
import { splitLineIntoTimestamps, hhmmssToSeconds, secondsToHHMMSS } from '../utils'
import { AUTO_CLIPPER_LIMIT } from '@/constants/CONSTANTS'

export default {
  name: 'TimeRow',
  props: {
    line: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    hideError: {
      type: Boolean,
      default: false
    },
    totalTime: {
      type: Number,
      default: 99999
    },
    timestamps: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: {
    AddCircle,
    MinusCircle,
    ExclamationCircle,
    CustomInput
  },
  data: function() {
    return {
      initialised: false,
      startTime: '',
      endTime: '',
      title: '',
      error: '',
      firstInputFocus: true,
      secondInputFocus: false,
      thirdInputFocus: false
    }
  },
  computed: {
    currentTimestampIndex: function() {
      return this.timestamps.findIndex(el => el.index === this.index)
    },
    currentTimestamp: function() {
      return this.timestamps.find(el => el.index === this.index)
    },
    nextTimestamp: function() {
      return (this.currentTimestampIndex !== -1) ? (timestamps[this.currentTimestampIndex + 1] || null) : null
    },
    startTimeModel: {
      get: function() {
        return this.startTime
      },
      set: function(val) {
        if (val.length >= 8) {
          this.secondInputFocus = true
          this.firstInputFocus = false
          this.thirdInputFocus = false
        }
        this.startTime = val
      }
    },
    endTimeModel: {
      get: function() {
        return this.endTime
      },
      set: function(val) {
        if (val.length >= 8) {
          this.secondInputFocus = false
          this.firstInputFocus = false
          this.thirdInputFocus = true
        }
        this.endTime = val
      }
    }
  },
  methods: {
    onChange: function() {
      const line = this.startTime + (this.endTime ? ' - ' : '') + this.endTime + ' ' + this.title
      const result = splitLineIntoTimestamps(line)

      if (!result) this.error = 'Invalid timestamp'
      else {
        const [startTime, endTime, title] = result
        if (!startTime || !title.trim()) this.error = 'Incorrect timestamp'
        else {
          let startTimeInSeconds = hhmmssToSeconds(startTime)
          let endTimeInSeconds
          if (endTime) endTimeInSeconds = hhmmssToSeconds(endTime)
          this.error = ''
          if ((endTimeInSeconds - startTimeInSeconds) > AUTO_CLIPPER_LIMIT * 60) {
            this.error = `Clips must be shorter than ${AUTO_CLIPPER_LIMIT} minutes`
          }
          if (endTimeInSeconds <= startTimeInSeconds) {
            this.error = "Start time can't be greater than end time"
          }
          if (endTimeInSeconds > this.totalTime) {
            this.error = "End time can't be greater video length"
          }
        }
      }
      if (this.initialised) this.$emit('onChange', { index: this.index, line })
    },
    setEndTime: function() {
      const { end } = { ...this.currentTimestamp }
      if (end && !this.endTime) this.endTime = secondsToHHMMSS(end)
    },
    shouldShowGap: function() {
      return this.index === 0 || (!this.startTime && !this.endTime && !this.title)
    },
    handleTextInputBlur: function() {
      this.thirdInputFocus = false
    }
  },
  mounted: function() {
    const result = splitLineIntoTimestamps(this.line || '')
    if (!result) this.error = 'Invalid timestamp'
    else {
      this.error = ''
      const [startTime, endTime, title] = result
      this.startTime = startTime
      this.endTime = endTime
      this.title = title
    }
    setTimeout(() => {
      this.initialised = true
    }, 100)
  },
  watch: {
    startTime: function() {
      this.onChange()
    },
    endTime: function() {
      this.onChange()
    },
    title: function() {
      this.onChange()
    }
  }
}
</script>
