<template>
  <div :id="id" class="bg-black h-full w-full relative group">
    <div v-if="title" class="absolute top-0 left-0 z-50 justify-start items-center w-full clip-title-bg"
      :class="{
        'flex': !isPlaying,
        'hidden group-hover:flex': isPlaying
      }"
    >
      <p class="text-white font-h text-lg font-semibold px-4 py-2 overflow-hidden whitespace-nowrap overflow-ellipsis">{{title}}</p>
    </div>
    <video
      v-if="urlReady"
      id="export-player"
      name="media"
      preload="auto"
      autoplay
      class="h-full w-full"
    />

    <div v-if="!loading" class="w-full px-2 bottom-2 z-50 absolute flex justify-between align-center preview-bar-bg">
      <div :class="`text-white text-xxs ${getCurrentTime.length === 5 ? 'w-9.5' : 'w-12'}`">
        {{getCurrentTime}}
      </div>
      <VueSlider
        id="playerSeeker"
        key="playerSeeker"
        v-model="progressOutOf100"
        class="slider-class"
        :style=" `height:2px; padding: 0; width: 90%; margin: auto auto; ${getTotalTime.length === 5 ? 'calc(100% - 82px)' : 'calc(100% - 122px)'};`"
        :railStyle="{'cursor': 'pointer', 'background-color': '#666C7C'}"
        :processStyle="{'background-color': '#fff'}"
        :tooltip="'none'"
        :interval="0.1"
      >
        <template v-slot:dot="">
          <div :class="'custom-dot'" />
        </template>
      </VueSlider>
        <div :class="`text-white text-xxs text-right ${getTotalTime.length === 5 ? 'w-9.5' : 'w-12'}`">
          {{getTotalTime}}
        </div>
    </div>

    <div class="flex top-0 right-0 bottom-0 left-0 z-40 absolute items-center justify-center">
      <CircularLoader
        v-if="loading"
        class="flex h-14 text-red w-14 items-center justify-center"
      />

      <div
        v-if="!loading && isPlaying"
        class="bg-red rounded-full cursor-pointer flex h-14 text-white w-14 items-center justify-center hidden group-hover:flex"
        @click="togglePlay()"
      >
        <PauseIcon />
      </div>
      <div
        v-if="!loading && !isPlaying"
        class="bg-red rounded-full cursor-pointer flex h-14 text-white w-14 items-center justify-center "
        @click="togglePlay()"
      >
        <PlayIcon />
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/auth'
import 'firebase/database'
import * as firebase from 'firebase/app'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import VueSlider from 'vue-slider-component'
import { secondsToHHMMSS } from '../utils'

export default {
  name: 'HTMLVideoPlayer',
  props: {
    path: String,
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    PlayIcon,
    PauseIcon,
    CircularLoader,
    VueSlider
  },
  data() {
    return {
      loading: true,
      isPlaying: true,
      urlReady: false,
      player: null,
      duration: 0,
      currentTime: 0
    }
  },
  computed: {
    progressOutOf100: {
      get() {
        const max = Math.min(this.duration, 99999)
        const min = 0
        const range = max - min
        if (range) {
          if (this.currentTime >= max) return 100
          return (this.currentTime / range) * 100
        }
        return 0
      },
      set(newVal) {
        const max = Math.min(this.duration, 99999)
        const min = 0
        const range = max - min
        if (range) {
          const seekedTime = max * newVal / 100
          this.player.currentTime = seekedTime
        }
      }
    },
    getTotalTime: function() {
      if (!this.duration) return
      const timeString = secondsToHHMMSS(this.duration)
      if (timeString.substring(0, 2) === '00') { // checks if the first two units (hours) are 0
        return timeString.substring(3)
      }
      return timeString
    },
    getCurrentTime: function() {
      const timeString = secondsToHHMMSS(this.currentTime)
      if (timeString.substring(0, 2) === '00') { // checks if the first two units (hours) are 0
        return timeString.substring(3)
      }
      return timeString
    }
  },
  methods: {
    togglePlay: function() {
      if (this.player) {
        if (this.player.paused) {
          this.player.play()
          this.isPlaying = true
          this.$emit('onPlayToggle', true)
        } else {
          this.player.pause()
          this.isPlaying = false
          this.$emit('onPlayToggle', false)
        }
      }
    }

  },
  mounted: async function() {
    const src = await firebase.storage().ref(this.path).getDownloadURL()
    this.urlReady = true
    setTimeout(() => {
      this.player = document.getElementById('export-player')
      this.player.addEventListener('loadeddata', (e) => {
        if (this.player.readyState >= 3) {
          this.loading = false
        }
      })
      this.player.addEventListener('loadedmetadata', () => {
        this.duration = this.player.duration
      }, false)
      this.player.addEventListener('timeupdate', () => {
        this.currentTime = this.player.currentTime
      }, false)
      this.player.src = src
    }, 30)
  }

}
</script>

<style lang="scss" scoped>
.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 0, 0, 0.61);
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top:2px;
  left: 7px;
  background-color: #E2462C;
  transform: translateX(-50%);
  transition: left .3s;
  box-sizing: border-box;

  &:hover, &:active {
    width: 12px;
    height: 12px;
    top: 1px;
    border-width: 2px;
  }
}

.clip-title-bg {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.7861738445378151) 61%, rgba(255,255,255,0) 100%);
}

.preview-bar-bg {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7861738445378151) 61%, rgba(255,255,255,0) 100%);
}

.text-xxs {
  font-size: 10px;
}
</style>
