<template>
  <div class="relative grid grid-cols-2 gap-x-8 gap-y-6">
    <div v-if="disabled" class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white opacity-80" />
    <div class="col-span-1">
      <div class="mb-1 font-semibold font-h text-primary">Intro Settings</div>
      <UploadFile
        key="intro"
        name="intro"
        accept=".mp4"
        type="video"
        :value="introValue"
        @onChange="onIntroChange"
      />
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-semibold font-h text-primary">Outro Settings</div>
      <UploadFile
        key="outro"
        name="outro"
        accept=".mp4"
        type="video"
        :value="outroValue"
        @onChange="onOutroChange"
      />
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-semibold font-h text-primary">Logo</div>
      <UploadFile
        key="logo"
        name="logo"
        accept=".jpeg,.jpg,.png"
        type="image"
        :value="logoValue"
        @onChange="onLogoChange"
      />
    </div>

    <div class="col-span-1">
      <div class="mb-1 font-semibold font-h text-primary">Logo Alignment</div>
      <LogoAlignment
        :value="logoStyle"
        @onChange="onLogoStyleChange"
      />
    </div>

    <div
      :class="`col-span-${subsExpanded ? '2' : '1'}`"
    >
      <div class="flex items-center mb-1 font-semibold font-h text-primary">
        Subtitles
        <VueSwitch class="ml-2" v-model="enableSubtitleFormatting" />
      </div>
      <SubtitleFormatting
        :disabled="!enableSubtitleFormatting"
        :value="subtitleStyle"
        :subsExpanded="subsExpanded"
        @onChange="onSubtitleStyleChange"
      />
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { mapGetters } from 'vuex'
import { getBrandkitInfo } from '@/services/api/brandkit'
import VueSwitch from '@/components/base/inputs/Switch'

import UploadFile from '@/view/BrandKit/components/UploadFile.vue'
import LogoAlignment from '@/view/BrandKit/components/LogoAlignment.vue'
import SubtitleFormatting from '@/view/BrandKit/components/SubtitleFormatting.vue'

import videoPlayer from '@/components/VideoPlayer/api'
import { getImageFromPath } from '@/components/VideoPlayer/playerElementsData'

export default {
  name: 'BrandKit',
  components: {
    UploadFile,
    LogoAlignment,
    SubtitleFormatting,
    VueSwitch
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    subsExpanded: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      version: 'v1',
      tab: 'video',
      brandkit: {},
      logoUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    introValue: function() {
      return this.brandkit.intro || {}
    },
    outroValue: function() {
      return this.brandkit.outro || {}
    },
    logoValue: function() {
      return this.brandkit.logo || {}
    },
    logoStyle: function() {
      return this.brandkit.logoStyle || {}
    },
    enableSubtitleFormatting: {
      get() {
        return this.brandkit ? this.brandkit.enableSubtitleFormatting || false : false
      },
      set(val) {
        this.saveBrandkit({ enableSubtitleFormatting: val }, false)
      }
    },
    subtitleStyle: function() {
      return this.brandkit.subtitleStyle || {}
    }
  },
  methods: {
    // TODO: pass updated brandkit to copybrandkit fn instead of waiting on firebase save
    saveBrandkit: function(update, next = true) {
      firebase.database().ref(`/${this.user.uid}/brandkit/${this.tab}/${this.version}`)
        .update(update)
        .then(() => {
          this.$emit('onChange', next)
        })
    },
    onIntroChange: function(intro) {
      this.saveBrandkit({ intro })
      this.$emit('showToast', '<span>Changes saved and added to <span class="underline cursor-pointer">brand kit</span></span>')
    },
    onOutroChange: function(outro) {
      this.saveBrandkit({ outro })
      this.$emit('showToast', '<span>Changes saved and added to <span class="underline cursor-pointer">brand kit</span></span>')
    },
    onLogoChange: async function(logo) {
      this.saveBrandkit({ logo }, false)
      this.scheduleLogoChange(logo)
      this.$emit('showToast', '<span>Changes saved and added to <span class="underline cursor-pointer">brand kit</span></span>')
    },
    onLogoStyleChange: function(logoStyle) {
      this.saveBrandkit({ logoStyle }, false)
      this.scheduleLogoStyleChange(logoStyle)
    },
    onSubtitleStyleChange: function(update) {
      this.saveBrandkit({
        subtitleStyle: {
          ...this.brandkit.subtitleStyle,
          ...update
        }
      }, false)
    },
    scheduleLogoChange: async function(logo) {
      const logoUrl = logo && logo.url ? await getImageFromPath(logo && logo.url) : ''
      videoPlayer.updateImage({
        src: logoUrl
      })
    },
    scheduleLogoStyleChange: function(logoStyle) {
      videoPlayer.updateImagePosition({
        position: logoStyle
      })
    }
  },
  created: function() {
    // setup brandkit listner
    getBrandkitInfo(
      {
        userId: this.user.uid,
        type: this.tab,
        version: this.version
      },
      val => {
        if (val) {
          this.brandkit = val
        }
      }
    )
  }
}
</script>

<style scoped lang="scss">

</style>
