<template>
  <div class="relative"
    :class="{
      'flex justify-between': subsExpanded
    }"
  >
    <div v-if="disabled" class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white opacity-70" />
    <div class="grid grid-cols-10"
      :class="`${ subsExpanded ? 'gap-4' : 'gap-2' }`"
    >
      <div class="col-span-5">
        <Popover
          placement="top-start"
          popoverArrowClass="hidden"
        >
          <div class="border rounded cursor-pointer flex border-primary-400 text-sm text-primary w-full py-3 px-2.5 col-span-2 items-center justify-between">
            <div class="text-sm text-primary">{{value['font-family'] || 'Roboto'}}</div>
            <ChevronDown class="w-3 h-3 dropdownCaret"/>
          </div>

          <template slot="popover">
            <div class="max-h-80 py-1.5 pr-0.5 pl-1.5 fontPopup overflow-y-auto">
              <div
                v-for="el in fonts"
                :key="el"
                :value="el"
                @click="$emit('onChange', {'font-family': el})"
                v-close-popover
                class="rounded cursor-pointer text-sm text-primary mb-2 p-2.5 hover:bg-primary-200 last:mb-0"
                :class="{'text-red': value['font-family'] === el}"
                :style="{'font-family': el}"
              >
                {{el}}
              </div>
            </div>
          </template>
        </Popover>
      </div>

      <div class="col-span-3">
        <Popover
          placement="top-start"
          popoverArrowClass="hidden"
        >
          <div class="border rounded cursor-pointer flex border-primary-400 text-sm text-primary w-full py-3 px-2.5 col-span-2 items-center justify-between">
            <div class="text-sm text-primary">{{selectedFontSize ? selectedFontSize.name : 'M'}}</div>
            <ChevronDown class="w-3 h-3 dropdownCaret"/>
          </div>

          <template slot="popover">
            <div class="max-h-80 py-1.5 pr-0.5 pl-1.5 fontsizePopup overflow-y-auto">
              <div
                v-for="el in fontSizes"
                :key="el.name"
                :value="el.value"
                @click="$emit('onChange', {'font-size': el.value})"
                v-close-popover
                class="rounded cursor-pointer text-sm text-primary mb-2 p-2.5 hover:bg-primary-200 last:mb-0"
                :class="{'text-red': value['font-size'] && value['font-size'] === el.value}"
              >
                {{el.name}}
              </div>
            </div>
          </template>
        </Popover>
      </div>

      <div class="col-span-2">
        <Popover
          placement="top-end"
          popoverArrowClass="hidden"
        >
          <div
            class="border rounded cursor-pointer flex border-primary-400 text-sm text-primary w-full py-2 px-1.75 col-span-2 items-center justify-between"
          >
            <div
              class="text-sm rounded px-1.25 py-1"
              :style="{
                'color': value['color'] || '#E2462C',
                'background': value['background'] || '#fff'
              }"
            >
              <AIcon v-if="value['color']!=='#ffffff'"/>
              <AoutlineIcon v-if="value['color']==='#ffffff'" />
            </div>
            <ChevronDown class="w-3 h-3 dropdownCaret"/>
          </div>

          <template slot="popover">
            <div class="p-4 fontstylePopup">
              <div class="mb-2 text-sm font-semibold font-h text-primary">Text Color</div>
              <div class="grid grid-cols-4 gap-2">
                <div
                  v-for="el in fontColors"
                  :key="el"
                  :value="el"
                  @click="$emit('onChange', {'color': el})"
                  class="flex items-center justify-center col-span-1 py-2 border rounded cursor-pointer border-primary-400 hover:border-primary hover:bg-primary-200"
                  :class="{'border-primary': value['color'] === el}"
                  :style="{'color': el}"
                >
                  <AIcon v-if="el!=='#ffffff'" class="w-6 h-6"/>
                  <AoutlineIcon v-if="el==='#ffffff'" class="w-6 h-6" />
                </div>
              </div>

              <div class="mt-4 mb-2 text-sm font-semibold font-h text-primary">Background Color</div>
              <div class="grid grid-cols-5 gap-2 text-primary-400">
                <div
                  v-for="el in bgColors"
                  :key="el"
                  :value="el"
                  @click="$emit('onChange', {'background': el })"
                  class="w-8 h-8 col-span-1 border-4 border-transparent rounded-full shadow-sm cursor-pointer hover:border-primary-400"
                  :class="{'border-primary-400': value['background'] === el, 'pattern-checks-sm transparent-bg': el==='transparent'}"
                  :style="{'background': el==='transparent' ? '' : el}"
                />
              </div>
            </div>
          </template>
        </Popover>
      </div>
    </div>

    <div class="grid grid-cols-5 "
      :class="`${ subsExpanded ? 'gap-5' : 'gap-2 mt-2' }`"
    >
      <div class="flex items-center justify-around w-full col-span-2 border rounded border-primary-400">
        <div
          class="cursor-pointer mx-3 py-2.5 text-primary-500 hover:text-primary"
          :class="{'text-primary': value['font-weight'] === 'bold'}"
          @click="$emit('onChange',value['font-weight'] ? {'font-weight': ''} : {'font-weight': 'bold'})"
        >
          <BoldIcon />
        </div>

        <div
          class="cursor-pointer mx-3 py-2.5 text-primary-500 hover:text-primary"
          :class="{'text-primary': value['font-style'] === 'italic'}"
          @click="$emit('onChange', value['font-style'] ? {'font-style': ''} : {'font-style': 'italic'})"
        >
          <ItalicIcon />
        </div>
      </div>

      <div class="inline-flex items-center justify-around col-span-3 border rounded border-primary-400">
        <div
          class="cursor-pointer py-2.5 text-primary-500 hover:text-primary"
          v-tooltip.top="'Text align left'"
          :class="{'text-primary': value['text-align'] === 'left'}"
          @click="$emit('onChange', {'text-align': 'left', 'justify-content': 'flex-start'})"
        >
          <TextAlignLeft />
        </div>

        <div
          class="cursor-pointer mx-2.5 py-2.5 text-primary-500 hover:text-primary"
          v-tooltip.top="'Text align center'"
          :class="{'text-primary': value['text-align'] === 'center'}"
          @click="$emit('onChange', {'text-align': 'center', 'justify-content': 'center'})"
        >
          <TextAlignCenter />
        </div>

        <div
          class="cursor-pointer py-2.5 text-primary-500 hover:text-primary"
          v-tooltip.top="'Text align right'"
          :class="{'text-primary': value['text-align'] === 'right'}"
          @click="$emit('onChange', {'text-align': 'right', 'justify-content': 'flex-end'})"
        >
          <TextAlignRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popover from '@/components/base/display/Popover.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import AIcon from '@/components/base/icons/A.vue'
import AoutlineIcon from '@/components/base/icons/Aoutline.vue'
import TextAlignLeft from '@/components/base/icons/TextAlignLeft.vue'
import TextAlignCenter from '@/components/base/icons/TextAlignCenter.vue'
import TextAlignRight from '@/components/base/icons/TextAlignRight.vue'
import ItalicIcon from '@/components/base/icons/Italic.vue'
import BoldIcon from '@/components/base/icons/Bold.vue'

import isEqual from 'lodash/isEqual'

import 'pattern.css'

const fonts = [
  'Abril Fatface',
  'Alfa Slab One',
  'Titan One',
  'Work Sans',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Rakkas',
  'Roboto',
  'Rubik',
  'Shrikhand',
  'Squada One',
  'Iceberg',
  'Just Another Hand',
  'Kumar One Outline',
  'Lobster',
  'Oswald',
  'Pacifico',
  'Zilla Slab',
  'ZCOOL KuaiLe',
  'Anton',
  'Archivo',
  'Archivo Black',
  'Arimo',
  'Bad Script',
  'Bangers',
  'Ballet',
  'Calistoga',
  'Chewy',
  'Cinzel',
  'Courier Prime'
]
const fontSizes = [
  {
    name: 'XS',
    value: '24px'
  },
  {
    name: 'S',
    value: '36px'
  },
  {
    name: 'M',
    value: '48px'
  },
  {
    name: 'L',
    value: '60px'
  },
  {
    name: 'XL',
    value: '72px'
  }
]
const fontColors = [
  '#000228',
  '#ffffff',
  '#EC5B16',
  '#53B745',
  '#0075FF',
  '#8338EC',
  '#F8C450'
]
const bgColors = [
  '#fff',
  '#000000',
  '#E2462C',
  '#52AADB',
  '#F8C450',
  '#F20D70',
  '#B4C236',
  '#53B745',
  '#AE33D9',
  'transparent'
]
const positions = {
  topRight: {
    x: 92,
    y: 88
  },
  topLeft: {
    x: 8,
    y: 88
  },
  bottomRight: {
    x: 92,
    y: 12
  },
  bottomLeft: {
    x: 8,
    y: 12
  }
}

export default {
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    subsExpanded: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      fonts,
      fontSizes,
      fontColors,
      bgColors
    }
  },
  components: {
    Popover,
    ChevronDown,
    AIcon,
    AoutlineIcon,
    TextAlignRight,
    TextAlignCenter,
    TextAlignLeft,
    ItalicIcon,
    BoldIcon
  },
  computed: {
    activeFont: function() {
      let position = ''
      if (this.value.x) {
        position = Object.keys(positions).find(el => isEqual(positions[el], this.value))
      }
      return position
    },
    selectedFontSize: function() {
      return fontSizes.find(el => el.value === (this.value['font-size'] || '48px'))
    }
  },
  methods: {
    setPosition: function(position) {
      this.$emit('onChange', positions[position])
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Anton&family=Archivo&family=Archivo+Black&family=Arimo&family=Bad+Script&family=Ballet&family=Bangers&family=Calistoga&family=Chewy&family=Cinzel&family=Courier+Prime&family=Iceberg&family=Just+Another+Hand&family=Kumar+One+Outline&family=Lobster&family=Oswald&family=Pacifico&family=Permanent+Marker&family=Playfair+Display&family=Poppins&family=Rakkas&family=Roboto&family=Rubik&family=Shrikhand&family=Squada+One&family=Titan+One&family=Work+Sans&family=ZCOOL+KuaiLe&family=Zilla+Slab&display=swap');

.fontPopup {
  width: 164px;
}

.fontsizePopup {
  width: 90px;
}

.fontstylePopup {
  width: 230px;
}

.transparent-bg {
  background-position: 0 0, 4px 4px;
  background-position-x: 0px, 4px;
  background-position-y: 0px, 4px;
  background-size: calc(2 * 4px) calc(2 * 2px);
}
</style>
