<template>
  <div class="bottom-6 left-28 z-50 fixed downloadsPopup">
    <div
      class="bg-primary rounded-tr rounded-tl flex h-10 px-4 items-center justify-between"
      :class="{'rounded-bl rounded-br': minimized}"
    >
      <div class="text-white text-sm">Downloads</div>
      <div
        class="cursor-pointer text-white"
        @click="minimized=!minimized"
      >
        <Maximize v-if="minimized"/>
        <Minimize v-else/>
      </div>
    </div>

    <div
      class="bg-white rounded-bl rounded-br flex p-4 items-center justify-between"
      v-if="!minimized"
    >
      <div class="flex items-center">
        <div class="mr-2 text-primary-500">
          <Zip />
        </div>
        <div>
          <div class="text-primary text-sm mb-1">
            {{title}}
          </div>
          <div class="text-xs text-primary-500 loadingDots">
            {{message}}
          </div>
        </div>
      </div>

      <div class="text-red">
        <CircularLoader />
      </div>
    </div>
  </div>
</template>

<script>
import Minimize from '@/components/base/icons/Minimize.vue'
import Maximize from '@/components/base/icons/Maximize.vue'
import Zip from '@/components/base/icons/Zip.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'

export default {
  name: 'Downloads',
  data: function() {
    return {
      minimized: false
    }
  },
  props: {
    message: {
      type: String,
      default: 'Preparing to download'
    },
    title: {
      type: String,
      default: 'Clips'
    }
  },
  components: {
    Minimize,
    Maximize,
    Zip,
    CircularLoader
  }
}
</script>

<style scoped lang="scss">
.downloadsPopup {
  width: 340px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 0px 12px rgba(0, 2, 40, 0.1);
}
</style>
