<template>
  <div class="inline-flex items-center px-2 border rounded-lg border-primary-400 uploadFile">
    <div
      class="py-4 mx-3 cursor-pointer text-primary-500 hover:text-primary"
      v-tooltip.top="'Align top right'"
      :class="{'text-primary': activePosition === 'topRight'}"
      @click="setPosition('topRight')"
    >
      <AlignTopRight />
    </div>

    <div
      class="py-4 mx-3 cursor-pointer text-primary-500 hover:text-primary"
      v-tooltip.top="'Align bottom right'"
      :class="{'text-primary': activePosition === 'bottomRight'}"
      @click="setPosition('bottomRight')"
    >
      <AlignBottomRight />
    </div>

    <div
      class="py-4 mx-3 cursor-pointer text-primary-500 hover:text-primary"
      v-tooltip.top="'Align top left'"
      :class="{'text-primary': activePosition === 'topLeft'}"
      @click="setPosition('topLeft')"
    >
      <AlignTopLeft />
    </div>

    <div
      class="py-4 mx-3 cursor-pointer text-primary-500 hover:text-primary"
      v-tooltip.top="'Align bottom left'"
      :class="{'text-primary': activePosition === 'bottomLeft'}"
      @click="setPosition('bottomLeft')"
    >
      <AlignBottomLeft />
    </div>
  </div>
</template>

<script>
import AlignBottomLeft from '@/components/base/icons/AlignBottomLeft.vue'
import AlignBottomRight from '@/components/base/icons/AlignBottomRight.vue'
import AlignTopLeft from '@/components/base/icons/AlignTopLeft.vue'
import AlignTopRight from '@/components/base/icons/AlignTopRight.vue'
import isEqual from 'lodash/isEqual'

const positions = {
  topRight: {
    x: 92,
    y: 88
  },
  topLeft: {
    x: 8,
    y: 88
  },
  bottomRight: {
    x: 92,
    y: 12
  },
  bottomLeft: {
    x: 8,
    y: 12
  }
}

export default {
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  components: {
    AlignBottomLeft,
    AlignBottomRight,
    AlignTopLeft,
    AlignTopRight
  },
  computed: {
    activePosition: function() {
      let position = ''
      if (this.value.x) {
        position = Object.keys(positions).find(el => isEqual(positions[el], this.value))
      }
      return position
    }
  },
  methods: {
    setPosition: function(position) {
      this.$emit('onChange', positions[position])
    }
  }
}
</script>

<style>
.uploadFile {
  max-height: 50px;
  min-height: 50px;
}
</style>
