<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    viewBox="0 0 19 20"
    :class="classname"
  >
    <path d="M8.05018 4.29285L10.0502 2.29286C12.0976 0.24548 15.417 0.245478 17.4644 2.29286C19.5118 4.34024 19.5118 7.6597 17.4644 9.70708L15.4644 11.7071L14.0502 10.2929L16.0502 8.29287C17.3165 7.02654 17.3165 4.9734 16.0502 3.70707C14.7839 2.44074 12.7307 2.44074 11.4644 3.70707L9.4644 5.70707L8.05018 4.29285Z" />
    <path d="M11.4644 15.7071L9.46441 17.7071C7.41703 19.7544 4.09756 19.7544 2.05018 17.7071C0.00280261 15.6597 0.00280404 12.3402 2.05018 10.2928L4.05018 8.29284L5.46439 9.70706L3.4644 11.7071C2.19807 12.9734 2.19807 15.0265 3.4644 16.2928C4.73073 17.5592 6.78386 17.5592 8.05019 16.2928L10.0502 14.2929L11.4644 15.7071Z" />
    <path d="M7.4644 13.7071L13.4644 7.70707L12.0502 6.29285L6.05018 12.2929L7.4644 13.7071Z" />
  </svg>
</template>

<script>
export default {
  name: 'Share',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
