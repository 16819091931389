<template>
    <span
      class="toggle-wrapper"
      role="checkbox"
      :aria-checked="value.toString()"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span
        class="toggle-background"
        :class="backgroundStyles"
      />
      <span
        class="toggle-indicator"
        :style="indicatorStyles"
      />
    </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    hightlight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backgroundStyles: function() {
      return {
        'bg-primary': this.value,
        'bg-primary-200': !this.value && !this.hightlight,
        'bg-red': !this.value && this.hightlight
      }
    },
    indicatorStyles: function() {
      return {
        transform: this.value ? 'translateX(14px)' : 'translateX(0)',
        border: this.value ? '1px solid #818181' : '1px solid #D7DBE7'
      }
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>
<style scoped>
.toggle-wrapper {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 16px;
  border-radius: 16px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-flex;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 2, 40, 0.1);
  transition: background-color .5s ease;
}

.toggle-indicator {
  position: absolute;
  height: 16px;
  width: 16px;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  box-shadow:  0 2px 4px rgba(0, 2, 40, 0.1);
  transition: transform .5s ease, border .5s ease;
}
</style>
