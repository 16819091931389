<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3731 9.29289C16.7636 9.68342 16.7636 10.3166 16.3731 10.7071L12.3731 14.7071C11.9826 15.0976 11.3494 15.0976 10.9589 14.7071L8.95891 12.7071C8.56838 12.3166 8.56838 11.6834 8.95891 11.2929C9.34943 10.9024 9.9826 10.9024 10.3731 11.2929L11.666 12.5858L14.9589 9.29289C15.3494 8.90237 15.9826 8.90237 16.3731 9.29289Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0232 3H14.3088C15.3926 2.99999 16.2668 2.99998 16.9746 3.05782C17.7035 3.11737 18.3437 3.24318 18.936 3.54497C19.8768 4.02433 20.6417 4.78924 21.121 5.73005C21.4228 6.32234 21.5486 6.96253 21.6082 7.69138C21.666 8.39925 21.666 9.27339 21.666 10.3572V13.6428C21.666 14.7266 21.666 15.6008 21.6082 16.3086C21.5486 17.0375 21.4228 17.6777 21.121 18.27C20.6417 19.2108 19.8768 19.9757 18.936 20.455C18.3437 20.7568 17.7035 20.8826 16.9746 20.9422C16.2668 21 15.3926 21 14.3088 21H11.0232C9.93941 21 9.06527 21 8.35739 20.9422C7.62854 20.8826 6.98835 20.7568 6.39606 20.455C5.45525 19.9757 4.69035 19.2108 4.21098 18.27C3.9092 17.6777 3.78338 17.0375 3.72383 16.3086C3.666 15.6007 3.66601 14.7266 3.66602 13.6428V10.3572C3.66601 9.27341 3.666 8.39926 3.72383 7.69138C3.78338 6.96253 3.9092 6.32234 4.21098 5.73005C4.69035 4.78924 5.45525 4.02433 6.39606 3.54497C6.98835 3.24318 7.62854 3.11737 8.35739 3.05782C9.06527 2.99998 9.93942 2.99999 11.0232 3ZM8.52026 5.05118C7.91509 5.10062 7.5674 5.19279 7.30404 5.32698C6.73956 5.6146 6.28062 6.07354 5.993 6.63803C5.85881 6.90138 5.76664 7.24907 5.71719 7.85424C5.66679 8.47108 5.66602 9.26339 5.66602 10.4V13.6C5.66602 14.7366 5.66679 15.5289 5.71719 16.1458C5.76664 16.7509 5.85881 17.0986 5.993 17.362C6.28062 17.9265 6.73956 18.3854 7.30404 18.673C7.5674 18.8072 7.91509 18.8994 8.52026 18.9488C9.1371 18.9992 9.92941 19 11.066 19H14.266C15.4026 19 16.1949 18.9992 16.8118 18.9488C17.4169 18.8994 17.7646 18.8072 18.028 18.673C18.5925 18.3854 19.0514 17.9265 19.339 17.362C19.4732 17.0986 19.5654 16.7509 19.6148 16.1458C19.6652 15.5289 19.666 14.7366 19.666 13.6V10.4C19.666 9.26339 19.6652 8.47108 19.6148 7.85424C19.5654 7.24907 19.4732 6.90138 19.339 6.63803C19.0514 6.07354 18.5925 5.6146 18.028 5.32698C17.7646 5.19279 17.4169 5.10062 16.8118 5.05118C16.1949 5.00078 15.4026 5 14.266 5H11.066C9.92941 5 9.1371 5.00078 8.52026 5.05118Z" />
  </svg>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
