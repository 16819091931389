<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 4.1875C3.5 3.63522 3.94772 3.1875 4.5 3.1875H20.5C21.0523 3.1875 21.5 3.63522 21.5 4.1875C21.5 4.73978 21.0523 5.1875 20.5 5.1875H4.5C3.94772 5.1875 3.5 4.73978 3.5 4.1875ZM3.5 12.1875C3.5 11.6352 3.94772 11.1875 4.5 11.1875H20.5C21.0523 11.1875 21.5 11.6352 21.5 12.1875C21.5 12.7398 21.0523 13.1875 20.5 13.1875H4.5C3.94772 13.1875 3.5 12.7398 3.5 12.1875ZM4.5 19.1875C3.94772 19.1875 3.5 19.6352 3.5 20.1875C3.5 20.7398 3.94772 21.1875 4.5 21.1875H20.5C21.0523 21.1875 21.5 20.7398 21.5 20.1875C21.5 19.6352 21.0523 19.1875 20.5 19.1875H4.5ZM7.5 8.1875C7.5 7.63522 7.94772 7.1875 8.5 7.1875H16.5C17.0523 7.1875 17.5 7.63522 17.5 8.1875C17.5 8.73978 17.0523 9.1875 16.5 9.1875H8.5C7.94772 9.1875 7.5 8.73978 7.5 8.1875ZM8.5 15.1875C7.94772 15.1875 7.5 15.6352 7.5 16.1875C7.5 16.7398 7.94772 17.1875 8.5 17.1875H16.5C17.0523 17.1875 17.5 16.7398 17.5 16.1875C17.5 15.6352 17.0523 15.1875 16.5 15.1875H8.5Z" />
  </svg>
</template>

<script>
export default {
  name: 'TextAlignCenter',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
