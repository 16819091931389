import { humanLength } from '@/utilities/utility'

export const splitLineIntoTimestamps = line => {
  // const timePattern = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/gm
  const timePattern = /(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)/gm
  const matches = (line.match(timePattern) || []).filter(el => el.includes(':'))
  const error = !matches.length
  if (error) return null

  const startTime = matches[0]
  const endTime = matches[1] || ''
  const lastTime = endTime || startTime
  const newLine = startTime === endTime ? line.replace(startTime, '') : line
  const words = newLine.split(' ')
  const lastTimeIndex = words.findIndex(t => t === lastTime)

  return [startTime, endTime, words.slice(lastTimeIndex + 1).join(' ')]
}

export const findAndToggleObj = (arr, key, item, forceSelect = false) => {
  if (!Array.isArray(arr)) return []
  const i = arr.findIndex(el => el[key] === item[key])
  if (i !== -1 && forceSelect) return arr
  return i === -1 ? [ item, ...arr ] : [...arr.slice(0, i), ...arr.slice(i + 1)]
}

export const findAndToggleItem = (arr, item) => {
  if (!Array.isArray(arr)) return []
  const i = arr.findIndex(el => el === item)
  return i === -1 ? [ item, ...arr ] : [...arr.slice(0, i), ...arr.slice(i + 1)]
}

export const hhmmssToSeconds = (hhmmss) => {
  const [seconds = 0, minutes = 0, hours = 0] = hhmmss.split(':').reverse()
  return (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds)
}

export const modulus = (num, divisor) => {
  return [Math.floor(num / divisor), num % divisor]
}

export const secondsToHHMMSS = (seconds, round = false) => {
  if (round) {
    seconds = seconds.toFixed(0)
  }
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export const getResultsForUI = (list) => list.map(t => ({
  ...t,
  atTime: humanLength(Math.round(t.atTime)),
  key: `${t.location.start}-${t.location.end}`,
  stringWithoutSearchWord: t.resultString.replace(t.searchWord, '')
}))
