<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path d="M3.20399 17.3127C3.13121 16.9958 3.08665 16.6627 3.05772 16.3086C3.02808 15.9458 3.01363 15.5393 3.00659 15.0833L5.34974 13.6345L5.42838 13.5857C5.75808 13.381 6.08967 13.1751 6.46323 13.079C6.78933 12.9951 7.129 12.9779 7.46188 13.0286C7.84323 13.0866 8.19387 13.258 8.5425 13.4285L8.62565 13.4691L9.15401 13.7262C9.6834 13.9838 9.74654 14.001 9.78872 14.0065C9.85991 14.0159 9.93229 14.0099 10.0009 13.9888C10.0416 13.9763 10.101 13.9489 10.5802 13.6068L13.0858 11.8177L13.1789 11.751C13.5632 11.4754 13.9488 11.1988 14.3937 11.08C14.7813 10.9766 15.1881 10.9679 15.5797 11.0548C16.0293 11.1545 16.4263 11.4145 16.8219 11.6736L16.9178 11.7363L20.9991 14.3959C20.9965 15.1414 20.9859 15.7727 20.9421 16.3086C20.9307 16.4484 20.9168 16.5849 20.8998 16.7184L15.8258 13.4119C15.2632 13.0452 15.1925 13.0175 15.1466 13.0073C15.0683 12.99 14.9869 12.9917 14.9094 13.0124C14.8639 13.0245 14.7945 13.0552 14.248 13.4454L11.7423 15.2344L11.6611 15.2926C11.3224 15.5355 10.9819 15.7796 10.5885 15.9005C10.2453 16.006 9.88338 16.0363 9.52742 15.9894C9.11933 15.9356 8.74301 15.7515 8.36859 15.5684L8.27883 15.5246L7.75047 15.2674C7.25887 15.0282 7.20085 15.0119 7.16121 15.0058C7.09464 14.9957 7.0267 14.9991 6.96149 15.0159C6.92266 15.0259 6.86658 15.048 6.40157 15.3355L3.20399 17.3127Z" />
    <path d="M10.9999 9C10.9999 10.1046 10.1045 11 8.99991 11C7.89534 11 6.99991 10.1046 6.99991 9C6.99991 7.89543 7.89534 7 8.99991 7C10.1045 7 10.9999 7.89543 10.9999 9Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3572 3H13.6428C14.7266 2.99999 15.6007 2.99998 16.3086 3.05782C17.0375 3.11737 17.6777 3.24318 18.27 3.54497C19.2108 4.02433 19.9757 4.78924 20.455 5.73005C20.7568 6.32234 20.8826 6.96253 20.9422 7.69138C21 8.39925 21 9.27339 21 10.3572V13.6428C21 14.7266 21 15.6008 20.9422 16.3086C20.8826 17.0375 20.7568 17.6777 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.6777 20.7568 17.0375 20.8826 16.3086 20.9422C15.6008 21 14.7266 21 13.6428 21H10.3572C9.27339 21 8.39925 21 7.69138 20.9422C6.96253 20.8826 6.32234 20.7568 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3.24318 17.6777 3.11737 17.0375 3.05782 16.3086C2.99998 15.6007 2.99999 14.7266 3 13.6428V10.3572C2.99999 9.27341 2.99998 8.39926 3.05782 7.69138C3.11737 6.96253 3.24318 6.32234 3.54497 5.73005C4.02433 4.78924 4.78924 4.02433 5.73005 3.54497C6.32234 3.24318 6.96253 3.11737 7.69138 3.05782C8.39926 2.99998 9.27341 2.99999 10.3572 3ZM7.85424 5.05118C7.24907 5.10062 6.90138 5.19279 6.63803 5.32698C6.07354 5.6146 5.6146 6.07354 5.32698 6.63803C5.19279 6.90138 5.10062 7.24907 5.05118 7.85424C5.00078 8.47108 5 9.26339 5 10.4V13.6C5 14.7366 5.00078 15.5289 5.05118 16.1458C5.10062 16.7509 5.19279 17.0986 5.32698 17.362C5.6146 17.9265 6.07354 18.3854 6.63803 18.673C6.90138 18.8072 7.24907 18.8994 7.85424 18.9488C8.47108 18.9992 9.26339 19 10.4 19H13.6C14.7366 19 15.5289 18.9992 16.1458 18.9488C16.7509 18.8994 17.0986 18.8072 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C18.8072 17.0986 18.8994 16.7509 18.9488 16.1458C18.9992 15.5289 19 14.7366 19 13.6V10.4C19 9.26339 18.9992 8.47108 18.9488 7.85424C18.8994 7.24907 18.8072 6.90138 18.673 6.63803C18.3854 6.07354 17.9265 5.6146 17.362 5.32698C17.0986 5.19279 16.7509 5.10062 16.1458 5.05118C15.5289 5.00078 14.7366 5 13.6 5H10.4C9.26339 5 8.47108 5.00078 7.85424 5.05118Z" />
  </svg>
</template>

<script>
export default {
  name: 'Image',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
