<template>
  <div :id="id" ref="previewPlayer" class="relative flex flex-col items-center justify-center w-full h-full bg-black group">

    <div v-if="!isReady" class="absolute top-0 z-50 flex items-center justify-center w-full h-full loadingOverlay">
      <CircularLoader
        class="absolute flex items-center justify-center text-white rounded-full h-14 text-red w-14"
      />
      <div class="text-xs font-semibold text-white font-h">{{Math.round(docProgress)}}%</div>
    </div>

    <div
      v-if="isReady && (!disableInteraction || showInteractionOnHover)"
      class="absolute top-0 z-50 flex items-center justify-center w-full h-full"
      :class="{'hidden group-hover:flex': showInteractionOnHover}"
    >
      <div v-if="title" class="absolute top-0 left-0 z-50 justify-start items-center w-full clip-title-bg"
        :class="{
          'flex': !isPlaying,
          'hidden group-hover:flex': isPlaying
        }"
      >
        <p class="text-white font-h text-lg font-semibold px-4 py-2 overflow-hidden whitespace-nowrap overflow-ellipsis">{{title}}</p>
      </div>
      <div
        v-if="isPlaying"
        class="flex items-center justify-center hidden text-white rounded-full cursor-pointer bg-red h-14 w-14 group-hover:flex"
        @click="togglePlay"
      >
        <PauseIcon />
      </div>
      <div
        v-else
        class="flex items-center justify-center text-white rounded-full cursor-pointer bg-red h-14 w-14"
        @click="togglePlay"
      >
        <PlayIcon />
      </div>

      <div v-show="isReady && showSeeker" class="absolute w-full px-2 bottom-0 pb-2 preview-bar-bg flex justify-between align-center">
        <div
          :class="`text-white text-xxs ${getCurrentTime.length === 5 ? 'w-9.5' : 'w-12'}`"
        >
          {{getCurrentTime}}
        </div>
        <VueSlider
          id="playerSeeker"
          key="playerSeeker"
          v-model="progressOutOf100"
          :style="`height:2px; padding: 0; width: ${getTotalTime.length === 5 ? 'calc(100% - 82px)' : 'calc(100% - 122px)'}; margin: auto auto;`"
          class="slider-class"
          :railStyle="{'cursor': 'pointer', 'background-color': '#666C7C'}"
          :processStyle="{'background-color': '#fff'}"
          :tooltip="'none'"
          :interval="0.1"
        >
          <template v-slot:dot="">
            <div :class="'custom-dot'" />
          </template>
        </VueSlider>
        <div :class="`text-white text-xxs text-right ${getTotalTime.length === 5 ? 'w-9.5' : 'w-12'}`">
          {{getTotalTime}}
        </div>
      </div>
    </div>

    <VideoPlayer
      v-if="showVideoPlayer"
      :type="playerType"
      :subtitle="subtitleOverRide || subtitle"
      :autoplay="false"
      :loading="loading === 'true'"
      :subtitleStyle="subtitleStyle"
      :scale="getScale"
      :videoPlayerInfo="videoPlayerInfo"
      :vpRatio="vpRatio"
      :progress="progressOutOf100"
      @goToTime="goToTime"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import store from '@/services/store'
import { mapGetters, mapActions } from 'vuex'
import { formatAsSubtitleNew } from '@/utilities/utility'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'

import VideoPlayer from '@/components/VideoPlayer/index.vue'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import { secondsToHHMMSS } from '../utils'
export default {
  props: {
    fileKey: {
      type: String,
      default: ''
    },
    fileOwner: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    from: {
      type: Number,
      default: 0
    },
    to: {
      type: Number,
      default: 99999
    },
    loading: {
      type: String,
      required: true
    },
    subtitleStyle: {
      type: Object,
      default: function() {
        return {}
      }
    },
    showSeeker: {
      type: Boolean,
      default: true
    },
    customClickAction: {
      type: Boolean,
      default: false
    },
    playerType: {
      type: String,
      default: 'video'
    },
    videoPlayerInfo: {
      type: Object,
      default: () => ({})
    },
    showVideoPlayer: {
      type: Boolean,
      default: false
    },
    subtitleOverRide: {
      type: Array,
      required: false
    },
    disableInteraction: {
      type: Boolean,
      default: false
    },
    showplayer: {
      type: Boolean,
      default: true
    },
    showInteractionOnHover: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    VideoPlayer,
    CircularLoader,
    PlayIcon,
    PauseIcon,
    VueSlider
  },
  data: function() {
    return {
      height: 228,
      width: 405,
      isReady: false,
      subtitle: []
    }
  },
  computed: {
    ...mapGetters({
      files: 'app/computedList',
      isPlaying: 'editor/isPlaying',
      currentTime: 'doc/currentTime',
      totalTime: 'editor/totalTime',
      videoReady: 'video/sourcesReady',
      audioReady: 'editor/sourcesReady',
      user: 'app/user',
      docEndedAt: 'editor/docEndedAt',
      docProgress: 'editor/docProgress'
    }),
    vpRatio: function() {
      return this.width / this.height
    },
    mediaReady: function() {
      return this.loading !== 'true' && this.audioReady && this.videoReady
    },
    progressOutOf100: {
      get() {
        const max = Math.min(this.totalTime, this.to)
        const min = Math.max(this.from, 0)
        const range = max - min
        if (range) {
          if (this.currentTime >= max) return 100
          return ((this.currentTime - min) / range) * 100
        }
        return 0
      },
      set(newVal) {
        const max = Math.min(this.totalTime, this.to)
        const min = Math.max(this.from, 0)
        const range = max - min
        if (range) {
          const seekedTime = min + ((range * newVal) / 100)
          this.$emit('goToTime', seekedTime, true)
        }
      }
    },
    getScale: function() {
      return this.width / 1920
    },
    getTotalTime: function() {
      let totalTime = 0
      /*
        The first two if conditions are true if the clipper
        is in step 2. if it's not, it's either step 3 or no clip is
        selected in step 2.
      */
      if (this.from !== 0) {
        totalTime = this.to - this.from
      } else if (this.from === 0 && this.to < this.totalTime) {
        totalTime = this.to
      } else {
        totalTime = this.totalTime
      }
      const timeString = secondsToHHMMSS(totalTime, true)
      if (timeString.substring(0, 2) === '00') { // checks if the first two units (hours) are 0
        return timeString.substring(3)
      }
      return timeString
    },
    getCurrentTime: function() {
      let currentTime = 0
      // Only in step 2 of clipper from time can be more than 0
      currentTime = this.currentTime - this.from
      if (currentTime < 0) currentTime = 0
      const timeString = secondsToHHMMSS(currentTime)
      if (timeString.substring(0, 2) === '00') { // checks if the first two units (hours) are 0
        return timeString.substring(3)
      }
      return timeString
    }
  },
  methods: {
    ...mapActions({
      toggleState: 'editor/toggleState'
    }),
    togglePlay: function() {
      if (this.customClickAction) this.$emit('onTogglePlay')
      else this.toggleState(true)
    },
    goToTime: function(time) {
      this.$emit('goToTime', time)
    },
    syncSubtitle: function() {
      if (myProseEditor) this.subtitle = formatAsSubtitleNew(myProseEditor.view.state)
    },
    getWindowWidth: function() {
      this.height = this.$refs.previewPlayer.clientHeight
      this.width = this.$refs.previewPlayer.clientWidth
    }
  },
  watch: {
    showInteractionOnHover: function(newVal) {
      console.log('showInteractionOnHover', this.isReady, newVal)
    },
    loading: function(val, oldVal) {
      if (oldVal === 'true' && val === 'false') this.syncSubtitle()
    },
    mediaReady: function(val, oldVal) {
      if (!val && oldVal) this.isReady = false
      if (!oldVal && val) {
        setTimeout(() => {
          this.isReady = true
          this.$emit('onPlayerReady')
        }, 2000)
      }
    },
    currentTime: function(val) {
      if (val >= this.to) {
        if (this.isPlaying) this.toggleState(true)
        this.$emit('onPreviewEnd')
      }
    },
    docEndedAt: function() {
      this.goToTime(0)
    },
    showplayer: function(val) {
      if (val) this.getWindowWidth()
    }
  },
  mounted() {
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.getWindowWidth)
    store.commit('video/setSourcesReady', true)
    store.commit('editor/setSourcesReady', false)
  }
}
</script>

<style lang="scss" scoped>
.loadingOverlay {
  background: rgba(229, 231, 235, 0.8);
  backdrop-filter: blur(7px);
}

.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 0, 0, 0.61);
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top:2px;
  left: 7px;
  background-color: #E2462C;
  transform: translateX(-50%);
  transition: left .3s;
  box-sizing: border-box;

  &:hover, &:active {
    width: 12px;
    height: 12px;
    top: 1px;
    border-width: 2px;
  }
}

// .custom-dot::before {
//   content: '';
//   display: inline-block;
//   width: 6px;
//   height: 6px;
//   border-radius: 50%;
//   background: #E2462C;
// }

.clip-title-bg {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.7861738445378151) 61%, rgba(255,255,255,0) 100%);
}

.preview-bar-bg {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7861738445378151) 61%, rgba(255,255,255,0) 100%);
}

.text-xxs {
  font-size: 10px;
}
</style>
