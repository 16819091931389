<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
    :class="classname"
  >
    <path d="M13.3971 18.1875L12.1371 14.5618H4.47429L3.21429 18.1875H0L6.53143 0.1875H10.08L16.5857 18.1875H13.3971ZM5.45143 11.7846H11.16L8.30571 3.65893L5.45143 11.7846Z" />
  </svg>
</template>

<script>
export default {
  name: 'A',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
