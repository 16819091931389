<template>
  <div class="flex">
    <div
      class="flex flex-col items-center mr-6 cursor-pointer group asp"
      @click="onChange('landscape')"
    >
      <div
        class="relative flex flex-col items-center justify-center rounded-lg landscape group-hover:ring-primary group-hover:ring-1"
        :class="[aspectRatio === 'landscape' ? 'activeBg ring-primary ring-1' : 'inactiveBg']"
      >
        <div
          class="group-hover:block line1"
          :class="[aspectRatio === 'landscape' ? 'block' : 'hidden']"
        />
        <div
          class="group-hover:block line2"
          :class="[aspectRatio === 'landscape' ? 'block' : 'hidden']"
        />
        <div class="text-xs text-primary">1920 x 1080</div>
        <div class="mt-1 text-xs text-primary-600">16:9</div>
      </div>

      <div class="font-h font-semibold text-primary mt-2.5">
        Landscape
      </div>
    </div>

    <div
      class="flex flex-col items-center mr-6 cursor-pointer group asp"
      @click="onChange('square')"
    >
      <div
        class="relative flex flex-col items-center justify-center rounded-lg square group-hover:ring-primary group-hover:ring-1"
        :class="[aspectRatio === 'square' ? 'activeBg ring-primary ring-1' : 'inactiveBg']"
      >
        <div
          class="group-hover:block line1"
          :class="[aspectRatio === 'square' ? 'block' : 'hidden']"
        />
        <div
          class="group-hover:block line2"
          :class="[aspectRatio === 'square' ? 'block' : 'hidden']"
        />
        <div class="text-xs text-primary">1080 x 1080</div>
        <div class="mt-1 text-xs text-primary-600">1:1</div>
      </div>

      <div class="font-h font-semibold text-primary mt-2.5">
        Square
      </div>
    </div>

    <div
      class="flex flex-col items-center mr-6 cursor-pointer group asp"
      @click="onChange('portrait')"
    >
      <div
        class="relative flex flex-col items-center justify-center rounded-lg portrait group-hover:ring-primary group-hover:ring-1"
        :class="[aspectRatio === 'portrait' ? 'activeBg ring-primary ring-1' : 'inactiveBg']"
      >
        <div
          class="group-hover:block line1"
          :class="[aspectRatio === 'portrait' ? 'block' : 'hidden']"
        />
        <div
          class="group-hover:block line2"
          :class="[aspectRatio === 'portrait' ? 'block' : 'hidden']"
        />
        <div class="text-xs text-primary">1080 x 1920</div>
        <div class="mt-1 text-xs text-primary-600">9:16</div>
      </div>

      <div class="font-h font-semibold text-primary mt-2.5">
        Portrait
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AspectRatio',
  props: {
    aspectRatio: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange: function(val) {
      this.$emit('onChange', val)
    }
  }
}
</script>

<style scoped lang="scss">
.landscape {
  height: 145px;
  width: 258px;

  .line1 {
    position: absolute;
    width: 112.65px;
    height: 0px;
    left: 148px;
    top: 111px;
    border: 1px dashed #949BA8;
    transform: rotate(-151.26deg);
  }

  .line2 {
    position: absolute;
    width: 100px;
    height: 0px;
    left: 0px;
    top: 28px;
    border: 1px dashed #949BA8;
    transform: rotate(-151.26deg);
  }
}

.square {
  height: 145px;
  width: 145px;

  .line1 {
    position: absolute;
    width: 75px;
    height: 0px;
    left: 76px;
    top: 114px;
    border: 1px dashed #949BA8;
    transform: rotate(-136.26deg);
  }

  .line2 {
    position: absolute;
    width: 68px;
    height: 0px;
    left: -5px;
    top: 28px;
    border: 1px dashed #949BA8;
    transform: rotate(-136.26deg);
  }
}

.portrait {
  height: 145px;
  width: 88px;

  .line1 {
    position: absolute;
    width: 56px;
    height: 0px;
    left: 42px;
    top: 116px;
    border: 1px dashed #949BA8;
    transform: rotate(-122.26deg);
  }

  .line2 {
    position: absolute;
    width: 54px;
    height: 0px;
    left: -8px;
    top: 26px;
    border: 1px dashed #949BA8;
    transform: rotate(-122.26deg);
  }
}

.inactiveBg {
  background: linear-gradient(0deg, rgba(229, 231, 235, 0.95), rgba(229, 231, 235, 0.95)), url('./bg.png');
  background-position: center;
  background-size: cover;

  * {
    color: #949BA8 !important;
  }
}

.asp:hover .inactiveBg{
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url('./bg.png');
  background-position: center;
  background-size: cover;
}

.activeBg {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url('./bg.png');
  background-position: center;
  background-size: cover;
}
</style>
