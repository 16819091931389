<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6902 3H13.9758C15.0596 2.99999 15.9338 2.99998 16.6416 3.05782C17.3705 3.11737 18.0107 3.24318 18.603 3.54497C19.5438 4.02433 20.3087 4.78924 20.788 5.73005C21.0898 6.32234 21.2156 6.96253 21.2752 7.69138C21.333 8.39925 21.333 9.27339 21.333 10.3572V13.6428C21.333 14.7266 21.333 15.6008 21.2752 16.3086C21.2156 17.0375 21.0898 17.6777 20.788 18.27C20.3087 19.2108 19.5438 19.9757 18.603 20.455C18.0107 20.7568 17.3705 20.8826 16.6416 20.9422C15.9338 21 15.0596 21 13.9758 21H10.6902C9.6064 21 8.73226 21 8.02438 20.9422C7.29553 20.8826 6.65535 20.7568 6.06306 20.455C5.12225 19.9757 4.35734 19.2108 3.87798 18.27C3.57619 17.6777 3.45038 17.0375 3.39083 16.3086C3.33299 15.6007 3.333 14.7266 3.33301 13.6428V10.3572C3.333 9.27341 3.33299 8.39926 3.39083 7.69138C3.45038 6.96253 3.57619 6.32234 3.87798 5.73005C4.35734 4.78924 5.12225 4.02433 6.06306 3.54497C6.65535 3.24318 7.29554 3.11737 8.02438 3.05782C8.73226 2.99998 9.60642 2.99999 10.6902 3ZM8.18725 5.05118C7.58208 5.10062 7.23439 5.19279 6.97104 5.32698C6.40655 5.6146 5.94761 6.07354 5.65999 6.63803C5.5258 6.90138 5.43363 7.24907 5.38418 7.85424C5.33379 8.47108 5.33301 9.26339 5.33301 10.4V13.6C5.33301 14.7366 5.33379 15.5289 5.38418 16.1458C5.43363 16.7509 5.5258 17.0986 5.65999 17.362C5.94761 17.9265 6.40655 18.3854 6.97104 18.673C7.23439 18.8072 7.58208 18.8994 8.18725 18.9488C8.80409 18.9992 9.5964 19 10.733 19H13.933C15.0696 19 15.8619 18.9992 16.4788 18.9488C17.0839 18.8994 17.4316 18.8072 17.695 18.673C18.2595 18.3854 18.7184 17.9265 19.006 17.362C19.1402 17.0986 19.2324 16.7509 19.2818 16.1458C19.3322 15.5289 19.333 14.7366 19.333 13.6V10.4C19.333 9.26339 19.3322 8.47108 19.2818 7.85424C19.2324 7.24907 19.1402 6.90138 19.006 6.63803C18.7184 6.07354 18.2595 5.6146 17.695 5.32698C17.4316 5.19279 17.0839 5.10062 16.4788 5.05118C15.8619 5.00078 15.0696 5 13.933 5H10.733C9.5964 5 8.80409 5.00078 8.18725 5.05118Z" />
  </svg>
</template>

<script>
export default {
  name: 'CheckboxEmpty',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
