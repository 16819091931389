<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classname"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5ZM7.7669 8.36561C8.24496 8.64215 8.85668 8.47879 9.13323 8.00073C10.0902 6.6154 11.9401 8.46 10.4234 9.36165C10.1508 9.52371 9.79427 9.79348 9.5231 10.1239C9.26633 10.4368 9.03051 10.8672 9.00278 11.396C8.99925 11.4302 8.99744 11.4649 8.99744 11.5C8.99744 12.0523 9.44515 12.5 9.99744 12.5C10.7597 12.5 10.9048 12.095 11.0512 11.6864C11.1454 11.4235 11.2401 11.1591 11.5001 11C12.3267 10.494 13 9.59773 13.0001 8.5C13.0001 6.84315 11.6569 5.5 10.0001 5.5C8.88838 5.5 7.91922 6.10518 7.40202 6.99927C7.12547 7.47733 7.28884 8.08906 7.7669 8.36561ZM8.99744 14.5C8.99744 13.9477 9.44515 13.5 9.99744 13.5C10.5497 13.5 10.9974 13.9477 10.9974 14.5C10.9974 15.0523 10.5497 15.5 9.99744 15.5C9.44515 15.5 8.99744 15.0523 8.99744 14.5Z"
      fill="#E2462C"
    />
  </svg>
</template>
<script>
export default {
  name: 'QuestionMarkFilled',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
