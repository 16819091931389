<template>
  <div class="bg-primary-100 p-4 rounded-1 mb-2 last:mb-0">
    <div class="mb-4 font-h font-semibold text-lg">Clip {{index + 1}}: {{title}}</div>

    <SubtitleEditor
      v-for="(sub, i) in subtitle"
      :key="i"
      :index="i"
      :start="sub.start"
      :end="sub.end"
      :text="sub.text"
      @onChange="updateSubtitleForClip"
      @focus="$emit('focus')"
    />
  </div>
</template>

<script>
import SubtitleEditor from './SubtitleEditor.vue'

export default {
  name: 'TranscriptEditor',
  props: {
    index: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: 'Default clip name'
    },
    subtitle: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    SubtitleEditor
  },
  methods: {
    updateSubtitleForClip: function({ index, update }) {
      let newSubtitle = this.subtitle.map(({ start, end, text }) => ({ start, end, text }))
      newSubtitle[index] = update
      this.$emit('onChange', {
        index: this.index,
        subtitle: newSubtitle
      })
    }
  }
}
</script>

<style>

</style>
