<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <main class="flex flex-1 h-screen clipEditor">
    <div
      v-if="debug && clips.length"
      class="absolute z-50 w-2/5 h-40 overflow-auto rounded-lg shadow-lg bg-primary-300 text-primary bottom-4 left-4"
    >
      <div
        v-for="(c, i) in clips"
        :key="c.uid"
        class="mb-1 text-xs"
      >
        <span class="font-h font-semibold">Clip {{i+1}}:</span>
        <a
          target="_blank"
          :href="`${host}/player/${c.docOwner}/${c.uid}?logs=true`"
          class="text-blue"
        >
          {{`${host}/player/${c.docOwner}/${c.uid}?logs=true`}}
        </a>
      </div>

      <div class="mt-6 font-h text-xs font-semibold">Videoplayer Info</div>
      <pre
        class="text-2xs"
      >
        {{JSON.stringify(videoPlayerInfo, 0, 2)}}
      </pre>
    </div>
    <pre
      v-else-if="debug"
      class="absolute z-50 w-2/5 h-40 overflow-auto rounded-lg shadow-lg bg-primary-300 text-primary bottom-4 left-4"
    >
      {{JSON.stringify(projectInfo, 0, 2)}}
    </pre>
    <Downloads
      v-if="downloads"
      :message="downloads"
      :title="downloadName"
    />
    <Header clipper/>

    <div class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16">
      <section class="relative z-10 flex flex-col h-screen col-span-7 bg-white shadow-lg">
        <div v-if="makingClips" class="absolute left-0 right-0 flex justify-center bottom-16">
          <div v-if="makingClipsForTooLong" class="flex items-center px-3 py-1 rounded-lg bg-primary banner">
            <div class="mr-2">⌛️</div>
            <div class="text-xs leading-6 text-white">
              We’re almost there! Just a little while left.
            </div>
          </div>
          <div v-else class="flex items-center px-3 py-1 rounded-lg bg-primary banner">
            <div class="mr-2">⏱</div>
            <div class="text-xs leading-6 text-white">
              Your exports are on their way! Please wait here until we begin exporting.
            </div>
          </div>
        </div>

        <VToastr ref="toastr" />
        <div
          v-show="loading"
          class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-screen bg-white bg-opacity-80 z-99"
        >
          <div class="px-8 py-2 bg-white rounded-lg shadow-lg">Loading...</div>
        </div>

        <div v-show="!loading" class="relative w-full">
          <div class="relative z-10 flex items-center px-10 pt-10 mb-6 bg-white">
            <img
              v-if="projectMeta.icon"
              :src="projectMeta.icon"
              class="relative w-8 h-8 mr-2"
              :class="{
                '-top-0.5': projectMeta.name === 'Automated Video Clipper'
              }"
            />

            <input
              v-if="isEditingTitle"
              type="text"
              placeholder="Name Your Project"
              class="flex-grow p-0 text-4xl font-semibold border-none outline-none font-h placeholder-primary-300 titleInput focus:ring-0"
              v-model="title"
              v-focus
              @blur="isEditingTitle = false;"
              @keyup.enter="isEditingTitle=false;"
            >
            <div
              v-else
              @click="isEditingTitle = true;"
              class="flex-grow overflow-hidden text-4xl font-semibold font-h titleInput whitespace-nowrap overflow-ellipsis sp-0"
              :class="{'text-primary-300': !title}"
            >
              {{title || 'Name Your Project'}}
            </div>
          </div>

          <div
            class="px-10 overflow-y-auto"
            :style="{height: 'calc(100vh - 104px)'}"
          >
            <!-- step 1 -->
            <div
              class="relative flex"
              v-show="debug ? true : (step !== 4 && (minimized || step <= 2))"
            >
              <div
                class="flex flex-col mr-2.5 pt-3 transition-all w-4 duration-500 items-center"
                @click="goToStep(1)"
                :class="[enabledSteps.includes(1) ? 'cursor-pointer' : 'cursor-not-allowed']"
              >
                <StepIcon
                  class="transition-all duration-500 ease-in-out"
                  :class="[!minimized && step === 1 ? 'h-4 text-primary w-4' : 'h-3 w-3 inactiveStepIcon text-primary-500']"
                />
              </div>

              <div class="relative w-full pb-6">
                <div class="bg-primary-400 top-8 -bottom-1.5 -left-4.5 w-0.25 absolute"/>
                <div class="border border-white border-dashed top-8 -bottom-1.5 -left-4.5 absolute"/>

                <div
                  class="font-semibold font-h"
                  :class="[!minimized && step === 1 ? 'text-primary px-2 pt-1.5 pb-3.5 text-1.5xl' : 'p-2 text-primary-500 text-base', enabledSteps.includes(1) ? 'cursor-pointer' : 'cursor-not-allowed']"
                >
                  <span @click="goToStep(1)">1. Select from drive or</span>
                  <span
                    v-if="selectedFile && selectedDocProgress < 100"
                    class="text-primary-500"
                    @click="goToStep(1)"
                  >
                    upload a file
                  </span>
                  <span
                    v-else-if="step===1"
                    class="underline cursor-pointer text-blue"
                    @click="showUploadModal"
                  >
                    upload a file
                  </span>
                  <span v-else @click="goToStep(1)">
                    upload a file
                  </span>
                </div>

                <CollapseTransition :duration="500">
                  <div v-show="!minimized && step===1">
                    <div
                      class="grid grid-cols-3 gap-4"
                      v-if="selectedFile && selectedDocProgress < 100"
                    >
                      <TranscribingFile
                        :title="selectedFile.title || ''"
                        :progress="Math.round(selectedDocProgress)"
                        @onCancel="removeFile()"
                      />
                    </div>

                    <div
                      v-if="files.length && !(selectedFile && selectedDocProgress < 100)"
                      class="overflow-y-auto border rounded border-primary-300 fileList"
                    >
                      <div
                        class="relative flex items-center justify-between px-6 py-4 cursor-pointer group"
                        v-for="item in files"
                        v-if="!(item.code in fileErrorCodes)"
                        :key="item.key"
                        :id="item.key"
                        @click="selectFile(item)"
                      >
                        <div class="flex items-center w-custom-title-cont relative">
                          <BrandIcon
                            class="mr-3 h-7 w-7"
                            :class="[selectedFile && selectedFile.key === item.key ? 'text-red' : 'text-primary-500 group-hover:text-red']"
                          />
                          <div
                            class="text-sm font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap mw-title-custom"
                            :class="[selectedFile && selectedFile.key === item.key ? 'text-red' : 'text-primary group-hover:text-red']"
                          >
                            {{item.title}}
                          </div>
                        </div>

                        <div
                          :class="[selectedFile && selectedFile.key === item.key ? 'block text-green' : 'hidden group-hover:block group-hover:text-primary-400 cursor-pointer']"
                        >
                          <i
                            class="text-xl far fa-check-circle"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CollapseTransition>

              </div>
            </div>

            <!-- step 2 -->
            <div
              class="relative flex"
              v-show="debug ? true : (step !== 4 && (minimized || step <= 3))"
            >
              <div
                class="flex flex-col mr-2.5 pt-3 transition ease-in-out w-4 duration-500 items-center"
                :class="[enabledSteps.includes(2) ? 'cursor-pointer' : 'cursor-not-allowed']"
                @click="goToStep(2)"
              >
                <StepIcon
                  class="transition duration-500 ease-in-out"
                  :class="[!minimized && step === 2 ? 'h-4 text-primary w-4' : 'h-3 w-3 inactiveStepIcon text-primary-500']"
                />
              </div>

              <div class="relative w-full pb-6">
                <div class="bg-primary-400 top-8 -bottom-1.5 -left-4.5 w-0.25 absolute"/>
                <div class="border border-white border-dashed top-8 -bottom-1.5 -left-4.5 absolute"/>
                <div
                  v-if="!isManualClipper"
                  class="flex items-center font-semibold font-h"
                  :class="[!minimized && step === 2 ? 'text-primary px-2 pt-1.5 pb-3.5 text-1.5xl' : 'p-2 text-primary-500 text-base', enabledSteps.includes(2) ? 'cursor-pointer' : 'cursor-not-allowed']"
                >
                  <span @click="goToStep(2)">
                    {{form ? '2. Add your timestamps' : '2. Paste your notes/ timestamps'}}
                  </span>

                  <span v-show="!minimized && step===2" class="ml-2 text-sm text-primary-500">
                    or
                    <span class="text-blue" @click="setForm(!form)">
                      {{form ? 'paste your notes/timestamps' : 'add them using this form'}}
                    </span>
                  </span>

                  <CircularLoader v-if="step===2 && !isDocReady && !minimized" class="w-5 h-4 ml-2 text-red"/>

                </div>
                <div
                  v-else
                  class="relative flex justify-between font-semibold font-h"
                  :class="[!minimized && step === 2 ? 'text-primary px-2 pt-1.5 text-1.5xl' : 'p-2 text-primary-500 text-base']"
                >
                  <div class="relative flex">
                    <span @click="goToStep(2)" class="cursor-pointer">
                      2. Select text to create clips
                    </span>
                    <CircularLoader  v-if="step===2 && !minimized && !isDocReady" class="relative w-5 h-4 ml-2 text-red top-1"/>

                    <div
                      v-if="!minimized && isDocReady && step===2 && isManualClipper"   class="relative"
                    >
                      <div
                        v-if="!searchOpen"
                        class="rounded-lg cursor-pointer flex h-10 ml-2 -top-1.5 text-primary-500 w-10 relative items-center justify-center hover:bg-primary-200 hover:text-primary-600"
                        @click="openSearch"
                      >
                        <SearchIcon class="w-6 h-6 m-0"/>
                      </div>
                      <div
                        class="ml-3 -top-1.5 relative searchBox"
                        :class="{'active': searchOpen}"
                      >
                        <input
                          v-show="searchOpen"
                          type="text"
                          ref="searchInput"
                          v-model="searchterm"
                          @keyup.enter="research()"
                          class="border rounded-lg font-ui font-normal border-primary-300 text-xs w-full py-2.75 pl-3 text-primary-800 block focus:border-primary focus:ring-primary"
                          placeholder="Search inside media"
                          :class="[searchterm ? 'pr-28' : 'pr-2']"
                          @blur="onSearchBlur"
                        />
                        <div
                          v-show="searchOpen && !searchterm"
                          class="flex mt-2 inset-y-0 right-2.5 text-primary-500 absolute"
                        >
                          <SearchIcon class="w-6 h-6 m-0"/>
                        </div>

                        <div
                          v-show="searchOpen && searchterm"
                          class="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-primary-500 hover:text-primary"
                          @click="resetSearch(1)"
                        >
                          <CloseIcon classname="h-3.5 w-3.5 transform rotate-180" />
                        </div>
                        <div
                          v-show="searchOpen && searchterm"
                          class="absolute inset-y-0 flex items-center pr-2 cursor-pointer right-5 text-primary-500 hover:text-primary"
                          @click="navigateSearch(1)"
                        >
                          <ChevronDown classname="h-3 w-3" />
                        </div>
                        <div
                          v-show="searchOpen && searchterm"
                          class="absolute inset-y-0 flex items-center pr-2 cursor-pointer right-10 text-primary-500 hover:text-primary"
                          @click="navigateSearch(-1)"
                        >
                          <ChevronDown classname="h-3 w-3 transform rotate-180" />
                        </div>

                        <div v-show="searchOpen && searchterm" class="rounded flex bg-primary-300 my-2.5 mr-1 inset-y-0 right-16 w-0.5 absolute"/>

                        <div
                          v-show="searchOpen && searchterm"
                          class="flex mt-3.25 text-xs pr-1.5 inset-y-0 right-18 text-primary-500 absolute"
                        >
                          {{searchResults.length ? (searchResultIndex + 1) : 0}}/{{searchResults.length}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="relative flex items-center -top-2"
                    v-show="!minimized && step===2 && isManualClipper"
                  >

                    <Button
                      :variant="isTimestampError ? 'disabled' : 'primary'"
                      :disabled="isTimestampError"
                      @click="confirmNotes()"
                      class="ml-2"
                    >
                      Next
                    </Button>
                  </div>
                </div>

                <CollapseTransition :duration="500">
                <div
                  id="manualClipperDoc"
                  v-show="debug || (!minimized && step===2 && isManualClipper)"
                >
                  <DocPreview
                    v-if="showPreviewPlayer"
                    :key="selectedFile.key"
                    :loading="previewLoading"
                    :fileKey="selectedFile.key"
                    :fileOwner="selectedFile.owner"
                    :height="'320px'"
                    :disableAutoScroll="true"
                    @onLoadingChange="onLoadingChange"
                    @goToTime="goToTime"
                    @addClip="createManualClipTimestamp"
                    @documentInitialised="documentInitialised"
                    ref="clipDoc"
                  />
                </div>
                </CollapseTransition>
                <CollapseTransition :duration="500">
                <div v-show="!minimized && step===2 && !isManualClipper">
                  <div v-if="notes && isTimestampError && showTimestampError" class="bg-red rounded text-white text-sm text-center mb-2 py-1.5 px-2">
                    Hmm, seems like we aren’t able to process the notes. <span v-if="!form">Try using <span @click="setForm(true)" class="underline cursor-pointer">this form</span> to fix the errors.</span><span v-else>Please try again.</span>
                  </div>

                  <div class="relative border rounded border-primary-300 notesInputContainer">
                    <textarea
                      ref="noteInput"
                      autofocus
                      v-if="!form"
                      v-model="notes"
                      @keyup="updateTimestamps()"
                      maxlength="10000"
                      placeholder="Example:

00:00:00 Introduction to Spext

00:00:20 Video Clipper and it's features

00:00:59 Automatic Clipping

00:01:54 Using the Brand Kit"
                      class="relative py-4 pl-6 pr-4 text-sm border-none rounded ring-primary text-primary placeholder-primary-400 focus:ring-1"
                    />

                    <div v-else class="h-full">
                      <TimestampForm
                        :hasError="showTimestampError"
                        :notes="rawNotes"
                        :timestamps="timestamps"
                        @updateRawNotes="updateRawNotes"
                        :hideError="!showNoteErrors"
                        :totalTime="totalTime"
                      />
                    </div>

                    <Button
                      class="absolute right-4 bottom-4"
                      :variant="showNoteErrors ? 'disabled' : 'primary'"
                      @click="checkNoteErrors()"
                    >
                      Next
                    </Button>
                  </div>
                </div>
                </CollapseTransition>
              </div>
            </div>

            <!-- step 3 -->
            <div class="relative flex">
              <div
                class="cursor-pointer flex flex-col mr-2.5 pt-3 transition w-4 duration-500 items-center"
                :class="[enabledSteps.includes(3) ? 'cursor-pointer' : 'cursor-not-allowed']"
                @click="goToStep(3)"
              >
                <StepIcon
                  class="transition duration-500 ease-in-out"
                  :class="[!minimized && step === 3 ? 'h-4 text-primary w-4' : 'h-3 w-3 inactiveStepIcon text-primary-500']"
                />
              </div>
              <div class="relative w-full pb-6">
                <div class="bg-primary-400 top-8 -bottom-1.5 -left-4.5 w-0.25 absolute"/>
                <div class="border border-white border-dashed top-8 -bottom-1.5 -left-4.5 absolute"/>
                <div class="flex items-center justify-between" v-if="step === 4">
                  <div
                    class="flex p-2 text-base font-semibold cursor-default font-h text-primary-500"
                    v-tooltip.right="'Can’t go back now unfortunately 😅'"
                  >
                    <span>
                      3. Apply Brand Kit
                    </span>
                    <LockIcon
                      class="ml-2"
                    />
                  </div>
                </div>
                <div v-else class="flex items-center justify-between">
                  <div
                    class="font-semibold font-h"
                    :class="[!minimized && step === 3 ? 'text-primary px-2 pt-1.5 pb-3.5 text-1.5xl' : 'p-2 text-primary-500 text-base', enabledSteps.includes(3) ? 'cursor-pointer' : 'cursor-not-allowed']"
                  >
                    <span @click="goToStep(3)">
                      3. Apply Brand Kit (optional)
                    </span>
                    <VueSwitch
                      v-if="!minimized && step===3"
                      class="ml-2"
                      v-model="useBrandkit"
                      hightlight
                    />
                  </div>

                  <Button
                    v-if="!minimized && step===3"
                    @click="confirmMakeClips()"
                    v-tooltip.bottom="'Tip: Preview the clips before exporting.<br/>This step will be locked later 😲'"
                    :disabled="!previewTimestamps.length"
                    :variant="previewTimestamps.length ? 'primary' : 'disabled'"
                  >
                    Next
                  </Button>
                </div>

                <CollapseTransition :duration="500">
                  <div v-show="!minimized && step===3" class="pt-2">
                    <BrandKitWrapper
                      :disabled="!useBrandkit"
                      @onChange="copyBrandkit"
                      @showToast="showToast"
                    />
                  </div>
                </CollapseTransition>
              </div>
            </div>

            <!-- step 4 -->
            <div class="relative flex">
              <div :class="['flex flex-col mr-2.5 pt-3 w-4 items-center cursor-pointer', enabledSteps.includes(4) ? 'cursor-pointer' : 'cursor-not-allowed']" @click="goToStep(4)">
                <StepIcon
                  :class="[!minimized && step === 4 ? 'h-4 text-primary w-4' : 'h-3 w-3 inactiveStepIcon text-primary-500']"
                />
              </div>
              <div class="w-full pb-6">
                <div
                  class="relative flex justify-between font-semibold font-h"
                  :class="[!minimized && step === 4 ? 'text-primary px-2 pt-1.5 pb-3.5 text-1.5xl' : 'p-2 text-primary-500 text-base', enabledSteps.includes(4) ? 'cursor-pointer' : 'cursor-not-allowed']"
                >
                  <span @click="goToStep(4)">
                    4. Export Clips
                  </span>
                  <div v-show="!minimized && step === 4" class="relative -top-1">
                    <Button
                      v-if="selectedClips.length"
                      @click="onDownloadClicked(selectedClips, ['mp3', 'mp4'])"
                    >
                      Download Selected
                    </Button>
                    <Button
                      v-else
                      @click="downloadAll"
                      :variant="selectedClipsForDownloadAll.length ? 'primary' : 'disabled'"
                      :disabled="!selectedClipsForDownloadAll.length"
                    >
                      Download All
                    </Button>
                  </div>
                </div>

                <CollapseTransition :duration="500">
                <div v-if="!minimized && step===4" class="grid grid-cols-3 gap-4 pb-48">
                  <ExportClip
                    v-for="el in (makingClips ? timestamps : clips)"
                    :key="makingClips ? el.title : el.uid"
                    :clip="el"
                    :working="makingClips"
                    :selected="selectedClips.some(t => t.uid === el.uid)"
                    :isPlaying="exportClip && exportClip.uid === el.uid && exportClip.playing"
                    @onSelect="selectClip"
                    @onSelectForDownloadAll="selectClipForDownloadAll"
                    @onTogglePlay="playExportClip"
                    @onShare="onShare"
                    ref="exportClips"
                  />
                </div>
                </CollapseTransition>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="relative flex flex-col h-screen col-span-3 overflow-hidden bg-white border-l border-primary-300"
      >
        <div class="relative thumbnail group">
          <HTMLVideoPlayer
            v-if="exportClip"
            :key="exportClip.uid"
            :path="exportClip.url"
            :title="exportClip.title"
            ref="exportPlayer"
            @onPlayToggle="onExportPlayToggled"
          />
          <PreviewPlayer
            v-if="showPreviewPlayer"
            v-show="[1, 2, 3].includes(step)"
            ref="previewPlayer"
            id="previewPlayer"
            :debug="debug"
            :key="selectedFile.key"
            :fileKey="selectedFile.key"
            :fileOwner="selectedFile.owner"
            :from="step === 2 && previewClip ? previewClip.start : 0"
            :to="getEndTimeValue"
            :subtitleStyle="useBrandkit && brandkit && enableSubtitleFormatting ? (brandkit.subtitleStyle || {}) : null"
            :loading="previewLoading"
            :showSeeker="true"
            :customClickAction="step===3 || (isManualClipper && step === 2)"
            :showVideoPlayer="true"
            :disableInteraction="hidePlayerInteraction"
            :title="previewClip? previewClip.title : ''"
            @onPlayerReady="onPlayerReady"
            @onTogglePlay="resumePreview"
            @onPreviewEnd="onPreviewEnd"
            @goToTime="goToTime"
          />
          <div v-if="(!showPreviewPlayer && step===1) || (!exportClip && step===4)">
            <img
              src="/images/projects/preview-window.svg"
              alt="preview-placeholder"
              class="w-full"
            >
          </div>
        </div>

        <div v-if="step===1">
          <img
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>

        <div v-if="step===2">
          <div
            v-if="timestampsWithoutError.length"
            class="relative py-4 pl-4 overflow-hidden rightContent"
          >
            <div class="mb-3 text-lg font-semibold font-h text-primary">Clips</div>

            <div class="pr-4 previewList">
              <PreviewClip
                v-for="el in (isManualClipper ? [...timestampsWithoutError].reverse() : timestampsWithoutError)"
                :class="{'mb-2': true, 'border-primary': previewClip && previewClip.index === el.index}"
                :key="el.key"
                :id="`previewClip-${el.index}`"
                :clip="el"
                :isSelected="previewClip && previewClip.index === el.index"
                :isPlaying="isPlaying && previewClip && previewClip.index === el.index"
                :loading="!playerReady || !isDocReady"
                :totalTime="totalTime || 1000"
                :showDelete="isManualClipper"
                :editable="isManualClipper"
                :showDuration="true"
                :nonBrandkitSource="nonBrandkitSource"
                @onTogglePlay="playClip(el)"
                @onDelete="deleteTimestamp(el.index)"
                @onSelect="selectPreviewClip(el)"
                @onUpdate="createManualClipTimestamp"
                @mouseenter.native="addClipHover"
                @mouseleave.native="removeClipHover"
              />
            </div>
          </div>
          <img
            v-else
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>
        <div
          v-else-if="step===3 && !previewTimestamps.length"
          class="animate-pulse"
        >
          <img
            src="/images/projects/exported-files.svg"
            alt="preview-placeholder"
            class="w-full"
          >
        </div>
        <div
          v-else-if="step===3"
          class="relative py-4 pl-4 overflow-hidden rightContent"
        >
          <div class="mb-3 text-lg font-semibold font-h text-primary">Preview Clips</div>

          <div class="pr-4 previewList">
            <PreviewClip
              v-for="el in (isManualClipper ? [...previewTimestamps].reverse() : previewTimestamps)"
              class="mb-2"
              :key="`${el.title}-${el.index}`"
              :clip="el"
              :isSelected="previewClip && (isManualClipper ? (previewClip.index === el.index) : (previewClip.title === el.title))"
              :isPlaying="isPlaying && previewClip && (isManualClipper ? (previewClip.index === el.index) : (previewClip.title === el.title))"
              :loading="sourcesLoading"
              :nonBrandkitSource="nonBrandkitSource"
              :totalTime="totalTime || 1000"
              :showDelete="false"
              :additionalDuration="additionalDuration"
              :showDuration="true"
              @onTogglePlay="playClip(el)"
              @onSelect="selectPreviewClip(el)"
              @onDelete="deleteTimestamp(el.index)"
            />
          </div>
        </div>

        <div v-else-if="step===4 && selectedClips.length" class="relative p-4 overflow-y-auto rightContent">
          <div class="mb-3 text-lg font-semibold font-h text-primary">Share Clips</div>
          <div
            v-for="cl in selectedClips"
            v-if="cl.exports && cl.exports.mp4 && cl.exports.mp4.publish"
            :key="cl.uid"
            class="flex items-center justify-between max-w-full px-4 py-3 mb-2 border rounded border-primary-300 group"
          >
            <div class="w-8/12">
              <div class="overflow-hidden text-sm font-semibold text-primary overflow-ellipsis whitespace-nowrap">
                {{cl.title}}
              </div>
              <div
                class="mt-1 overflow-hidden text-xs cursor-pointer text-primary-800 overflow-ellipsis whitespace-nowrap group-hover:text-blue"
                @click="onShare(`${pubURL}/video/${cl.exports.mp4.publish}`)"
              >
                {{`${pubURL}/video/${cl.exports.mp4.publish}`}}
              </div>
            </div>

            <div
              class="cursor-pointer text-primary-300 hover:text-primary-700"
              @click="onShare(`${pubURL}/video/${cl.exports.mp4.publish}`)"
            >
              <ShareIcon />
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import 'firebase/auth'
import 'firebase/database'
import * as firebase from 'firebase/app'
import uuidv1 from 'uuid/v1'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import isEqual from 'lodash/isEqual'

import { myProseEditor, focusCursorOnLocation, scrollToLocation, computeTimeAtPos, getUidFromPillElementId, checkForIdInParentNodes, checkForAttrInParentNodes } from '@/view/voiceEditor/proseEditor/util/utility'
import { mapGetters, mapActions } from 'vuex'
import { saveExportedFileMeta, updatePodcast } from '@/services/api/podcast'
import { getProjectInfo, updateProject } from '@/services/api/project'
import { getBrandkitInfoOnce } from '@/services/api/brandkit'

import {
  computeTotalDuration,
  addAudioVideoTime,
  saveDoc,
  createAndCopyDocTime,
  addImageTime,
  addInlineMediaAtEnd,
  createClippedDoc,
  addTextSelection,
  createClippedDocFromLocation,
  createAndCopyDocLocation
} from '@/view/voiceEditor/proseEditor/util/transoformationUtility'

import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import VToastr from 'vue-toastr'
import CONSTANTS, { AUTO_CLIPPER_LIMIT } from '@/constants/CONSTANTS'
import Header from '@/components/Drive/core/Header.vue'
import StepIcon from '@/components/base/icons/StepIcon.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'
import Button from '@/components/base/buttons/Button.vue'
import VueSwitch from '@/components/base/inputs/Switch'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import LockIcon from '@/components/base/icons/Lock.vue'
import SearchIcon from '@/components/base/icons/Search.vue'
import CloseIcon from '@/components/base/icons/Close.vue'
import ShareIcon from '@/components/base/icons/Share.vue'

import BrandKitWrapper from './components/BrandKit.vue'
import PreviewPlayer from './PreviewPlayer'
import ExportClip from './components/ExportClip.vue'
import PreviewClip from './components/PreviewClip.vue'
import TranscribingFile from './components/TranscribingFile.vue'
import Downloads from './components/Downloads.vue'
import HTMLVideoPlayer from './components/HtmlVideoPlayer.vue'
import DocPreview from './PreviewPlayer/DocPreview.vue'

import videoPlayer from '@/components/VideoPlayer/api'
import TimestampForm from './components/TimestampForm.vue'
import { splitLineIntoTimestamps, findAndToggleObj, findAndToggleItem, hhmmssToSeconds } from './utils'
import {
  searchHighlightInDoc,
  removeAllSearchHighlights, // remove all search highlights
  removeAllSelections,
  addCustomHighlight
} from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import { humanLength, copyToClipboard } from '@/utilities/utility'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import { getImageFromPath } from '@/components/VideoPlayer/playerElementsData'
import Tone from 'tone'
import { clipPill } from '@/view/voiceEditor/proseEditor/CustomNodes'

require('@fortawesome/fontawesome-pro/css/all.css')

const getResultsForUI = (list) => list.map(t => ({
  ...t,
  atTime: humanLength(Math.round(t.atTime)),
  key: `${t.location.start}-${t.location.end}`,
  stringWithoutSearchWord: t.resultString.replace(t.searchWord, '')
}))

export default {
  name: 'ClipEditor',
  components: {
    Header,
    StepIcon,
    BrandIcon,
    Button,
    PreviewPlayer,
    ExportClip,
    BrandKitWrapper,
    TranscribingFile,
    VToastr,
    Downloads,
    VueSwitch,
    PreviewClip,
    HTMLVideoPlayer,
    TimestampForm,
    DocPreview,
    CircularLoader,
    CollapseTransition,
    ChevronDown,
    LockIcon,
    SearchIcon,
    CloseIcon,
    ShareIcon
  },
  data: function() {
    return {
      pubURL: CONSTANTS.PUBLISH_URL,
      searchterm: '',
      searchResults: [],
      searchResultIndex: 0,
      avaialbleExportFormats: [
        // 'wav',
        'mp3',
        'mp4'
      ],
      projectInfo: null,
      ownerId: this.$route.params.userId,
      projectId: this.$route.params.projectId,
      loading: true, // for middle column
      totalTime: 0,

      isEditingTitle: false,
      // title: '',

      step: 1,
      enabledSteps: [],
      minimized: true,
      fileErrorCodes: CONSTANTS.ERROR_CODES,

      docState: null,
      docInit: false,

      // step 2
      // notes, timestamps part of project info
      showNoteErrors: false,
      previewLoading: 'false',
      searchOpen: true,

      // step 3
      brandkitVersion: 'v1',
      makingPreview: false,
      makingClips: false,
      makingClipsForTooLong: false,
      clipMakeProgress: 100,
      playerReady: false,
      isDocReady: false,

      // step 3-4
      previewClip: null,

      // step 4
      exportClip: null,
      selectedClips: [],
      exportWithSubtitles: true,
      selectedClipsForDownloadAll: [],
      shareUrl: '',

      downloads: '',
      downloadName: '',
      previewTimestamps: [],
      debug: this.$route.query.debug === 'true',
      introAsources: [],
      outroAsources: [],
      hidePlayerInteraction: false,
      clipBeingEdited: ''
    }
  },
  computed: {
    ...mapGetters({
      allFiles: 'app/computedList',
      isPlaying: 'editor/isPlaying',
      user: 'app/user',
      projectDocId: 'app/projectDocId',
      audioLoading: 'editor/sourcesLoading',
      videoLoading: 'video/sourcesLoading',
      uploadingList: 'app/uploadingList',
      videoReady: 'video/sourcesReady',
      audioReady: 'editor/sourcesReady',
      sourcesReadyMap: 'video/sourcesReadyMap'
    }),
    host: function() {
      return window.location.origin
    },
    isReadyToMakePreviews: function() {
      return (this.playerReady && this.docInit)
    },
    nonBrandkitSource: function() {
      console.log('nonBrandkitSource', this.sourcesReadyMap, {
        introAsources: this.introAsources,
        outroAsources: this.outroAsources
      })
      const sourceIds = Object.keys(this.sourcesReadyMap || {}).filter(el => !this.introAsources.includes(el) && !this.outroAsources.includes(el))
      console.log(this.sourcesReadyMap[sourceIds[0]])
      return sourceIds.length ? this.sourcesReadyMap[sourceIds[0]] : null
    },
    mediaReady: function() {
      return this.loading !== 'true' && this.audioReady && this.videoReady
    },
    selectedAll: function() {
      return this.clips.length && (this.clips.length === this.selectedClips.length)
    },
    files: function() {
      const selectedFile = this.allFiles.find(item => (this.selectedFile && (this.selectedFile.key === item.key)))

      // TODO: undo this after video perf testing
      // return [...(selectedFile ? [selectedFile] : []), ...(this.allFiles || []).filter(t => !t.isClip && (selectedFile ? t.key !== selectedFile.key : true))]
      return [...(selectedFile ? [selectedFile] : []), ...(this.allFiles || []).filter(t => t.isVideo && (selectedFile ? t.key !== selectedFile.key : true))]
    },
    sourcesLoading: function() {
      return this.audioLoading || this.videoLoading
    },
    title: {
      get: function() {
        return this.projectInfo ? this.projectInfo.title || '' : ''
      },
      set: function(title) {
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: { title }
          }
        )
      }
    },
    selectedFile: function() {
      const el = this.projectInfo
      return el && el.doc ? el.doc : null
    },
    showPreviewPlayer: function() {
      return !this.exportClip && this.selectedFile && this.selectedDocProgress === 100 && [1, 2, 3, 4].includes(this.step)
    },
    shadowDoc: function() {
      const el = this.projectInfo
      return el && el.shadowDoc ? el.shadowDoc : null
    },
    selectedDocProgress: function() {
      if (this.selectedFile) {
        const fullDoc = this.files.find(el => el.key === this.selectedFile.key)
        return fullDoc ? fullDoc.doc_progress || 0 : 0
      } else return 0
    },
    form: function() {
      const el = this.projectInfo
      return el && el.form ? el.form : false
    },
    rawNotes: function() {
      const el = this.projectInfo
      return el && Array.isArray(el.notes) && el.notes.length ? el.notes : ['']
    },
    notes: {
      get: function() {
        const el = this.projectInfo
        return el && Array.isArray(el.notes) ? el.notes.join('\n') : ''
      },
      set: function(val) {
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: {
              confirmedNotes: false,
              notes: val.split('\n')
            }
          }
        )
      }
    },
    useBrandkit: {
      get: function() {
        if (this.step === 2 || this.step === 1) return false
        return this.projectInfo ? this.projectInfo.useBrandkit || false : false
      },
      set: async function(val) {
        await updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: {
              useBrandkit: val
            }
          }
        )
        this.copyBrandkit()
      }
    },
    brandkit: function() {
      const el = this.projectInfo
      if (this.step === 2 || this.step === 1 || !this.useBrandkit) return null
      return el && el.brandkit ? el.brandkit : null
    },
    enableSubtitleFormatting: function() {
      return this.brandkit ? this.brandkit.enableSubtitleFormatting || false : false
    },
    projectType: function() {
      const el = this.projectInfo
      return el && el.projectType ? el.projectType : 'Automated Video Clipper'
    },
    projectMeta: function() {
      const meta = CONSTANTS.PROJECT_TYPES.find(el => el.name === this.projectType)
      console.log('projectMeta', meta)
      return meta
    },
    isManualClipper: function() {
      return ['Video Clipper'].includes(this.projectType)
    },
    isTimestampError: function() {
      const el = this.projectInfo
      const timestamps = el && el.timestamps ? el.timestamps : []
      const error = timestamps.some(el => el.error) || !timestamps.length
      return error
    },
    showTimestampError: function() {
      return this.showNoteErrors && this.isTimestampError
    },
    timestamps: function() {
      const el = this.projectInfo
      return el && el.timestamps ? el.timestamps : null
    },
    timestampsWithoutError: function() {
      const { timestamps } = { ...this.projectInfo }
      const errorFreeTimestamps = (timestamps || []).filter(el => !el.error).map(el => ({
        ...el,
        key: `${el.index}-${el.start}-${el.end}-${el.title}`
      }))
      return errorFreeTimestamps
    },
    clips: function() {
      const el = this.projectInfo
      return el && el.clips ? el.clips : []
    },
    additionalDuration: function() {
      let duration = 0
      if (this.brandkit) {
        const { intro, outro } = { ...this.brandkit }
        if (intro && intro.duration) duration += intro.duration
        if (outro && outro.duration) duration += outro.duration
      }

      return duration
    },
    getEndTimeValue: function () {
      if (this.step === 3) return 99999
      if (this.step === 2 && this.previewClip) return this.previewClip.end
      return this.totalTime
    }
  },
  methods: {
    ...mapActions({
      toggleState: 'editor/toggleState',
      resetVideo: 'video/resetVideo',
      openModal: 'dialogs/openModal',
      hideDeleted: 'editor/hideDeleted'
    }),
    onShare: function(url) {
      window.open(url, '_blank')
      copyToClipboard(url)
      this.showToast('Link copied')
    },
    onSearchBlur: function() {
      // if (!this.searchterm) this.searchOpen = false
    },
    openSearch: function() {
      this.searchOpen = true
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 100)
    },
    research: function(searchterm) {
      this.$refs.searchInput.blur()
      const search = searchterm || this.searchterm
      removeAllSearchHighlights()
      this.searchResults = getResultsForUI(searchHighlightInDoc(search))
      this.goToSearchIndex(0)
    },
    debouncedSearch: debounce(function(searchterm) {
      const search = searchterm || this.searchterm
      removeAllSearchHighlights()
      this.searchResults = getResultsForUI(searchHighlightInDoc(search))
      this.goToSearchIndex(0)
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 100)
    }, 750),
    resetSearch: function() {
      removeAllSelections()
      removeAllSearchHighlights()
      this.searchterm = ''
      this.searchResults = []
      this.searchResultIndex = 0
      this.searchOpen = true
    },
    goToSearchIndex: function(index) {
      this.searchResultIndex = index
      const result = this.searchResults[index]
      if (result && result.location) {
        const location = result.location
        removeAllSelections()
        scrollToLocation(location)
        addCustomHighlight(location.start, location.end, 'searchSelection', 'searchSelection')
      }
    },
    navigateSearch: function(val) {
      const resultsLength = this.searchResults.length
      if (val === 1) {
        if (this.searchResultIndex < (resultsLength - 1)) {
          this.goToSearchIndex(this.searchResultIndex + 1)
        }
      } else {
        if (this.searchResultIndex > 0) {
          this.goToSearchIndex(this.searchResultIndex - 1)
        }
      }
    },
    downloadAll: function() {
      this.$refs.exportClips.forEach(el => {
        el.onSelectForDownloadAll(true)
      })

      this.onDownloadClicked(this.selectedClipsForDownloadAll, ['mp3', 'mp4'])
    },
    showToast: function(msg = '', type = 'info', title = '💡') {
      this.$refs.toastr.Add({
        msg,
        type,
        title: type === 'info' ? title : '',
        position: 'toast-bottom-center',
        progressbar: false,
        preventDuplicates: true,
        timeout: 4000,
        classNames: ['animate__animated', 'animate__zoomInUp']
      })
    },
    goToStep: function(step) {
      if (this.enabledSteps.includes(step)) {
        if (this.step !== step) {
          if (step === 1 || step === 2 || !this.useBrandkit) {
            videoPlayer.updateImage({
              src: ''
            })
          }
          this.minimized = false
          if (step === 2) {
            setTimeout(() => {
              this.updateDocState(this.docState)
            }, 500)
          }
          this.step = step
        } else {
          this.minimized = !this.minimized
        }
      } else {
        if (this.step === 1) this.showToast('Please Select a File First')
        if (this.step === 2) {
          if (this.isManualClipper) this.showToast('Please create clips first')
          else this.showToast('Please add valid timestamps to proceed')
        }
      }
    },
    showUploadModal: function() {
      this.$parent.showUploadModal(true)
    },
    selectFile: async function({ owner, key, title }, next = true) {
      this.previewTimestamps = []
      if (next) {
        this.step = 2
        this.enabledSteps = [1, 2]
      } else {
        this.enabledSteps = [1]
      }

      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            title,
            doc: {
              key,
              title,
              owner
            },
            form: false,
            notes: [''],
            timestamps: [],
            confirmedNotes: false,
            useBrandkit: true,
            confirmedClips: false
          }
        }
      )
    },
    removeFile: async function() {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            title: '',
            doc: null
          }
        }
      )
      this.showToast('File is removed from the project, you can find it in the drive.')
    },
    checkNoteErrors: async function() {
      await this.rawUpdateTimestamps()
      const el = this.projectInfo
      const timestamps = el && el.timestamps ? el.timestamps : []
      const error = timestamps.some(el => el.error) || !timestamps.length
      if (error) this.showNoteErrors = true
      else {
        this.showNoteErrors = false
        this.confirmNotes()
      }
    },
    setForm: function(form) {
      updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            form
          }
        }
      )
    },
    updateRawNotes: debounce(async function(notes) {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            confirmedNotes: false,
            notes
          }
        }
      )
      if (notes && notes.length) this.updateTimestamps(notes.join('\n'))
      else this.updateTimestamps('')
    }, 500),
    rawUpdateTimestamps: async function() {
      this.showNoteErrors = false
      const timestamps = []
      const lines = this.notes.split('\n')

      lines.forEach((el, index) => {
        const result = splitLineIntoTimestamps(el)
        if (!result) {
          timestamps.push({
            index,
            value: '',
            start: 0,
            error: 'Invalid timestamp',
            title: ''
          })
        } else {
          let error = ''
          const [startTime, endTime, title] = result
          if (!startTime || !title.trim()) error = 'Incorrect timestamp'

          timestamps.push({
            index,
            value: startTime,
            ...endTime ? {
              endValue: endTime,
              end: hhmmssToSeconds(endTime)
            } : {},
            start: hhmmssToSeconds(startTime),
            error,
            title
          })
        }
      })

      for (let i = 0; i < timestamps.length; i++) {
        const nextEl = timestamps[i + 1]
        let el = timestamps[i]
        const start = el.start
        if (!el.end) {
          const end = nextEl && nextEl.start > el.start ? nextEl.start : ((i === timestamps.length - 1) ? this.totalTime : 0)
          timestamps[i] = {
            ...el,
            end,
            error: end <= start ? 'Incorrect timestamp' : el.error
          }
        } else {
          timestamps[i] = {
            ...el,
            error: el.end <= start ? 'Incorrect timestamp' : el.error
          }
        }
        el = timestamps[i]
        if ((el.start >= this.totalTime) || el.end > this.totalTime) {
          timestamps[i] = {
            ...el,
            error: 'Incorrect timestamp'
          }
        }
        if ((el.end - el.start > AUTO_CLIPPER_LIMIT * 60)) {
          timestamps[i] = {
            ...el,
            error: `Clips must be shorter than ${AUTO_CLIPPER_LIMIT} minutes`
          }
        }
      }

      return updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
    },
    updateTimestamps: debounce(async function(notes) {
      this.showNoteErrors = false
      const timestamps = []
      const lines = (notes || this.notes).split('\n')
      const vm = this
      lines.forEach((el, index) => {
        const result = splitLineIntoTimestamps(el)
        if (!result) {
          timestamps.push({
            index,
            value: '',
            start: 0,
            error: 'Invalid timestamp',
            title: ''
          })
        } else {
          let error = ''
          const [startTime, endTime, title] = result
          if (!startTime || !title.trim()) error = 'Incorrect timestamp'
          timestamps.push({
            index,
            value: startTime,
            ...endTime ? {
              endValue: endTime,
              end: hhmmssToSeconds(endTime)
            } : {},
            start: hhmmssToSeconds(startTime),
            error,
            title
          })
          vm.updateClipTitle(index, title)
        }
      })

      for (let i = 0; i < timestamps.length; i++) {
        const nextEl = timestamps[i + 1]
        let el = timestamps[i]
        const start = el.start
        if (!el.end) {
          const end = nextEl && (nextEl.start > el.start) ? nextEl.start : ((i === timestamps.length - 1) ? this.totalTime : 0)
          timestamps[i] = {
            ...el,
            end,
            error: end <= start ? 'Incorrect timestamp' : el.error
          }
        } else {
          timestamps[i] = {
            ...el,
            error: el.end <= start ? 'Incorrect timestamp' : el.error
          }
        }
        el = timestamps[i]
        if ((el.start >= this.totalTime) || (el.end > this.totalTime)) {
          timestamps[i] = {
            ...el,
            error: 'Incorrect timestamp'
          }
        }
        if ((el.end - el.start > AUTO_CLIPPER_LIMIT * 60)) {
          timestamps[i] = {
            ...el,
            error: `Clips must be shorter than ${AUTO_CLIPPER_LIMIT} minutes`
          }
        }
      }

      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
    }, 400),
    createManualClipTimestamp: async function({ id, ...update }) {
      let timestamps = [...(this.timestamps || [])]
      const indexToUpdate = timestamps.findIndex(el => el.index === id)
      if ((update.end - update.start) > AUTO_CLIPPER_LIMIT * 60) {
        this.$refs.toastr.Add({
          name: 'clip_too_long',
          title: '',
          type: 'info',
          msg: `Clips must be shorter than ${AUTO_CLIPPER_LIMIT} minutes. Please make a shorter selection`,
          clickClose: 'false',
          timeout: 0,
          position: 'toast-bottom-center',
          progressbar: false,
          preventDuplicates: true,
          classNames: ['animate__animated', 'animate__zoomInUp']
        })
      } else {
        this.$refs.toastr.removeByName('clip_too_long')
        if (indexToUpdate === -1) {
          timestamps.push({
            index: id,
            ...update
          })
        } else {
          timestamps = [
            ...timestamps.slice(0, indexToUpdate),
            {
              ...timestamps[indexToUpdate],
              index: id,
              ...update
            },
            ...timestamps.slice(indexToUpdate + 1)
          ]
        }

        await updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: {
              timestamps
            }
          }
        )

        if (this.step === 2) {
          this.selectPreviewClip(timestamps[timestamps.findIndex(el => el.index === id)])
          this.updateClipTitle(id, update.title)
        }
      }
    },
    deleteTimestamp: async function(id) {
      let timestamps = [...(this.timestamps || [])]
      const indexToDelete = timestamps.findIndex(el => el.index === id)
      if (indexToDelete !== -1) {
        timestamps = [
          ...timestamps.slice(0, indexToDelete),
          ...timestamps.slice(indexToDelete + 1)
        ]
      }

      if (this.step === 3) {
        let previewTimestamps = [...(this.previewTimestamps || [])]
        const idToDelete = previewTimestamps.findIndex(el => el.index === id)
        if (idToDelete !== -1) {
          previewTimestamps = [
            ...previewTimestamps.slice(0, idToDelete),
            ...previewTimestamps.slice(idToDelete + 1)
          ]
        }
        this.previewTimestamps = previewTimestamps
      }

      if (this.step === 2) {
        this.clearPreview(true)
        if (this.isManualClipper) {
          this.removeClipHover()
        }
      }

      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            timestamps
          }
        }
      )
    },
    confirmNotes: async function() {
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            confirmedNotes: true
          }
        }
      )
      this.enabledSteps = [1, 2, 3]
      this.goToStep(3)
      this.copyBrandkit()
    },
    copyBrandkit: async function(next = true) {
      if (next) this.makingPreview = true

      const brandkit = await getBrandkitInfoOnce(
        {
          userId: this.$route.params.userId,
          type: this.projectMeta.type,
          version: this.brandkitVersion
        }
      )
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update: {
            brandkit
          }
        }
      )

      if (next) this.makePreviewClips()
    },
    updateDocState: function(state) {
      myProseEditor.view.updateState(state)
      myProseEditor.registerComputeOnDispatchTransaction()
      this.$refs.previewPlayer.syncSubtitle()
    },
    makePreviewClips: async function() {
      if (this.isPlaying) this.toggleState(true)
      this.hidePlayerInteraction = true
      const docState = this.docState
      const { useBrandkit, brandkit } = this.projectInfo
      const { intro = {}, outro = {} } = { ...brandkit }
      if (intro && intro.key) this.introAsources = [...this.introAsources, `${intro.key}#!@${this.user.uid}`]
      if (outro && outro.key) this.outroAsources = [...this.outroAsources, `${outro.key}#!@${this.user.uid}`]
      console.log('Sources ash', this.introAsources, this.outroAsources)
      myProseEditor.view.updateState(docState)
      myProseEditor.registerComputeOnDispatchTransaction()

      this.previewTimestamps = await Promise.all(this.timestamps.map(ts => new Promise(async(resolve) => {
        let clipState, clippedDoc
        if (this.isManualClipper && ts.location) {
          clippedDoc = await createClippedDocFromLocation(docState, ts.location.start, ts.location.end)
        } else {
          clippedDoc = await createClippedDoc(docState, ts.start, ts.end)
        }
        clipState = clippedDoc.state

        if (useBrandkit) {
          // add outro
          if (outro.url) {
            clipState = await addInlineMediaAtEnd(clipState, outro.name, `${outro.key}#!@${this.user.uid}`, outro.duration)
          }

          // add intro
          if (intro.url) {
            clipState = await addAudioVideoTime(clipState, intro.url, intro.name, `${intro.key}#!@${this.user.uid}`, 0, 0, intro.duration)
          }
        }
        const duration = computeTotalDuration(clipState)
        resolve({
          ...ts,
          duration,
          clipState,
          ...useBrandkit && intro.key ? { introAsource: `${intro.key}#!@${this.user.uid}` } : {},
          ...useBrandkit && outro.key ? { outroAsource: `${outro.key}#!@${this.user.uid}` } : {}
        })
      })))

      if (this.step !== 3) {
        const firstClip = this.previewTimestamps[0]
        this.previewClip = firstClip
        this.updateDocState(firstClip.clipState)
      }

      this.makingPreview = false
      if (this.$refs.previewPlayer) {
        this.$refs.previewPlayer.syncSubtitle()
        this.goToTime(0)
      }
    },
    onPlayerReady: function() {
      this.playerReady = true
    },
    selectClip: function(clip, forceSelect = false) {
      this.selectedClips = findAndToggleObj(this.selectedClips, 'uid', clip, forceSelect)
    },
    selectClipForDownloadAll: function(clip, forceSelect = false) {
      this.selectedClipsForDownloadAll = findAndToggleObj(this.selectedClipsForDownloadAll, 'uid', clip, forceSelect)
    },
    playExportClip: function(clip) {
      const isSameClipClicked = this.exportClip && (this.exportClip.uid === clip.uid)
      if (isSameClipClicked) {
        if (this.$refs.exportPlayer) this.$refs.exportPlayer.togglePlay()
      } else this.exportClip = clip
    },
    onExportPlayToggled: function(isPlaying) {
      this.$set(this.exportClip, 'playing', isPlaying)
    },
    onPreviewEnd: function() {
      if (this.previewClip) {
        const clip = this.previewClip
        if (this.isManualClipper && this.step === 2 && clip.location) {
          focusCursorOnLocation(clip.location.start, myProseEditor)
        } else this.goToTime(0)
      }
    },
    clearPreview: function(toggle = true) {
      if (toggle && this.isPlaying) this.toggleState(true)
      this.previewClip = null
      // myProseEditor.removeCustomHighlight()
      if (this.clipBeingEdited) {
        clipPill.deletePillsByUid(myProseEditor.view, this.clipBeingEdited)
        this.clipBeingEdited = ''
      }
    },
    selectPreviewClip: function(clip) {
      const isManualClipper = this.isManualClipper
      const isSameClipClicked = this.previewClip && (isManualClipper || this.step === 2 ? isEqual(this.previewClip.index, clip.index) : isEqual(this.previewClip.title, clip.title))

      if (!isSameClipClicked) {
        if (this.isPlaying) this.toggleState(true)
        this.previewClip = clip
        if (this.step === 3) {
          this.updateDocState(clip.clipState)
          this.goToTime(0)
        }

        if (isManualClipper && this.step === 2 && clip.location) {
          // addTextSelection(this.docState, clip.location)
          this.removeClipHover()
          this.setupClipPills(clip)
        }
      }
    },
    setupClipPills: function(clip) {
      if (this.clipBeingEdited) {
        clipPill.deletePillsByUid(myProseEditor.view, this.clipBeingEdited)
      }
      this.clipBeingEdited = clipPill.createPills(myProseEditor.view, clip.location.start, clip.location.end, clip.index)
      focusCursorOnLocation(clip.location.start + 5, myProseEditor)
    },
    updateClipTitle: function(id, title) {
      if (this.previewClip && this.previewClip.index === id) {
        this.previewClip.title = title
      }
    },
    playClip: function(clip) {
      this.hidePlayerInteraction = false
      const isManualClipper = this.isManualClipper
      const isSameClipClicked = this.previewClip && isEqual(this.previewClip.index, clip.index)
      if (isSameClipClicked) this.toggleState(true)
      else {
        if (this.isPlaying) this.toggleState(true)
        this.previewClip = clip

        if (isManualClipper && this.step === 2 && clip.location) {
          this.removeClipHover()
          this.setupClipPills(clip)
        } else if (clip.start) {
          this.goToTime(clip.start)
        }

        if (this.step === 3) {
          this.updateDocState(clip.clipState)
          this.goToTime(0)
        }

        if (!this.isPlaying) this.toggleState(true)
      }
    },
    goToTime: function(time, updateThumbnail = false) {
      this.$refs.clipDoc.goToTime(time)
      if (updateThumbnail && !this.isPlaying) {
        Tone.Master.mute = true
        this.toggleState(true)
        setTimeout(() => {
          this.toggleState(true)
        }, 500)
      }
    },
    onLoadingChange: function(loading) {
      this.previewLoading = loading
    },
    resumePreview: function() {
      if (this.isManualClipper && this.step === 2) {
        // this.clearPreview(false)
        this.toggleState(true)
      } else if (this.previewClip) this.playClip(this.previewClip)
      else if (this.previewTimestamps.length) this.playClip(this.previewTimestamps[0])
    },
    asyncUpdateClip: async function(uid, update) {
      return new Promise((resolve, reject) => {
        updatePodcast(this.$route.params.userId, uid, update)
          .then(resolve)
          .catch(reject)
      })
    },
    confirmMakeClips: function() {
      const dontAskForClipConfirmation = localStorage.dontAskForClipConfirmation
      const vm = this
      if (dontAskForClipConfirmation) {
        this.makeClips()
      } else {
        this.openModal({
          name: 'ClipperExportMessage',
          props: {
            next: vm.makeClips
          }
        })
      }
    },
    makeClips: async function() {
      this.step = 4
      this.enabledSteps = [4]
      this.makingClips = true // loading state for step 4
      setTimeout(() => {
        this.makingClipsForTooLong = true
      }, 30000)
      // delete old clips for this project if any
      const { clips: currentClips } = this.projectInfo
      const userId = this.$route.params.userId
      const projectId = this.$route.params.projectId
      if (currentClips && currentClips.length) {
        await updateProject(
          {
            userId,
            projectId,
            update: { clips: [] }
          }
        )
      }

      // make new clips & save in project
      const { key, owner } = this.selectedFile
      let mainDocState = this.docState

      const clips = await Promise.all(this.timestamps.map(
        el => this.makeClippedDoc({
          key,
          owner,
          start: el.start,
          end: el.end,
          location: el.location,
          title: el.title,
          mainDocState
        })
      ))

      await Promise.all([
        Promise.all(clips.map((el, i) => this.asyncUpdateClip(el.uid, {
          subtitleStyle: el.subtitleStyle,
          deleted: true,
          updated: Date().toString(),
          isClip: true,
          projectId,
          clipId: el.uid,
          clipIndex: i
        }))), // save subtitle style
        Promise.all(clips.map(el => this.asyncUpdateClip(el.copyDocId, {
          updated: Date().toString(),
          isClip: true
        }))), // fix update for new clips
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: { clips, confirmedClips: true }
          }
        )
      ])
      await this.exportClips()

      this.makingClips = false
    },
    makeClippedDoc: async function({ key, owner, start, end, location, title, mainDocState }) {
      const version = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      return new Promise(async (resolve, reject) => {
        try {
          const { useBrandkit, brandkit } = this.projectInfo
          const {
            intro = {},
            outro = {},
            logo = {},
            logoStyle = {},
            subtitleStyle = {},
            enableSubtitleFormatting
          } = { ...brandkit }
          const clipDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
          const clipCopyDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
          const clipOwnerId = this.user.uid
          const clip = {
            uid: clipDocId,
            owner: clipOwnerId,
            version,
            start,
            end,
            title,
            docId: key,
            docOwner: owner,
            subtitleStyle: useBrandkit && enableSubtitleFormatting ? subtitleStyle : null,
            copyDocId: clipCopyDocId
          }

          // save copy
          let { state: clipStateCopy, latestKey: latestKeyCopy } = await createAndCopyDocTime(mainDocState, start, end, clipCopyDocId, clipOwnerId, title)
          await saveDoc(clipStateCopy, clipOwnerId, clipCopyDocId, firebase, latestKeyCopy)

          // create clip doc,
          let clipState, latestKey, clippedDoc, clipDuration
          if (this.isManualClipper && location) {
            clippedDoc = await createAndCopyDocLocation(mainDocState, location.start, location.end, clipDocId, clipOwnerId, title)
          } else {
            clippedDoc = await createAndCopyDocTime(mainDocState, start, end, clipDocId, clipOwnerId, title)
          }
          clipState = clippedDoc.state
          latestKey = clippedDoc.latestKey

          console.log('project clip state: ', start, end, clipState, clip.uid)

          if (useBrandkit) {
            // add outro
            if (outro.url) {
              clipState = await addInlineMediaAtEnd(clipState, outro.name, `${outro.key}#!@${clipOwnerId}`, outro.duration)
              console.log('project outro', clipState)
            }

            // add intro
            if (intro.url) {
              clipState = await addAudioVideoTime(clipState, intro.url, intro.name, `${intro.key}#!@${clipOwnerId}`, 0, 0, intro.duration)
              console.log('project intro', clipState)
            }

            // add logo
            if (logo.url) {
              clipDuration = computeTotalDuration(clipState)
              clipState = addImageTime(clipState, logo.name, `${logo.key}#!@${clipOwnerId}`, 0, clipDuration, logoStyle)
              console.log('project logo', clipState)
            }
          }

          await saveDoc(clipState, clipOwnerId, clipDocId, firebase, latestKey)

          resolve(clip)
        } catch (error) {
          reject(error)
        }
      })
    },
    exportClips: async function() {
      const { clips } = this.projectInfo
      await Promise.all(clips.map(
        el => this.asyncExportClip(el)
      ))
      this.enabledSteps = [4]
      this.showToast('We’ll email you when the clips are ready. You can close this window for now.', 'info', '🎉')
    },
    asyncExportClip: async function({ uid, owner, version }) {
      return new Promise((resolve, reject) => {
        saveExportedFileMeta(owner, uid, version, 'mp4', {
          status: 'start',
          timestamp: Date.now(),
          startedBy: owner
        },
        resolve,
        reject
        )
      })
    },
    onDownloadClicked: async function(clips, exportTypes) {
      const time = dayjs().format('D MMM, h:mm a')
      const zipFilename = `${this.projectInfo.title || 'project'} - ${time}.zip`
      this.downloadName = zipFilename
      this.downloads = 'Preparing files'

      let filesToDownload = []
      clips.forEach(t => {
        const { exports, title } = t
        exportTypes.forEach(el => {
          if (exports[el] && exports[el].url) {
            filesToDownload.push({
              type: el,
              name: title,
              url: exports[el].url
            })
          }
        })
      })
      this.downloads = `Downloading ${filesToDownload.length} files`
      // make urls
      filesToDownload = await Promise.all(filesToDownload.map(({ type, name, url }) => new Promise(async (resolve, reject) => {
        const fileUrl = await firebase.storage().ref(url).getDownloadURL()
        resolve({
          type,
          name,
          url: fileUrl
        })
      })))
      const urls = filesToDownload.map(t => t.url)
      this.downloads = `Zipping 0 of ${filesToDownload.length} files`
      const vm = this
      const zip = new JSZip()
      let count = 0

      urls.forEach((url, index) => {
        const { name, type } = filesToDownload[index]
        const filename = `${name}.${type}`
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            throw err // or handle the error
          }
          zip.file(filename, data, { binary: true })
          count++
          vm.downloads = `Zipping ${count} of ${filesToDownload.length} files`
          if (count === urls.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              vm.downloads = ''
              saveAs(content, zipFilename)
            })
          }
        })
      })
    },
    onboard: function(projectType) {
      if (projectType === 'Automated Video Clipper') {
        const autoClipperOnboarded = window.localStorage.getItem('autoClipperOnboarded')

        setTimeout(() => {
          this.minimized = false
          if (!autoClipperOnboarded) {
            this.showToast('Create multiple clips based on your notes/time stamps')
            window.localStorage.setItem('autoClipperOnboarded', true)
          }
        }, 300)
      } else if (projectType === 'Video Clipper') {
        const clipperOnboarded = window.localStorage.getItem('clipperOnboarded')

        setTimeout(() => {
          this.minimized = false
          if (!clipperOnboarded) {
            this.showToast('Create clips by selecting portions of the transcript')
            window.localStorage.setItem('clipperOnboarded', true)
          }
        }, 300)
      }
    },
    decideStep: async function({ doc, confirmedNotes, confirmedClips, projectType }) {
      let step = 1
      let enabledSteps = [1]
      // if selected doc go to step 2
      if (doc && doc.key) {
        const fullDoc = this.files.find(el => el.key === doc.key)
        if (fullDoc && fullDoc.doc_progress === 100) {
          enabledSteps = [1, 2]
          step = 2
        } else {
          this.step = 1
          this.enabledSteps = [1]
          this.loading = false
          setTimeout(() => {
            this.minimized = false
          }, 300)
          return
        }
      }
      // if valid timestamps go to step 3
      if (confirmedNotes) {
        step = 3
        enabledSteps = [1, 2, 3]
      }

      // if created preview go to step 4
      if (confirmedClips) {
        enabledSteps = this.debug ? [2, 3] : [4]
        step = 4
      }
      this.step = step
      if (step === 3) this.hidePlayerInteraction = true
      this.enabledSteps = enabledSteps
      this.loading = false
      this.onboard(projectType)
    },
    documentInitialised() {
      this.docState = myProseEditor.view.state
      this.totalTime = computeTotalDuration(this.docState)
      removeAllSearchHighlights()
      this.docInit = true
    },
    keyDownListner: function(e) {
      const watchKeys = [
        32 // Space
      ]

      if ((e.metaKey || e.ctrlKey) && watchKeys.includes(e.keyCode)) {
        e.preventDefault()
      } else if (watchKeys.includes(e.keyCode)) {
        e.preventDefault()
        if (e.keyCode === 32) this.toggleState(true)
      }
    },
    waitForExports: function (e) {
      if (this.makingClips) {
        return 'Don’t leave just yet! You will lose all progress if you exit now. Are you sure you want to leave?'
      }
    },
    addClipHover(e) {
      if (!myProseEditor) return
      let start = 0
      let end = 0
      const id = getUidFromPillElementId(e.target.id)
      const clipHovered = this.timestamps.find(clip => clip.index === id)
      if (this.clipBeingEdited) {
        if (clipHovered.index === this.clipBeingEdited) return
        const clipEdited = this.timestamps.find(clip => clip.index === this.clipBeingEdited)
        if (clipHovered.location.end < clipEdited.location.start) {
          // hoveredClip starts and ends before startpill
          start = clipHovered.location.start
          end = clipHovered.location.end
        } else if (clipHovered.location.start < clipEdited.location.start && clipHovered.location.end >= clipEdited.location.end) {
          // hoveredClip starts before startpill and ends after endpill
          start = clipHovered.location.start
          end = clipHovered.location.end + 2 * clipPill.pillNodeSize
        } else if (clipHovered.location.start < clipEdited.location.start && clipHovered.location.end >= clipEdited.location.start) {
          // hoveredClip starts before startpill and ends before endpill
          start = clipHovered.location.start
          end = clipHovered.location.end + clipPill.pillNodeSize
        } else if (clipHovered.location.start >= clipEdited.location.start && clipHovered.location.end < clipEdited.location.end) {
          // hoveredClip falls between start and end pill
          start = clipHovered.location.start + clipPill.pillNodeSize
          end = clipHovered.location.end + clipPill.pillNodeSize
        } else if (clipHovered.location.start >= clipEdited.location.start && clipHovered.location.end >= clipEdited.location.end) {
          // hoveredClip starts between start and end pill but ends after endpill
          start = clipHovered.location.start + clipPill.pillNodeSize
          end = clipHovered.location.end + (2 * clipPill.pillNodeSize)
        } else if (clipHovered.location.start >= clipEdited.location.end) {
          // hoveredClip is beyond clipBeingEdited
          start = clipHovered.location.start + (2 * clipPill.pillNodeSize)
          end = clipHovered.location.end + (2 * clipPill.pillNodeSize)
        }
      } else {
        start = clipHovered.location.start
        end = clipHovered.location.end
      }
      clipPill.createPillPreviewHover(myProseEditor.view, start, end)
    },
    removeClipHover() {
      if (!myProseEditor) return
      clipPill.removePillPreviewHover(myProseEditor.view)
    }
  },
  created: function() {
    window.projects = true
    window.onbeforeunload = this.waitForExports
    // window.addEventListener('popstate', function(e) {
    //   const leavePage = confirm('Don’t leave just yet! You will lose all progress if you exit now.')
    //   if (leavePage) {
    //     history.back()
    //   } else {
    //     history.pushState(null, document.title, location.href)
    //   }
    // })
  },
  mounted: function() {
    if (window.Intercom) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }
    this.resetVideo()
    this.hideDeleted()
    // get project info once and decide which step to go to
    getProjectInfo(
      {
        userId: this.$route.params.userId,
        projectId: this.$route.params.projectId
      }
    ).once('value', snap => this.decideStep(snap.val()))
    // setup projectInfo listner
    getProjectInfo(
      {
        userId: this.$route.params.userId,
        projectId: this.$route.params.projectId
      }
    ).on('value', snap => {
      this.projectInfo = snap.val()
    })
  },
  watch: {
    searchterm: function(val) {
      this.debouncedSearch(val)
    },
    mediaReady: function(val, oldVal) {
      if (!val && oldVal) this.isDocReady = false
      if (!oldVal && val) {
        this.isDocReady = true
      }
    },
    sourcesLoading: function(sourcesLoading) {
      console.log('sourcesLoading', sourcesLoading)
    },
    projectDocId: function(val) {
      if (val) {
        this.selectFile({
          owner: this.$route.params.userId,
          key: val.uid,
          title: val.title
        }, false)
      }
    },
    selectedDocProgress: function(val, oldVal) {
      if (oldVal && oldVal !== 100 && val === 100) {
        this.step = 2
        this.enabledSteps = [1, 2]
      }
    },
    step: async function(val, oldVal) {
      if (this.isPlaying) this.toggleState(true)
      if (myProseEditor) myProseEditor.removeCustomHighlight()

      // recompute total time if step becomes 2
      if (val === 2) {
        this.previewClip = null
        if (this.$refs.noteInput) this.$refs.noteInput.focus()
        if (this.isManualClipper) document.getElementById('manualClipperDoc').addEventListener('keydown', this.keyDownListner)
        if (this.isManualClipper) {
          window.addEventListener('mousedown', (e) => {
            if (!this.clipBeingEdited) return
            const ids = ['startclip-', 'endclip-', 'previewClip', 'manualclippericon', 'previewPlayer']
            const attr = 'data-cliphl_uid'
            const isAnyPill = ids.some(val => checkForIdInParentNodes(e.target, val))
            const isAnyHighlight = checkForAttrInParentNodes(e.target, attr)
            if (isAnyPill || isAnyHighlight) {

            } else {
              this.clearPreview()
            }
          })
        }
      }
      if (oldVal === 2) {
        if (this.isManualClipper) {
          this.resetSearch()
          document.getElementById('manualClipperDoc').removeEventListener('keydown', this.keyDownListner)
        }
      }
      if (val === 3) {
        this.previewClip = null
        if (oldVal === 2) {
          setTimeout(() => {
            this.makePreviewClips()
          }, 500)
        }
      } else {
        this.hidePlayerInteraction = false
      }
    },
    brandkit: async function(val, oldVal) {
      if (!oldVal && val) {
        const { logo, logoStyle } = { ...val }
        const logoUrl = logo && logo.url ? await getImageFromPath(logo && logo.url) : ''
        videoPlayer.updateImage({
          src: logoUrl,
          position: logoStyle
        })
      }
    },
    useBrandkit: async function(val) {
      if (!val) {
        videoPlayer.updateImage({
          src: ''
        })
      } else {
        const { logo, logoStyle } = { ...this.brandkit }
        const logoUrl = logo && logo.url ? await getImageFromPath(logo && logo.url) : ''
        videoPlayer.updateImage({
          src: logoUrl,
          position: logoStyle
        })
      }
    },
    isReadyToMakePreviews: function(val, oldVal) {
      if (val && this.step === 3) {
        setTimeout(() => {
          this.makePreviewClips()
        }, 1000)
      }
    },
    clipBeingEdited: function(val) {
      if (!val) return
      // Event listener for clip edit
      clipPill.PillEvents[val].eventBus.on(`clip-edit.${val}`, (ustart, uend, uid) => {
        const pillSize = clipPill.PillEvents[uid].pillNodeSize
        // Time has to be computed taking pills into consideration because
        // at this point, pills are visible on the document.
        const start = ustart + pillSize
        const end = uend + pillSize
        const startTime = computeTimeAtPos(start, myProseEditor.view)
        const endTime = computeTimeAtPos(end, myProseEditor.view)

        const timestamps = [...this.projectInfo.timestamps]
        timestamps.forEach(val => {
          if (val.index === uid) {
            val.start = startTime
            val.end = endTime
            // The values saved have to be irrespective of pill size.
            val.location.start = ustart
            val.location.end = uend
          }
        })
        this.previewClip = { ...timestamps.find((val) => val.index === uid) }
        // cursor focused is according to existing pills in the document
        focusCursorOnLocation(start, myProseEditor)
        updateProject(
          {
            userId: this.$route.params.userId,
            projectId: this.$route.params.projectId,
            update: {
              timestamps
            }
          }
        )
      })
    }
  },
  beforeDestroy: function() {
    if (myProseEditor) {
      myProseEditor.stopAudioPlayer()
      myProseEditor.removeCustomHighlight()
    }
    this.resetVideo()
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss" />
