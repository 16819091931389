export default [
  // {
  //   id: 0,
  //   name: 'default',
  //   enabled: false
  // },
  {
    id: 1,
    name: 'one',
    enabled: true,
    logo: '/images/default/logo-dark.png',
    bg: '/images/default/template-one-bg.png',
    settings: {
      accentColor: '#20557D',
      backgroundType: 'image',
      backgroundColor: '#FFFFFF',
      titleStyle: {
        color: '#20557D',
        'font-family': 'Poppins',
        'font-weight': '500'
      },
      subtitleStyle: {
        color: '#000228',
        'font-family': 'Inter',
        'font-weight': '400'
      }
    }
  },
  {
    id: 2,
    name: 'two',
    enabled: true,
    logo: '/images/default/logo-dark.png',
    settings: {
      accentColor: '#20557D',
      backgroundType: 'color',
      backgroundColor: '#FFFFFF',
      titleStyle: {
        color: '#666C7C',
        'font-family': 'Archivo',
        'font-weight': '600'
      },
      subtitleStyle: {
        color: '#000228',
        'font-family': 'Inter',
        'font-weight': '400'
      }
    }
  },
  {
    id: 3,
    name: 'three',
    enabled: true,
    logo: '/images/default/logo-light.png',
    settings: {
      accentColor: '#20557D',
      backgroundType: 'color',
      backgroundColor: '#12334B',
      titleStyle: {
        color: '#FFFFFF',
        'font-family': 'Poppins',
        'font-weight': '500'
      },
      subtitleStyle: {
        color: '#F3F4F6',
        'font-family': 'Inter',
        'font-weight': '400'
      }
    }
  },
  {
    id: 4,
    name: 'four',
    enabled: true,
    hideCover: true,
    logo: '/images/default/logo-dark.png',
    settings: {
      accentColor: '#E86B56',
      backgroundType: 'color',
      backgroundColor: '#FFFFFF',
      titleStyle: {
        color: '#20557D',
        'font-family': 'Archivo',
        'font-weight': '600'
      },
      subtitleStyle: {
        color: '#000228',
        'font-family': 'Roboto',
        'font-weight': '400'
      }
    }
  },
  {
    id: 5,
    name: 'five',
    enabled: true,
    hideCover: true,
    logo: '/images/default/logo-dark.png',
    settings: {
      accentColor: '#E55841',
      backgroundType: 'color',
      backgroundColor: '#FFFFFF',
      titleStyle: {
        color: '#FFFFFF',
        'font-family': 'Archivo',
        'font-weight': '700'
      },
      subtitleStyle: {
        color: '#20557D',
        'font-family': 'Roboto',
        'font-weight': '400'
      }
    }
  },
  {
    id: 6,
    name: 'six',
    enabled: true,
    logo: '/images/default/logo-light.png',
    settings: {
      accentColor: '#8899A5',
      backgroundType: 'color',
      backgroundColor: '#12334B',
      titleStyle: {
        color: '#6388A4',
        'font-family': 'Archivo',
        'font-weight': '500'
      },
      subtitleStyle: {
        color: '#000228',
        'font-family': 'Roboto',
        'font-weight': '400'
      }
    }
  }
]
