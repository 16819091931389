<template>
  <div
    class="relative flex w-full overflow-hidden border rounded cursor-pointer previewClip group border-primary-300 hover:border-primary"
    :class="{'border-primary': isSelected}"
    @click="$emit('onSelect')"
    :id="id"
  >
    <div
      class="relative z-20 flex items-center justify-center rounded-tl rounded-bl bg-primary thumb"
      @click="(e) => {
        e.stopPropagation()
        onTogglePlay()
      }"
    >
      <div
        class="relative z-30 flex items-center justify-center w-6 h-6 text-white border-white rounded-full cursor-pointer hover:border-red hover:text-red"
        :class="{'border-2': sourcesLoaded && !loading}"
      >
        <CircularLoader
          v-if="loading"
          class="w-6 h-6 pointer-events-none"
        />
        <PauseIcon
          v-else-if="isPlaying"
          class="h-2.5 w-2.5 pointer-events-none"
        />
        <PlayIcon
          v-else
          class="h-2.5 left-0.25 w-2.5 relative pointer-events-none"
        />
      </div>

      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-20 bg-primary bg-opacity-40"
      />

      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center"
      >
        <Thumbnail
          v-if="playerType==='video' && !loading && nonBrandkitSource"
          class="w-full h-full"
          :source="nonBrandkitSource"
          :percentage="100 * (clip.start || 100) / totalTime"
        />
        <AudioThumbnail
          v-else-if="!loading && !isEmpty(videoPlayerInfo)"
          :videoPlayerInfo="videoPlayerInfo"
        />
      </div>

      <div v-if="showDuration" class="absolute z-20 text-xs text-white right-1 bottom-1 font">
        {{duration}}
      </div>
    </div>

    <div class="flex items-center justify-between flex-grow px-4">
      <textarea
        v-if="editable && isEditingTitle"
        type="text"
        placeholder="Name Your Project"
        class="resize-none border-none text-primary text-sm outline-none px-1.5 py-2 placeholder-primary-300 focus:ring-1 focus:ring-primary rounded clipTitleInput"
        v-model="title"
        v-focus
        @blur="onBlur"
        @keyup.enter="onBlur"
      />
      <div
        v-else-if="editable"
        @click="startEditing"
        class="text-primary text-sm cursor-text rounded border border-transparent hover:border-primary-300 px-1.5 py-2 clipTitleInput"
        :class="{'text-primary-300': !title}"
        :title="title"
      >
        {{limitString(title)}}
      </div>
      <div v-else class="text-sm text-primary" :title="title">
        {{limitString(title)}}
      </div>

      <div
        v-if="showDelete"
        class="cursor-pointer text-primary-500 hover:text-red z-20"
        @click="(e) => {
          e.stopPropagation()
          $emit('onDelete')
        }"
      >
        <DeleteIcon />
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import Thumbnail from '@/components/VideoPlayer/Thumbnail.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import AudioThumbnail from '@/components/VideoPlayer/AudioThumb.vue'

export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    clip: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    totalTime: {
      type: Number,
      required: true
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showDuration: {
      type: Boolean,
      default: false
    },
    nonBrandkitSource: {
      type: String,
      default: ''
    },
    playerType: {
      type: String,
      default: 'video'
    },
    videoPlayerInfo: {
      type: Object,
      default: () => ({})
    },
    additionalDuration: {
      type: Number,
      default: 0
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    PlayIcon,
    PauseIcon,
    DeleteIcon,
    CircularLoader,
    Thumbnail,
    AudioThumbnail
  },
  data: function() {
    return {
      isEditingTitle: false,
      showLoader: this.loading,
      title: this.clip.title,
      isEmpty
    }
  },
  computed: {
    ...mapGetters({
      sourcesReadyMap: 'video/sourcesReadyMap'
    }),
    duration: function() {
      const durationInSeconds = this.additionalDuration + (this.clip.end - this.clip.start)
      const minutes = ~~(durationInSeconds / 60)
      const seconds = durationInSeconds % 60
      return `${minutes ? (minutes.toFixed(0) + 'm') : ''}${seconds ? (seconds.toFixed(seconds < 1 ? 1 : 0) + 's') : ''}`
    },
    sourcesLoaded: function() {
      let loaded = false
      if (this.clip.introAsource || this.clip.outroAsource) {
        const introLoaded = this.clip.introAsource ? Boolean(this.sourcesReadyMap[this.clip.introAsource]) : true
        const outroLoaded = this.clip.outroAsource ? Boolean(this.sourcesReadyMap[this.clip.outroAsource]) : true
        loaded = introLoaded && outroLoaded
      } else loaded = true

      return loaded
    }
  },
  methods: {
    limitString: function(str, limit = 40) {
      if (str.length > limit) return `${str.slice(0, limit)}...`
      else return str
    },
    onTogglePlay: function() {
      if (!this.loading) this.$emit('onTogglePlay')
    },
    startEditing: function() {
      setTimeout(() => {
        this.isEditingTitle = true
      }, 150)
    },
    updateTitle: debounce(function(title) {
      this.$emit('onUpdate', {
        id: this.clip.index,
        title
      })
    }, 500),
    onBlur: function() {
      if (!this.title.trim()) {
        this.title = this.clip.title
      }
      this.updateTitle(this.title)
      this.isEditingTitle = false
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.previewClip {
  max-height: 72px;
  min-height: 72px;
}

.thumb {
  height: 72px;
  min-width: 84px;
  max-width: 84px;
}

.clipTitleInput {
  width: calc(100%);
}
</style>
