<template>
  <div
    class="grid gap-3"
    :class="[aspectRatio === 'portrait' ? 'grid-cols-6' : 'grid-cols-3', aspectRatio === 'square' ? 'limitWidth' : '']"
  >

    <div
      v-for="t in templates"
      class="relative col-span-1 overflow-hidden rounded-md hover:opacity-100 hover:ring-primary hover:ring-1"
      :class="{
        'ring-primary ring-1': t.name === template,
        'ring-primary-300 ring-2 opacity-50': t.name !== template,
        'cursor-pointer': t.enabled
      }"
      :key="t.id"
      @click="onTemplateChange(t.name)"
    >

      <div
        v-if="t.name === template"
        class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-20"
      >
        <div class="flex items-center justify-center w-10 h-10 text-2xl text-white bg-white rounded-full">
          <div class="flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-primary">
            <i class="fas fa-check"></i>
          </div>
        </div>
      </div>
      <img :src="`/templates/${t.id}_${aspectRatio}.png`" :alt="t.name" class="w-full">
    </div>

  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { updateProject } from '@/services/api/project'
import templates from './data'

export default {
  name: 'Templates',
  props: {
    template: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: String,
      default: 'landscape'
    },
    projectInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data: function() {
    return {
      templates,
      videoPlayerInfoUpdates: {}
    }
  },
  computed: {
    videoPlayerInfo () {
      const {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      } = { ...this.projectInfo }
      const info = {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      }
      return info
    }
  },
  methods: {
    save: async function(update) {
      this.videoPlayerInfoUpdates = {
        ...this.videoPlayerInfoUpdates,
        ...update
      }
      await updateProject(
        {
          userId: this.$route.params.userId,
          projectId: this.$route.params.projectId,
          update
        }
      )
      console.log('updateeeee', update)
    },
    onTemplateChange: function(template) {
      const templateDetails = templates.find(t => t.name === template)
      if (templateDetails) {
        console.log('updateeeee', templateDetails)
        this.save({
          template,
          ...templateDetails.settings
        })
      }
    },
    onVideoPlayerInfoUpdate: debounce(function(update) {
      this.$emit('onChange', {
        ...this.videoPlayerInfo,
        ...update
      })
    }, 200)
  },
  watch: {
    videoPlayerInfoUpdates: function(val) {
      this.onVideoPlayerInfoUpdate(val)
    }
  }
}
</script>

<style scoped lang="scss">
.default {
  background-image: url('./default.png');
  background-size: cover;
  aspect-ratio: 16 / 9;
}

.comingsoon {
  background-image: url('./comingsoon.png');
  background-size: cover;
  aspect-ratio: 16 / 9;
}

.limitWidth {
  max-width: 528px;
}
</style>
